import React, { ChangeEvent, useEffect, useState } from 'react';
import { DashboardHeader } from './DashboardHeader';
import { DashboardCards } from './DashboardCards';
import { TenderItem } from '../../tender/list/TenderItem';
import { Tender } from '../../../model/tender/Tender';
import { DashboardTabs } from './DashboardTabs';
import { ErrorToast } from '../../../shared/toasters/toasters';
import { DashboardService } from '../service';
import { IDashboard } from '../dto/IDashboard';
import { InitialDashboardData } from '../dto/InitialDashboardData';
import { DashboardRelevanceList } from './DashboardRelevanceList';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FilterStatus } from '../../../shared/enums/Enums';
import { DashboardPersonalTenders } from './DashboardPersonalTenders';

interface IProps {
  tender: Tender;
}

export function DashboardComponent() {
  const [filterParams, setFilter] = useState({
    page: 1,
    perPage: 15,
    status: 'recent',
    relevanceListField: '',
    relevanceListIsAscending: false,
    tenderListField: '',
    tenderListIsAscending: false,
  });
  const [personalTenders, setPersonalTenders] = useState<Tender[]>([]);
  const { t } = useTranslation();
  const [tenders, setTenders] = useState<Tender[]>();
  const [title, setTitle] = useState('recent');
  const [dashboardStats, setDashboardStats] =
    useState<IDashboard>(InitialDashboardData);
  const [relevances, setRelevances] = useState<Tender[]>([]);
  const [ascending, setAscending] = useState(false);
  useEffect(() => {
    DashboardService.getDashboard(filterParams)
      .then((response) => {
        setDashboardStats(response);
        setTenders(response.tenders);
        setRelevances(response.relevanceList);
        setPersonalTenders(response.personalTenders);
      })
      .catch((err) => ErrorToast(err));
  }, [JSON.stringify(filterParams)]);

  const filterTenders = (type: string) => {
    setTitle(type);
    setFilter({
      ...filterParams,
      status: type,
    });
  };
  const handlerChange = (name: string, listType = 'tender') => {
    console.log(name, listType);
    setAscending(!ascending);
    if (listType === 'tender') {
      setFilter({
        ...filterParams,
        tenderListField: name,
        tenderListIsAscending: ascending,
      });
    }
    if (listType === 'relevance') {
      setFilter({
        ...filterParams,
        relevanceListField: name,
        relevanceListIsAscending: ascending,
      });
    }
    if (listType === 'draft') {
      setFilter((current) => ({
        ...current,
        draftListField: name,
        draftListIsAscending: ascending,
      }));
    }
  };

  return (
    <div>
      <DashboardHeader />
      <DashboardCards dashboardStats={dashboardStats} />

      <DashboardPersonalTenders
        personalTenders={personalTenders}
        handleChange={handlerChange}
      />

      <DashboardRelevanceList
        relevanceList={relevances}
        handleChange={handlerChange}
      />

      <div className="row">
        <DashboardTabs
          filterParams={filterParams}
          filterTenders={filterTenders}
        />
      </div>

      <div className="col-lg-12">
        <div className="tab-content text-muted">
          <div className="tab-pane active" id="recent" role="tabpanel">
            <div className="card" id="invoiceList">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  {t(
                    FilterStatus[title as keyof typeof FilterStatus] as string
                  )}
                  Tenders
                </h4>

                <div className="flex-shrink-0">
                  <a
                    href="/tenders#recent"
                    className="btn btn-soft-info btn-sm"
                  >
                    <i className="ri-file-list-3-line align-middle" />
                    {t('dashboard.tenders.view_all_tenders')}
                  </a>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive table-card">
                  <table
                    className="table align-middle table-nowrap"
                    id="invoiceTable"
                  >
                    <thead className="text-muted">
                      <tr>
                        <th
                          data-column-id="id"
                          onClick={() => handlerChange('id')}
                          className="gridjs-th gridjs-th-sort text-uppercase bg-white text-black-50"
                        >
                          <div className="gridjs-th-content">Id</div>
                          <button
                            name="id"
                            className="gridjs-sort gridjs-sort-neutral"
                          />
                        </th>
                        <th
                          onClick={() => handlerChange('number', 'tender')}
                          className="gridjs-th gridjs-th-sort text-uppercase bg-white text-black-50"
                          data-sort="country"
                        >
                          <div className="gridjs-th-content">
                            {t('dashboard.relevance_check_list.number')}
                          </div>
                          <button className="gridjs-sort gridjs-sort-neutral"></button>
                        </th>
                        <th
                          data-column-id="name"
                          onClick={() => handlerChange('name')}
                          className="gridjs-th gridjs-th-sort text-uppercase bg-white text-black-50"
                        >
                          <div className="gridjs-th-content">
                            {t('dashboard.tenders.name')}
                          </div>
                          <button className="gridjs-sort gridjs-sort-neutral" />
                        </th>
                        <th
                          data-column-id="deadline"
                          onClick={() => handlerChange('deadline')}
                          className="gridjs-th gridjs-th-sort text-uppercase bg-white text-black-50"
                        >
                          <div className="gridjs-th-content">
                            {t('dashboard.tenders.deadline')}
                          </div>
                          <button className="gridjs-sort gridjs-sort-neutral" />
                        </th>
                        <th className="sort text-uppercase" data-sort="country">
                          {t('dashboard.tenders.checklist')}
                        </th>
                        <th className="sort text-uppercase" data-sort="date">
                          {t('dashboard.tenders.bidded_companies')}
                        </th>
                        <th className="sort text-uppercase" data-sort="status">
                          Status
                        </th>
                        <th className="sort text-uppercase" data-sort="action">
                          {t('dashboard.tenders.action')}
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      className="list form-check-all"
                      id="invoice-list-data"
                    >
                      {tenders?.map((tender) => (
                        <TenderItem tender={tender} showCompaniesRow={true} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
