import React, { useEffect, useRef, useState } from "react";
import { TenderTabs } from "../TenderTabs";
import { TenderService } from "../../service";
import { ErrorToast } from "../../../../shared/toasters/toasters";
import { iTenderCommentsResponse } from "../../../../model/tender/iTenderComments";
import { useLocation } from "react-router-dom";
import { TenderCommentsHeader } from "./components/TenderCommentsHeader";
import { TenderCommentsList } from "./components/TenderCommentsList";
import Picker from "emoji-picker-react";
import { Tender } from "../../../../model/tender/Tender";
import { useTranslation } from "react-i18next";

export function TenderComments() {
  const { t } = useTranslation();
  const location = useLocation();
  const lastMessageRef = useRef<HTMLInputElement>(null);
  const [update, setUpdate] = useState(false);
  const tenderId = location?.pathname?.split("/")[3];
  const [listOfComments, setListOfComments] = useState<
    iTenderCommentsResponse[]
  >([]);
  const [messageToSend, setMessageToSend] = useState({
    message: "",
  });
  const [tenderInfo, setTenderInfo] = useState<Tender>();

  const [emoji, setEmoji] = useState("");
  const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (event: any, emojiObject: any) => {
    setEmoji((prevInput) => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageToSend((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.trim(),
    }));
    setEmoji(event.target.value);
  };

  const sendMessagePressingEnter = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") return sendNewMessage();
  };

  let textInput = document.getElementById("chat-input") as HTMLInputElement;

  const sendNewMessage = () => {
    TenderService.createTenderComment(+tenderId, {
      ...messageToSend,
      message: `${emoji.trim()}`,
    })
      .then((res) => {
        if (res && textInput) {
          setUpdate(!update);
          lastMessageRef?.current?.scrollIntoView({
            behavior: "smooth",
          });
          setEmoji("");
          setMessageToSend({ message: "" });
          textInput.value = "";
        }
      })
      .catch((error) => ErrorToast(error));
  };

  const [otherData, setOtherData] = useState([]);

  useEffect(() => {
    let numberOfMessages = 0;

    function getMessages() {
      tenderId &&
        TenderService.getTenderComments(+tenderId, {
          perPage: 99999,
        })
          .then((res) => {
            if (res) {
              setListOfComments(
                res.data?.sort(
                  (a: iTenderCommentsResponse, b: iTenderCommentsResponse) =>
                    a?.id - b.id
                )
              );
              setOtherData(res?.otherData?.tender);

              if (numberOfMessages < res?.count) {
                numberOfMessages = res?.count;
                lastMessageRef?.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }
            }
          })
          .catch((error) => ErrorToast(error));
    }

    getMessages();
    const interval = setInterval(() => getMessages(), 5000);
    return () => {
      clearInterval(interval);
    };
  }, [tenderId, update]);

  useEffect(() => {
    tenderId &&
      TenderService.getTender(+tenderId!).then((response) => {
        response && setTenderInfo(response);
      });
  }, [JSON.stringify(tenderId)]);

  const fieldRef = React.useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.scrollIntoView();
    }
  }, []);

  const currentUser = JSON.parse(localStorage.getItem("currentUser") as any);
  const isSuperAdmin = currentUser?.roles?.some(
    (role: string) => role === "ROLE_SUPER_ADMIN"
  );

  const isDisabled = !isSuperAdmin && tenderInfo?.status === "done";

  return (
    <div className="col-xl-12">
      <div className="card">
        <div className="card-body checkout-tab">
          <TenderTabs />
          <div className="user-chat w-100 overflow-hidden">
            <div className="chat-content ">
              <div className="w-100 overflow-hidden position-relative">
                <div className="position-relative">
                  {otherData && <TenderCommentsHeader otherData={otherData!} />}

                  <TenderCommentsList
                    listOfComments={listOfComments}
                    lastMessageRef={lastMessageRef}
                  />
                  <div
                    className={`chat-input-section p-3 p-lg-4 ${
                      isDisabled && "d-none"
                    }`}
                  >
                    <div className="row g-0 align-items-center">
                      <div className="col-auto">
                        <div className="chat-input-links me-2">
                          <div className="links-list-item">
                            <button
                              onClick={() => setShowPicker((val) => !val)}
                              type="button"
                              className="btn btn-link text-decoration-none emoji-btn"
                              id="emoji-btn"
                            >
                              <i className="bx bx-smile align-middle" />
                            </button>
                            {showPicker && (
                              <Picker
                                disableSearchBar={true}
                                pickerStyle={{ width: "50%" }}
                                onEmojiClick={onEmojiClick}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          ref={fieldRef}
                          className="form-control chat-input bg-light border-light"
                          id="chat-input"
                          placeholder={t("Tenders.Comments.message_input")}
                          onKeyUp={sendMessagePressingEnter}
                          onChange={changeHandler}
                          autoComplete="off"
                          value={emoji}
                        />
                      </div>
                      <div className="col-auto">
                        <div className="chat-input-links ms-2">
                          <div className="links-list-item">
                            <button
                              onClick={sendNewMessage}
                              className="btn btn-success chat-send waves-effect waves-light"
                            >
                              <i className="ri-send-plane-2-fill align-bottom" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
