import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState, Suspense } from 'react';
import { User } from '../../model/user/User';
import { BASE_URL } from '../../utils/helpers/api.routes';
import { ProfileService } from '../../app/profile/service';
import { ErrorToast } from '../toasters/toasters';
import { NotificationService } from '../../app/notification/service';
import { INotification } from '../../model/notification/INotification';
import { NotificationCount } from '../../model/notification/NotificationCount';
import { trimRoles } from '../../app/users/components/UserItem';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { availableLanguages } from '../../utils/translations/i18next';
import { requests } from '../../utils/helpers/api.services';
import 'moment/locale/de';
import 'moment/locale/en-gb';
export function Header() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [profile, setProfile] = useState<User>();
  const body = document.querySelector('body')!;
  const [notifyCount, setNotifyCount] = useState<NotificationCount>();
  const [notifications, setNotifications] = useState<INotification[]>([]);
  console.log(notifyCount);
  useEffect(() => {
    ProfileService.getProfile()
      .then((res) => {
        res && setProfile(res);
      })
      .catch((err) => ErrorToast(err));
  }, []);

  useEffect(() => {
    NotificationService.getNotifyCount()
      .then((response) => setNotifyCount(response))
      .catch((err) => ErrorToast(err));
  }, []);

  function getNotify() {
    NotificationService.getNotify({ perPage: 10, page: 1 })
      .then((response) => {
        const allNotify = response.data;
        setNotifications(allNotify);
      })
      .catch((err) => ErrorToast(err));
  }

  function menuToggle() {
    setMenuOpen(!menuOpen);
    body.classList.add('vertical-sidebar-enable');
  }

  const logoutUser = () => {
    localStorage.clear();
    navigate('/');
  };

  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token === null) {
      navigate('/');
    }
  }, []);

  const changeLanguageHandler = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    requests
      .put(`${BASE_URL}app/profile/change-language/${language}`)
      .then((res) => {
        res && window.location.reload();
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <>
      <header id="page-topbar">
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <a href="/dashboard" className="logo logo-dark">
                  <span className="logo-sm">
                    <img
                      src="../../assets/images/logo-sm.png"
                      alt=""
                      height="22"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src="../../assets/images/logo-dark.png"
                      alt=""
                      height="17"
                    />
                  </span>
                </a>
                <a href="/dashboard" className="logo logo-light">
                  <span className="logo-sm">
                    <img
                      src="../../assets/images/logo-sm.png"
                      alt=""
                      height="22"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src="../../assets/images/logo-light.png"
                      alt=""
                      height="17"
                    />
                  </span>
                </a>
              </div>

              <button
                onClick={menuToggle}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>

            <div className="d-flex align-items-center justify-content-end w-50">
              <div className="dropdown ms-1 topbar-head-dropdown header-item">
                <button
                  type="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <img
                    id="header-lang-img"
                    src={`/assets/images/flags/${
                      i18n.language === 'en' ? 'us' : 'germany'
                    }.svg`}
                    alt="Header Language"
                    height="20"
                    className="rounded"
                  />
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end  position-absolute m-0"
                  data-popper-placement="bottom-end"
                  id="languageSwitcher"
                  style={{
                    inset: '0px 0px auto auto',
                    transform: 'translate(0px, 58px)',
                  }}
                >
                  {availableLanguages?.map((language) => {
                    return (
                      <div
                        key={language}
                        className="dropdown-item notify-item language py-2"
                        id={language}
                        onClick={() => {
                          changeLanguageHandler(language);
                        }}
                        data-lang={language}
                        title={language}
                      >
                        <img
                          src={`/assets/images/flags/${
                            language === 'en' ? 'us' : 'germany'
                          }.svg`}
                          alt="country-image"
                          className="me-2 rounded"
                          height="18"
                        />
                        <span className="align-middle">
                          {language === 'en' ? 'English' : 'German'}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="dropdown topbar-head-dropdown ms-1 header-item">
                <button
                  type="button"
                  onClick={getNotify}
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  id="page-header-notifications-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bx bx-bell fs-22" />
                  {notifyCount && notifyCount?.unreadNotificationsCount > 0 && (
                    <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                      {notifyCount?.unreadNotificationsCount}
                      <span className="visually-hidden">
                        {t('header.notifications.unread_messages')}
                      </span>
                    </span>
                  )}
                </button>
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  aria-labelledby="page-header-notifications-dropdown"
                >
                  <div className="dropdown-head bg-primary bg-pattern rounded-top">
                    <div className="p-3">
                      <div className="row align-items-center">
                        <div className="col">
                          <h6 className="m-0 fs-16 fw-semibold text-white">
                            {' '}
                            {t('header.notifications.notification')}{' '}
                          </h6>
                        </div>
                        <div className="col-auto dropdown-tabs">
                          <span className="badge badge-soft-light fs-13">
                            {' '}
                            {notifyCount?.unreadNotificationsCount}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="px-2 pt-2">
                      <ul
                        className="nav nav-tabs dropdown-tabs nav-tabs-custom"
                        data-dropdown-tabs="true"
                        id="notificationItemsTab"
                        role="tablist"
                      >
                        <li className="nav-item waves-effect waves-light">
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#all-noti-tab"
                            role="tab"
                            aria-selected="true"
                          >
                            {t('header.notifications.all')}(
                            {notifyCount?.unreadNotificationsCount})
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="tab-content" id="notificationItemsTabContent">
                    <div
                      className="tab-pane fade py-2 ps-2 active show"
                      id="all-noti-tab"
                      role="tabpanel"
                    >
                      <div data-simplebar="init" className="pe-2">
                        <div
                          className="simplebar-wrapper"
                          style={{ margin: '0px -8px 0px 0px' }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ right: '0px', bottom: '0px' }}
                            >
                              <div
                                className="simplebar-content-wrapper"
                                tabIndex={0}
                                role="region"
                                aria-label="scrollable content"
                                style={{
                                  height: 'auto',
                                  overflow: 'hidden scroll',
                                }}
                              >
                                <div
                                  className="simplebar-content"
                                  style={{
                                    padding: '0px 8px 0px 0px',
                                    maxHeight: 500,
                                    overflow: 'auto',
                                  }}
                                >
                                  {notifications?.map((notify) => {
                                    return (
                                      <div className="text-reset notification-item d-block dropdown-item position-relative">
                                        <div className="d-flex">
                                          <div className="avatar-xs me-3">
                                            <span className="avatar-title bg-soft-danger text-danger rounded-circle fs-16">
                                              <i className="bx bx-message-square-dots" />
                                            </span>
                                          </div>
                                          <div className="flex-1">
                                            <Link
                                              to={`/tender/details/${notify?.tender?.id}`}
                                              state={{ isEdit: true }}
                                              className="stretched-link"
                                            >
                                              <h6
                                                className={`${
                                                  !notify.viewed && 'fw-bold'
                                                } mt-0 mb-2  fs-13 lh-base`}
                                              >
                                                {notify.message}
                                              </h6>
                                            </Link>
                                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                              <span>
                                                <i className="mdi mdi-clock-outline" />
                                                {moment(notify?.createdAt)
                                                  ?.locale(
                                                    `${
                                                      localStorage.getItem(
                                                        'language'
                                                      ) ?? 'de'
                                                    }`
                                                  )
                                                  ?.fromNow()}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                  <div className="my-3 text-center">
                                    <Link to="/notifications">
                                      <button
                                        type="button"
                                        className="btn btn-soft-success waves-effect waves-light"
                                      >
                                        {t(
                                          'header.notifications.viewAllNotifications'
                                        )}
                                        <i className="ri-arrow-right-line align-middle" />
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="simplebar-placeholder"
                            style={{ width: '0', height: '0' }}
                          />
                        </div>
                        <div
                          className="simplebar-track simplebar-horizontal"
                          style={{ visibility: 'hidden' }}
                        >
                          <div
                            className="simplebar-scrollbar"
                            style={{ width: '0px', display: 'none' }}
                          ></div>
                        </div>
                        <div
                          className="simplebar-track simplebar-vertical"
                          style={{ visibility: 'hidden' }}
                        >
                          <div
                            className="simplebar-scrollbar"
                            style={{
                              height: '0px',
                              display: 'none',
                              transform: 'translate3d(0px, 0px, 0px)',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*END NOTIFI*/}
              <div className="dropdown ms-sm-3 header-item topbar-user">
                <button
                  type="button"
                  className="btn"
                  id="page-header-user-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="d-flex align-items-center">
                    <img
                      src={`${BASE_URL}${profile?.profilePicture?.path}`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = '/images/placeholderImage.png';
                      }}
                      className="rounded-circle header-profile-user object-cover"
                      alt="user-profile-image"
                    />
                    <span className="text-start ms-xl-2">
                      <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                        {profile?.firstName} {profile?.lastName}
                      </span>
                      <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                        {profile?.roles?.map((role) => trimRoles(role))}
                      </span>
                    </span>
                  </span>
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                  <h6 className="dropdown-header">
                    {t('header.profile.welcome')} {profile?.firstName!}
                  </h6>
                  <Link className="dropdown-item" to="/profile">
                    <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1" />
                    <span className="align-middle">
                      {t('header.profile.profile')}
                    </span>
                  </Link>
                  <div className="dropdown-divider"></div>
                  <div className="dropdown-item" onClick={logoutUser}>
                    <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                    <span className="align-middle" data-key="t-logout">
                      {t('header.profile.logout')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
