import { PageTemplate } from "../../shared/layout/PageTemplate";
import { StatisticsPage } from "./components/StatisticsPage";
import { useTranslation } from "react-i18next";

export function Statistics() {
  const { t } = useTranslation();
  return (
    <PageTemplate
      pageName={`${t("side_bar.statistics")}`}
      title={`${t("side_bar.statistics")}`}
      content={<StatisticsPage />}
    />
  );
}
