import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDeleteModal } from '../../../shared/components/ConfirmDeleteModal';
import { EarningModal } from './EarningModal';
import { EarningsService } from '../service';
import { ErrorToast, SuccessToast } from '../../../shared/toasters/toasters';
import { Pagination } from '../../../shared/paginations/Paginations';

export interface iEarnings {
  id: number;
  year: number;
  revenue: number;
  month: number;
}

export const EarningList = () => {
  const { t } = useTranslation();
  const [filterParams, setFilter] = useState({
    page: 1,
    perPage: 10,
    year: null,
    month: null,
  });
  const [keyForCreate, setKeyForCreate] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [allEarnings, setAllEarnings] = useState<iEarnings[]>([]);
  const [earningCreate, setEarningCreate] = useState({
    id: 0,
    month: 0,
    year: 0,
    revenue: 0,
  });
  const [updateData, setUpdateData] = useState(false);
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'month_year') {
      let yearMonth = event.target.value.split('-');
      setEarningCreate((prev) => ({
        ...prev,
        year: +yearMonth[0],
        month: +yearMonth[1],
      }));
    } else {
      setEarningCreate((prev) => ({
        ...prev,
        [event.target.name]: +event.target.value,
      }));
    }
  };
  const deleteEarning = () => {
    EarningsService.deleteEarning(earningCreate?.id)
      .then((res) => {
        if (res === '') {
          SuccessToast(`${t('earnings.toasts.success_deleted')}!`);
          setUpdateData(!updateData);
        }
      })
      .catch((error) => ErrorToast(error));
  };
  const createEarning = () => {
    EarningsService.createEarning(earningCreate)
      .then((res) => {
        if (res) {
          SuccessToast(`${t('earnings.toasts.success_created')}!`);
          setUpdateData(!updateData);
          setKeyForCreate((prev) => prev + 1);
        }
      })
      .catch((error) => ErrorToast(error));
  };

  const updateEarning = () => {
    EarningsService.updateEarning(earningCreate, earningCreate?.id)
      .then((res) => {
        if (res) {
          SuccessToast(`${t('earnings.toasts.success_updated')}!`);
          setUpdateData(!updateData);
        }
      })
      .catch((error) => ErrorToast(error));
  };

  const getRightMonth = (id: number) => {
    switch (id) {
      case 1:
        return `${t('months.jan')}`;
        break;
      case 2:
        return `${t('months.feb')}`;
        break;
      case 3:
        return `${t('months.mar')}`;
        break;
      case 4:
        return `${t('months.apr')}`;
        break;
      case 5:
        return `${t('months.may')}`;
        break;
      case 6:
        return `${t('months.jun')}`;
        break;
      case 7:
        return `${t('months.jul')}`;
        break;
      case 8:
        return `${t('months.aug')}`;
        break;
      case 9:
        return `${t('months.sep')}`;
        break;
      case 10:
        return `${t('months.oct')}`;
        break;
      case 11:
        return `${t('months.nov')}`;
        break;
      default:
        return `${t('months.dec')}`;
    }
  };

  const handlePages = (updatePage: number) => {
    setFilter({ ...filterParams, page: updatePage });
  };

  const filterData = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFilter({
      ...filterParams,
      [event.target.name]:
        event.target.value === '0' || event.target.value === ''
          ? null
          : event.target.value,
    });
  };

  useEffect(() => {
    EarningsService.getEarnings(filterParams)
      .then((res) => {
        setAllEarnings(res.data);
        setTotalPages(Math.ceil(res.count / res.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [updateData, JSON.stringify(filterParams)]);

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header border-0">
          <div className="d-flex align-items-center flex-column flex-md-row">
            <h4 className="card-title mb-0 flex-grow-1">
              {t('side_bar.earnings')}
            </h4>
            <div className="flex-shrink-0 mt-2 mt-md-0">
              <a
                className="btn btn-success"
                href="#earningCreate"
                data-bs-toggle="modal"
              >
                <i className="ri-add-line align-bottom me-1" />{' '}
                {t('earnings.create_external_earning')}
              </a>
            </div>
          </div>
        </div>
        <div className="row ms-1 mb-2 w-100">
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              name="year"
              placeholder={`${t('earnings.searchByYear')}`}
              onChange={filterData}
            />
          </div>
          <div className="col-md-4">
            <select name="month" className="form-select" onChange={filterData}>
              <option value="0">All</option>
              <option value="1">{t('months.jan')}</option>
              <option value="2">{t('months.feb')}</option>
              <option value="3">{t('months.mar')}</option>
              <option value="4">{t('months.apr')}</option>
              <option value="5">{t('months.may')}</option>
              <option value="6">{t('months.jun')}</option>
              <option value="7">{t('months.jul')}</option>
              <option value="8">{t('months.aug')}</option>
              <option value="9">{t('months.sep')}</option>
              <option value="10">{t('months.oct')}</option>
              <option value="11">{t('months.nov')}</option>
              <option value="12">{t('months.dec')}</option>
            </select>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive table-card">
            <table
              className="table align-middle table-nowrap"
              id="invoiceTable"
            >
              <thead className="text-muted">
                <tr>
                  <th
                    className="sort text-uppercase text-start"
                    data-sort="invoice_id"
                  >
                    ID
                  </th>
                  <th
                    className="sort text-uppercase text-start"
                    data-sort="country"
                  >
                    {t('earnings.year')}
                  </th>
                  <th
                    className="sort text-uppercase text-start"
                    data-sort="country"
                  >
                    {t('earnings.month')}
                  </th>
                  <th
                    className="sort text-uppercase text-start"
                    data-sort="country"
                  >
                    {t('earnings.revenue')}
                  </th>
                  <th
                    className="sort text-uppercase text-start"
                    data-sort="action"
                  >
                    {t('earnings.actions')}
                  </th>
                </tr>
              </thead>
              <tbody className="list form-check-all" id="invoice-list-data">
                {allEarnings?.map((earning) => {
                  return (
                    <tr key={earning?.id}>
                      <td className="id text-start">
                        <a className="fw-medium text-muted">{earning?.id}</a>
                      </td>
                      <td className="customer_name text-start">
                        <a className="text-muted fw-medium">
                          <i className="ri-calendar-event-fill me-1 align-bottom" />
                          {earning?.year}
                        </a>
                      </td>
                      <td className="country text-start">
                        <div className="text-muted fw-medium">
                          <i className="ri-calendar-event-fill me-1 align-bottom" />
                          {getRightMonth(earning?.month)}
                        </div>
                      </td>
                      <td className="country text-start">
                        <div className="text-muted fw-medium d-flex align-items-center">
                          <i className="ri-money-euro-box-line me-1 align-bottom" />
                          {earning?.revenue}
                        </div>
                      </td>

                      <td>
                        <ul className="list-inline hstack gap-2 mb-0">
                          <li
                            className="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title="View"
                          >
                            <a
                              className="text-primary d-inline-block cursor-pointer"
                              onClick={() => setEarningCreate(earning)}
                              href="#earningUpdate"
                              data-bs-toggle="modal"
                            >
                              <i className="ri-edit-2-fill fs-16" />
                            </a>
                          </li>
                          <li
                            className="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title="View"
                          >
                            <a
                              className="text-primary d-inline-block cursor-pointer"
                              onClick={() => setEarningCreate(earning)}
                              href="#deleteEarning"
                              data-bs-toggle="modal"
                            >
                              <i className="ri-delete-bin-5-line text-danger fs-16" />
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end mt-3">
            {totalPages <= 1 ? (
              ''
            ) : (
              <Pagination
                page={filterParams.page}
                totalPages={totalPages}
                handlePagination={handlePages}
              />
            )}
          </div>
        </div>
      </div>
      <ConfirmDeleteModal
        modalId="deleteEarning"
        title={t('earnings.earning')}
        deleteItem={deleteEarning}
      />
      <EarningModal
        modalTitle={t('earnings.earning_create')}
        modalId="earningCreate"
        key={keyForCreate}
        createEarning={createEarning}
        changeHandler={changeHandler}
      />
      <EarningModal
        modalTitle={t('earnings.earning_update')}
        modalId="earningUpdate"
        earningCreate={earningCreate}
        createEarning={updateEarning}
        changeHandler={changeHandler}
      />
    </div>
  );
};
