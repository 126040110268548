import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../model/shared/IAllStates";
import { FilterStatus } from "../../../shared/enums/Enums";

export function DashboardTabs({ filterTenders, filterParams }: any) {
  const { filter } = useSelector((state: IAllStates) => state.enums);
  const { t } = useTranslation();
  return (
    <>
      <div className="col-xl-6">
        <ul
          className="nav nav-tabs nav-tabs-custom nav-success nav-justified"
          role="tablist"
        >
          {filter?.app.appDashboardFilterStatus?.map((filter: string) => {
            return (
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    filterParams?.status === filter ? "active" : ""
                  }`}
                  onClick={() => filterTenders(filter)}
                  data-bs-toggle="tab"
                  role="tab"
                  aria-selected="true"
                >
                  {t(
                    FilterStatus[filter as keyof typeof FilterStatus] as string
                  )}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
