import {Company} from '../../../model/company/Company';
import {User} from '../../../model/user/User';
import {IPicture} from '../../../model/shared/IPicture';
import {IContact} from '../../../model/shared/IContact';

const initialCompany: Company = {
    address: '',
    country: '',
    createdAt: '',
    email: '',
    id: 0,
    phone: '',
    title: '',
    updatedAt: '',
    zip: ''
}
const initialPhoto: IPicture = {
    createdAt: '',
    id: 0,
    path: '',
    updatedAt: ''
}
const initialContact: IContact = {
    address: '',
    city: '',
    country: '',
    phone: '',
    zip: ""
}
export const initialUser: User = {
    isActive: false,
    contact: initialContact,
    company: initialCompany,
    createdAt: '',
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    profilePicture: initialPhoto,
    roles: [],
    updatedAt: ''
}
export const initialCreateUser: User = {
    isActive: false,
    contact: initialContact,
    company: initialCompany,
    createdAt: '',
    id: 0,
    profilePicture: initialPhoto,
    updatedAt: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    roles: []
}