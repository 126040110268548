import React, {useState} from "react";
import {iChangePassword} from "../../../model/user/User";
import {ProfileService} from "../service";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {useTranslation} from "react-i18next";

export function ChangePassword() {
    const {t} = useTranslation()
    const [password, setPassword] = useState<iChangePassword>()
    const [visibilityPassword, setVisibility] = useState(false)
    const [visibilityOldPassword, setOldVisibility] = useState(false)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword((prev: any) => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    function changePass() {
        ProfileService.changePassword(password!).then(res => {
            res && SuccessToast(`${t('profile.toasts.successfully_changed_password')}`)
        }).catch(error => ErrorToast(error))
    }

    return (
        <div className="row g-2">
            <div className="col-lg-4">
                <div>
                    <label className="form-label" htmlFor="password-input">{t("profile.old_password")}</label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                        <input name="oldPassword" onChange={handleChange}
                               type={`${!visibilityOldPassword ? 'password' : 'text'}`}
                               className="form-control pe-5"
                               placeholder={`${t('profile.enter_password')}`} id="password-input"/>
                        <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button" id="password-addon"
                            onClick={() => setOldVisibility(!visibilityOldPassword)}
                        >
                            <i className="ri-eye-fill align-middle"/>
                        </button>
                    </div>
                </div>
            </div>

            <div className="col-lg-4">
                <div>
                    <label className="form-label" htmlFor="password-input">{t("profile.new_password")}</label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                        <input name="newPassword" onChange={handleChange}
                               type={`password`}
                               className="form-control pe-5"
                               placeholder={`${t('profile.enter_password')}`} id="password-input"/>
                    </div>
                </div>
            </div>

            <div className="col-lg-12">
                <div className="text-end">
                    <button onClick={changePass} type="submit"
                            className="btn btn-success">{t("profile.change_password")}
                    </button>
                </div>
            </div>
        </div>
    )
}