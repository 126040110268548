export enum FilterStatus {
  "recent" = "dashboard.tenders.filters.recent",
  "in_progress" = "dashboard.tenders.filters.in_progress",
  "submitted" = "dashboard.tenders.filters.submitted",
  "canceled" = "dashboard.tenders.filters.canceled",
  "won" = "Tenders.Status.won",
  "lost" = "Tenders.Status.lost",
  "done" = "statuses.done",
  "pending" = "dashboard.cards.pending",
}

export enum CrudTitle {
  "client" = "Tenders.details.client",
  "cancellation" = "side_bar.cancellation",
  "irrelevance" = "side_bar.irrelevance",
  "loss" = "side_bar.loss",
  "tender type" = "side_bar.tender_type",
  "location" = "side_bar.locations",
  "functions" = "side_bar.functions",
  "commissioner" = "side_bar.commissioner",
}

export enum TableTitle {
  "Name" = "cruds.name",
  "Created at" = "cruds.createdAt",
  "Updated at" = "cruds.updatedAt",
  "Actions" = "cruds.actions",
  "Reason" = "cruds.reason",
  "Title" = "cruds.title",
}

export enum PriortyTask {
  "high" = "Tenders.Checklist.modal.high",
  "medium" = "Tenders.Checklist.modal.medium",
  "low" = "Tenders.Checklist.modal.low",
}

export enum StatusTask {
  "pending" = "Tenders.Checklist.modal.pending",
  "new" = "Tenders.Checklist.modal.new",
  "completed" = "Tenders.Checklist.modal.completed",
}

export enum StatisticsName {
  "excepted_monthly_revenue" = "Statistics.names.excepted_monthly_revenue",
  "order_backlog_ytg" = "Statistics.names.ytg_order_backlog",
  "order_backlog_12_month" = "Statistics.names.12_month_order_backlog",
  "order_backlog_total" = "Statistics.names.cumulative_expected_revenue_as_of_today",
  "tender_applications_sum" = "Statistics.names.tender_applications_sum",
  "tender_applications_count" = "Statistics.names.tender_applications_count",
  "potential_12_month" = "Statistics.names.12_month_potential",
  "potential_total" = "Statistics.names.potential_total",
  "Total Potential" = "Statistics.names.total_potential",
  "reasons_for_cancellation_count" = "Statistics.names.reason_for_cancellation_count",
  "reasons_for_cancellation_sum" = "Statistics.names.reason_for_cancellation_monthly",
  "cumulative_expected_revenue" = "Statistics.names.cumulative_expected_revenue",
  "reasons_for_irrelevance_count" = "Statistics.names.reasons_for_irrelevance_count",
  "reasons_for_irrelevance_sum" = "Statistics.names.reasons_for_irrelevance_monthly",
  "cumulative_expected_revenue_total" = "Statistics.names.cumulative_expected_revenue_total",
  "cumulative_total_potential_revenue" = "Statistics.names.cumulative_total_potential_revenue",
  "cumulative_total_expected_revenue_plus_potential_revenue" = "Statistics.names.cumulative_total_expected_revenue_plus_potential_revenue",
  "cumulative_expected_revenue_plus_potential_revenue" = "Statistics.names.cumulative_expected_revenue_plus_potential_revenue",
  "number_of_active_tenders" = "Statistics.names.number_of_active_tenders",
  "reasons_for_loss_count" = "Statistics.names.reasons_for_loss_count",
  "order_backlog_after_awardance" = "Statistics.names.order_backlog_after_awardance",
  "order_backlog_12_month_after_awardance" = "Statistics.names.order_backlog_12_month_after_awardance",
}

export enum PersonelTitles {
  "tender_submitted_percent" = "personel.percentage_of_submitted",
  "tender_irrelevance_distribution" = "personel.distribution_of_reasons",
  "tender_won_distribution_by_function" = "personel.won_distribution_by_function",
  "tender_won_revenue_by_function" = "personel.won_revenue_by_function",
  "tender_cancellation_distribution" = "personel.cancellation_distribution",
}
