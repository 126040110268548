import React, { ReactChild, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { StatisticsService } from "../service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { EnumService } from "../../../store/enums/enumService";
import { useTranslation } from "react-i18next";
import { StatisticsName } from "../../../shared/enums/Enums";
import { BASE_URL } from "../../../utils/helpers/api.routes";
import { iStatistics } from "../../../model/statistics/Statistics";

export function StatisticsPage() {
  const [currentChart, setCurrentChart] = useState({
    name: "excepted_monthly_revenue",
    chart: "Expected Monthly Revenue",
    unit: "base",
    series: [],
    xaxis: {},
    fromDate: `${new Date().getFullYear()}-01`,
    toDate: `${new Date().getFullYear()}-12`,
  });

  const [chartData, setChartData] = useState<iStatistics>();

  const chartUnits = ["base", "thousand"];
  const [statisticsOrder, setStatiscisOrder] = useState({
    charts: [],
  });
  const [allCompanies, setAllCompanies] = useState<any>();
  const [selectedUnit, setSelectedUnit] = useState(chartUnits[0]);
  const [tableInfo, setTableInfo] = useState<any>({ tender: [], total: [] });
  const [tableFilter, setTableFilter] = useState({});
  const json: any = localStorage.getItem("currentUser");
  const currentUser: any = JSON.parse(json);

  const haveStartDate =
    currentChart.name === "excepted_monthly_revenue" ||
    currentChart.name === "number_of_active_tenders" ||
    currentChart.name === "order_backlog_12_month_after_awardance" ||
    currentChart.name === "order_backlog_after_awardance";
  const haveEndDate =
    currentChart.name === "excepted_monthly_revenue" ||
    currentChart.name === "number_of_active_tenders";

  const renderChart = (state: any) => {
    if (state) {
      return (
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mb-0">
                {t(
                  StatisticsName[
                    state.chart as keyof typeof StatisticsName
                  ] as string
                )}
              </h4>
            </div>
            <div className="card-body" style={{ overflow: "auto" }}>
              <Chart
                options={{
                  ...(options as any),
                  xaxis: state?.xaxis,
                }}
                series={state?.series}
                type="bar"
                width="100%"
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="d-flex align-items-center justify-content-center flex-column"
          style={{ minHeight: "80vh" }}
        >
          <div className="spinner-border" role="status" />
          <h5 className="fw-bold mt-3">Loading...</h5>
        </div>
      );
    }
  };
  const changeCompanyHandler = (e: any) => {
    const company = allCompanies?.company.find(
      (company: any) => company.title === e.target.value
    );
    setCurrentChart((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.value === "All companies" ? "All companies" : company.id,
    }));
  };
  const changeDateHandler = (e: any) => {
    if (
      currentChart.name === "excepted_monthly_revenue" ||
      currentChart.name === "number_of_active_tenders" ||
      currentChart.name === "order_backlog_12_month_after_awardance" ||
      currentChart.name === "order_backlog_after_awardance"
    ) {
      setCurrentChart((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };
  const changeChartHandler = (e: any) => {
    setCurrentChart((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const changeUnitHandler = (e: any) => {
    setCurrentChart((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    StatisticsService.getStatistics(currentChart).then((res: any) => {
      console.log(currentChart);
      // DEFAULT VALUE
      setChartData(res);
    });
  }, [JSON.stringify(currentChart)]);

  useEffect(() => {
    EnumService.getEnums()
      .then((res) => {
        if (currentUser?.roles?.includes("ROLE_SUPER_ADMIN")) {
          setStatiscisOrder({
            charts: (res?.charts as any)?.map((chart: any) => ({
              name: chart,
              unit: selectedUnit,
            })),
          });
        } else {
          setStatiscisOrder({
            charts: (res?.charts_company as any)?.map((chart: any) => ({
              name: chart,
              unit: selectedUnit,
            })),
          });
        }
      })
      .catch((error) => ErrorToast(error));
  }, []);

  const exportData = async () => {
    const data = await StatisticsService.getRevenueViaExport(tableFilter);
    const url = BASE_URL + data.path;
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link?.setAttribute("download", `${Date.now()}.xlsx`);
    link.click();
    link.remove();
  };

  tableInfo?.total?.forEach((total: any) => {
    tableInfo?.tender?.forEach((tender: any) => {
      if (!tender.month[total.month]) tender.month[total.month] = 0;
    });
  });

  const th = tableInfo?.total?.map((total: any) => {
    return (
      <th
        key={total.month}
        data-column-id="id"
        style={{ minWidth: 42, width: 65 }}
      >
        <div className="gridjs-th-content">{total.month}</div>
      </th>
    );
  });

  const changeDateFilterHandler = (e: any) => {
    setTableFilter((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const changeFilterHandler = (e: any) => {
    const companyData = allCompanies.company.find(
      (comp: any) => comp.title === e.target.value
    );
    setTableFilter((prev: any) => ({
      ...prev,
      [e.target.name]: companyData?.id,
    }));
  };

  const changeTableTenderNumber = (e: any) => {
    setTableFilter((prev: any) => ({ ...prev, tenderNumber: e.target.value }));
  };

  useEffect(() => {
    StatisticsService.getTendersMonthlyRevenue(tableFilter)
      .then((res) => {
        setTableInfo(res);
      })
      .catch((err) => ErrorToast(err));
  }, [tableFilter]);
  // END OF TABLE

  useEffect(() => {
    EnumService.getEnums()
      .then((res) => {
        if (currentUser?.roles?.includes("ROLE_SUPER_ADMIN")) {
          setStatiscisOrder({
            charts: (res?.charts as any)?.map((chart: any) => ({
              name: chart,
              unit: selectedUnit,
            })),
          });
        } else {
          setStatiscisOrder({
            charts: (res?.charts_company as any)?.map((chart: any) => ({
              name: chart,
              unit: selectedUnit,
            })),
          });
        }
      })
      .catch((error) => ErrorToast(error));
  }, []);

  useEffect(() => {
    StatisticsService.getCompanies()
      .then((res) => {
        setAllCompanies(res);
      })
      .catch((error) => {
        ErrorToast(error);
      });
  }, []);

  const options = {
    chart: {
      type: "bar",
      height: 200,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      labels: {
        formatter: function (value: any) {
          return value.toLocaleString("de");
        },
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,
      formatter: function (value: any) {
        return value.toLocaleString("de");
      },
    },

    colors: ["#405189", "#0ab39c", "#f7b84b", "#F06548"],
    fill: {
      opacity: 1,
    },
  };

  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="card-body">
        {/* Nav tabs */}
        <ul
          className="nav nav-pills animation-nav nav-justified gap-2 mb-3"
          role="tablist"
        >
          <li className="nav-item waves-effect waves-light">
            <a
              className="nav-link active"
              data-bs-toggle="tab"
              href="#animation-statistics"
              role="tab"
            >
              Statistics
            </a>
          </li>
          <li className="nav-item waves-effect waves-light">
            <a
              className="nav-link"
              data-bs-toggle="tab"
              href="#animation-table"
              role="tab"
            >
              Tender Table
            </a>
          </li>
        </ul>
        <div className="tab-content text-muted">
          <div
            className="tab-pane active"
            id="animation-statistics"
            role="tabpanel"
          >
            <div className="row justify-content-between align-items-center">
              <div className={"d-flex gap-3 col-6 me-auto"}>
                {haveStartDate && (
                  <div className="col-6">
                    <label htmlFor="year">{t("Statistics.from_date")}</label>
                    <input
                      type="month"
                      name="fromDate"
                      defaultValue={`${new Date().getFullYear()}-01`}
                      autoComplete="of"
                      placeholder="Month"
                      className="form-control"
                      onChange={changeDateHandler}
                    />
                  </div>
                )}
                {haveEndDate && (
                  <div className="col-6 me-auto">
                    <label htmlFor="year">{t("Statistics.to_date")}</label>
                    <input
                      type="month"
                      name="toDate"
                      defaultValue={`${new Date().getFullYear()}-12`}
                      autoComplete="of"
                      placeholder="Month"
                      className="form-control"
                      onChange={changeDateHandler}
                    />
                  </div>
                )}
              </div>

              <div className="col-5 ms-auto">
                <div className="d-flex align-items-center gap-2 ">
                  {currentUser?.roles?.includes("ROLE_SUPER_ADMIN") && (
                    <div className="col-4">
                      <label>All companies</label>
                      <select
                        name="company"
                        className="form-select ms-auto"
                        onChange={changeCompanyHandler}
                      >
                        <option value={"All companies"}>{`${t(
                          "Statistics.all_companies"
                        )}`}</option>
                        {allCompanies?.company?.map((company: any) => {
                          return (
                            <option value={company?.title} key={company?.title}>
                              {company?.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <div className="col-4">
                    <label>Charts</label>
                    <select
                      name="name"
                      className="form-select"
                      onChange={changeChartHandler}
                    >
                      <option hidden>{`${t(
                        "Statistics.select_chart"
                      )}`}</option>

                      {statisticsOrder?.charts?.map((chart: any) => {
                        return (
                          <option value={chart?.name} key={chart?.name}>
                            {console.log(chart?.name)}
                            {t(
                              StatisticsName[
                                chart?.name as keyof typeof StatisticsName
                              ] as string
                            )}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-4">
                    <label>Unit</label>
                    <select
                      name="unit"
                      className="form-select"
                      onChange={changeUnitHandler}
                    >
                      <option hidden>{`${t("Statistics.select_unit")}`}</option>
                      {chartUnits?.map((unit, i) => {
                        return (
                          <option value={unit} key={i}>
                            {unit}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              {renderChart(chartData)}
            </div>
          </div>

          <div className="tab-pane" id="animation-table" role="tabpanel">
            <div className="card">
              <div className="card-header align-items-center d-flex gap-4">
                <div className="d-flex col-2 align-items-center justify-content-between">
                  <h4 className="card-title mb-0 ">
                    {t("Statistics.tender_table")}
                  </h4>
                  <button
                    className="btn btn-primary"
                    id="exportButton"
                    onClick={exportData}
                  >
                    Export
                  </button>
                </div>
                <div className="col-md-2 mt-2 ms-auto ">
                  <label htmlFor="tenderNumber">Number</label>
                  <div className="search-box">
                    <input
                      type="text"
                      name="tenderNumber"
                      onChange={changeTableTenderNumber}
                      className="form-control search bg-light border-light"
                      placeholder={t("Tenders.search_number")}
                    />
                    <i className="ri-search-line search-icon" />
                  </div>
                </div>
                {currentUser?.roles?.includes("ROLE_SUPER_ADMIN") && (
                  <div className="col-md-2 mt-2 ">
                    <label htmlFor="company">{t("Statistics.company")}</label>
                    <select
                      name="company"
                      className="form-select"
                      onChange={changeFilterHandler}
                    >
                      <option value={"All companies"}>{`${t(
                        "Statistics.all_companies"
                      )}`}</option>
                      {allCompanies?.company?.map((company: any) => {
                        return (
                          <option value={company?.title} key={company?.title}>
                            {company?.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}

                <div className="col-md-2 mt-2">
                  <label htmlFor="year">{t("Statistics.from_date")}</label>
                  <input
                    type="month"
                    name="fromDate"
                    onChange={changeDateFilterHandler}
                    autoComplete="of"
                    placeholder="Month"
                    className="form-control"
                    defaultValue="undefined-undefined"
                  />
                </div>
                <div className="col-md-2 mt-2">
                  <label htmlFor="year">{t("Statistics.to_date")}</label>
                  <input
                    type="month"
                    name="toDate"
                    onChange={changeDateFilterHandler}
                    autoComplete="of"
                    placeholder="Month"
                    className="form-control"
                    defaultValue="undefined-undefined"
                  />
                </div>
              </div>

              <div className="card-body">
                <div className="live-preview">
                  <div className="stats-table table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>{t("Statistics.tender")}</th>
                          <th>Number</th>
                          <th>Max Revenue</th>
                          {th}
                        </tr>
                      </thead>
                      <tbody>
                        {tableInfo?.tender?.map((item: any) => {
                          return (
                            <tr key={item.id}>
                              <td>{item?.id}</td>
                              <td>{item?.title}</td>
                              <td>{item?.tenderNumber}</td>
                              <td>{item?.maxRevenue}</td>

                              {tableInfo?.total?.map((ele: any) => {
                                return (
                                  <td key={ele?.month}>
                                    {item?.month[ele?.month]}{" "}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                        <tr>
                          <th>{t("Statistics.total")}</th>
                          <td></td>
                          <td></td>
                          <td></td>
                          {tableInfo?.total?.map((total: any) => {
                            return (
                              <td key={total?.month}>
                                {total?.amount?.toFixed(2)}
                              </td>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end card-body */}
    </div>
  );
}
