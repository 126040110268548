import { BASE_URL } from "../../utils/helpers/api.routes";
import React, { SetStateAction } from "react";
import { iCompanyDocument } from "../../model/company/Company";

interface iDocumentData {
  name: string;
  size: number;
}

interface Props {
  setUpdateList?: React.Dispatch<SetStateAction<boolean>>;
  document: iCompanyDocument | any;
  modalId?: string;
  setSelectedDocument?: React.Dispatch<SetStateAction<number>>;
  isButtonVisible?: boolean;
}
export const PreviewDocumentComponent = ({
  modalId,
  document,
  setSelectedDocument,
  isButtonVisible = true,
}: Props) => {
  return (
    <div key={document?.id}>
      <div className="d-flex border border-dashed p-2 rounded position-relative my-2">
        <div className="flex-shrink-0 avatar-xs">
          <div className="avatar-title bg-soft-danger text-danger fs-15 rounded">
            <i className="ri-image-2-line" />
          </div>
        </div>
        <div className="flex-shrink-0"></div>
        <div className="flex-grow-1 overflow-hidden ms-2">
          <h6 className="text-truncate mb-0">
            {isButtonVisible ? document?.file?.nameOriginal : document?.name}
          </h6>
          <small>
            {isButtonVisible ? document?.file?.size : document?.size} KB
          </small>
        </div>
        {isButtonVisible && (
          <div className="d-flex ">
            <a
              className="dropdown-item p-2"
              href={`${BASE_URL + document?.path}`}
              target="_blank"
            >
              <i className="ri-eye-fill text-muted cursor-pointer" />
            </a>
            {
              <a
                className="dropdown-item p-2"
                href={`#${modalId}`}
                data-bs-toggle="modal"
                onClick={() => {
                  setSelectedDocument && setSelectedDocument(document?.id);
                }}
              >
                <i className="ri-delete-bin-2-fill text-danger cursor-pointer" />
              </a>
            }
          </div>
        )}
      </div>
    </div>
  );
};
