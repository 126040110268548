import React, { useEffect, useState } from 'react';
import { TenderTabs } from './TenderTabs';
import Select from 'react-select';
import { IClient } from '../../../model/user/IClient';
import { TenderService } from '../service';
import { ErrorToast } from '../../../shared/toasters/toasters';
import { ISupervisor } from '../../../model/user/ISupervisor';
import { ITenderLocation } from '../../../model/tender/ITenderLocation';
import { ITenderType } from '../../../model/tender/ITenderType';
import { ICommissioner } from '../../../model/user/ICommissioner';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FilterStatus } from '../../../shared/enums/Enums';
import { Filter } from '@material-ui/icons';

export const themeSelect = (theme: any) => {
  return {
    ...theme,
    borderRadius: 5,
    minHeight: 40,
    colors: {
      ...theme.colors,
      text: 'red',
      primary25: '#5cd3b9',
      primary: '#38cab3',
    },
  };
};

export function TenderFilter({
  filterTenders,
  title,
  titleFilter,
  filterParams,
  setFilter,
  status,
}: any) {
  const { t } = useTranslation();
  const [client, setClient] = useState<IClient[]>();
  const [supervisor, setSupervisor] = useState<ISupervisor[]>();
  const [commissioner, setCommissioner] = useState<ICommissioner[]>();
  const [location, setLocation] = useState<ITenderLocation[]>();
  const [tenderType, setTenderType] = useState<ITenderType[]>();

  const navigate = useNavigate();

  useEffect(() => {
    TenderService.getTenderFilters()
      .then((res) => {
        if (res) {
          setClient(res.client);
          setSupervisor(res.supervisor);
          setCommissioner(res.commissioner);
          setLocation(res.location);
          setTenderType(res.tenderType);
        }
      })
      .catch((error) => ErrorToast(error));
  }, []);

  const selectOptions = (arr: any, param: any, haveTwoParams?: boolean) => {
    return arr?.map((typ: any) => {
      return {
        value: typ.id,
        label: haveTwoParams
          ? `${typ?.firstName} ${typ?.lastName}`
          : typ[param],
      };
    });
  };
  const changeClientHandler = (e: any, type: string) => {
    setFilter((prev: any) => ({ ...prev, [type]: e?.value }));
  };
  const passData = () => {
    navigate('/tender/create', { state: { isEdit: false } });
  };
  return (
    <>
      <div className="card-header border-0">
        <div className="d-flex align-items-center">
          <h4 className="card-title mb-0 flex-grow-1">
            {t(FilterStatus[status as keyof typeof FilterStatus] as string)}{' '}
            Tenders
          </h4>
          <div className="flex-shrink-0">
            <a onClick={passData} className="btn btn-success">
              <i className="ri-add-line align-bottom me-1" />{' '}
              {t('Tenders.create_tender')}
            </a>
          </div>
        </div>
      </div>

      <TenderTabs
        filterParams={filterParams}
        filterTenders={filterTenders}
        setFilter={setFilter}
      />

      <div className="card-body bg-soft-light border border-dashed border-start-0 border-end-0">
        <form>
          <div className="row g-3">
            <div className="col-lg ">
              <div className="search-box">
                <input
                  type="text"
                  name="tenderNumber"
                  className="form-control search bg-light border-light"
                  placeholder={t('Tenders.search_number')}
                  onChange={(event) => titleFilter(event)}
                />
                <i className="ri-search-line search-icon" />
              </div>
            </div>
            <div className="col-lg ">
              <div className="search-box">
                <input
                  type="text"
                  name="title"
                  className="form-control search bg-light border-light"
                  placeholder={t('Tenders.search_tender')}
                  onChange={(event) => titleFilter(event)}
                />
                <i className="ri-search-line search-icon" />
              </div>
            </div>
            <div className="col-lg">
              <Select
                placeholder={t('Tenders.search_supervisor')}
                options={selectOptions(supervisor, 'firstName', true)}
                onChange={(e) => changeClientHandler(e, 'supervisor')}
                theme={themeSelect}
                isClearable={true}
              />
            </div>
            <div className="col-lg">
              <Select
                placeholder={t('Tenders.search_commissioner')}
                options={selectOptions(commissioner, 'name')}
                onChange={(e) => changeClientHandler(e, 'commissioner')}
                theme={themeSelect}
                isClearable={true}
              />
            </div>
            <div className="col-lg">
              <Select
                placeholder={t('Tenders.search_location')}
                options={selectOptions(location, 'title')}
                onChange={(e) => changeClientHandler(e, 'location')}
                theme={themeSelect}
                isClearable={true}
              />
            </div>
            <div className="col-lg">
              <Select
                placeholder={t('Tenders.search_tender_type')}
                options={selectOptions(tenderType, 'title')}
                onChange={(e) => changeClientHandler(e, 'type')}
                theme={themeSelect}
                isClearable={true}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
