import { PageTemplate } from "../../shared/layout/PageTemplate";
import { UserList } from "./components/UserList";
import { UserForm } from "./components/UserForm";
import { useTranslation } from "react-i18next";

export function Users() {
  const { t } = useTranslation();
  return (
    <PageTemplate
      pageName={`${t("header.title.users")}`}
      title={`${t("header.title.users")} list`}
      content={<UserList />}
    />
  );
}

export function UserFormPage() {
  const { t } = useTranslation();

  return (
    <PageTemplate
      pageName={`${t("header.title.users")}`}
      title={`${t("header.title.users")} list`}
      content={<UserForm />}
    />
  );
}
