import React from "react";
import { useTranslation } from "react-i18next";
import { iModal } from "../../model/shared/ICrud";

export function Modals({
  modalId,
  title,
  updateChanges,
  body,
  saveButtonName,
  buttonType,
}: iModal) {
  const { t } = useTranslation();
  return (
    <div className="modal fade " id={modalId}>
      <div className="modal-dialog " role="document">
        <div className="modal-content modal-content-demo ">
          <div className="modal-header">
            <h6 className="modal-title">{title}</h6>
            <button
              aria-label="Close"
              className="btn-close"
              data-bs-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {body !== undefined && <div className="modal-body">{body}</div>}
          <div className="modal-footer justify-content-end">
            <button
              className="btn ripple btn-danger"
              data-bs-dismiss="modal"
              type="button"
            >
              {t("modals.close")}
            </button>
            <button
              className={`${
                buttonType === undefined ? "btn-primary" : `${buttonType}`
              } btn ripple`}
              type="button"
              data-bs-dismiss="modal"
              onClick={updateChanges}
            >
              {saveButtonName === undefined
                ? `${t("Tenders.Checklist.modal.save")}`
                : `${saveButtonName}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
