import React, { SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../../../model/user/User';
import { BASE_URL } from '../../../utils/helpers/api.routes';
import { useTranslation } from 'react-i18next';

interface IProps {
  user: User;
  setSelectedUser: React.Dispatch<SetStateAction<User | undefined>>;
}

export const trimRoles = (role: string) => {
  if (role.slice(4).includes('COMPANY')) {
    return role.slice(12).split('_').join(' ') + `, `;
  } else {
    return role.split('_').join(' ') + `, `;
  }
};

export function UserItem({ user, setSelectedUser }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const passData = () => {
    navigate('/user/form', { state: { user: user, isEdit: true } });
  };
  console.log();

  return (
    <>
      <div className="card team-box">
        <div className="team-cover">
          <img
            src="/assets/images/small/img-9.jpg"
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="card-body p-md-4">
          <div className="row align-items-center team-row w-100 mx-0 justify-content-between">
            <div className=" col-xl-3 ">
              <div className="team-profile-img m-0">
                <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                  <img
                    src={`${BASE_URL}${user?.profilePicture?.path}`}
                    alt={user.firstName}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = '/images/placeholderImage.png';
                    }}
                    className="img-fluid d-block rounded-circle h-100"
                  />
                </div>
                <div className="team-content">
                  {/* <a
                    data-bs-toggle="offcanvas"
                    href="#offcanvasExample"
                    aria-controls="offcanvasExample"
                  > */}
                  <h5 className="fs-16 mb-1">
                    {user?.firstName} {user?.lastName}
                  </h5>
                  {/* </a> */}
                  <p
                    className="text-muted mb-0"
                    style={{ wordBreak: 'break-all' }}
                  >
                    {user?.email}
                  </p>
                </div>
              </div>
            </div>
            <div className=" col-lg-4  ">
              <div className="text-center text-md-start">
                <p className="text-muted mb-1">{t('Users.role')}</p>
                {user?.roles?.map((role) => (
                  <h5 key={user?.createdAt} className="fs-14">
                    {trimRoles(role)}
                  </h5>
                ))}
              </div>
            </div>
            <div
              className={`${
                user.isActive ? 'text-success' : 'text-danger'
              } col-lg-2 my-3 my-0 text-center `}
            >
              <i className="ri-checkbox-circle-line fs-17 align-middle" />{' '}
              {t(user?.isActive ? 'Users.active' : 'Users.deactivated')}
            </div>
            {/* //srediti */}
            {!user.roles.some((role) => role === 'ROLE_SUPER_ADMIN') ? (
              <div className="col-lg-2">
                {user?.isActive && (
                  <a onClick={passData} className="dropdown-item px-0">
                    <i className="ri-edit-box-line me-2 align-middle" />
                    {t('Users.edit')}
                  </a>
                )}
                {
                  <a
                    className="dropdown-item px-0"
                    href="#showModal"
                    onClick={() => setSelectedUser(user)}
                    data-bs-toggle="modal"
                  >
                    <i className="  ri-close-circle-line me-2 align-middle" />
                    {t(!user?.isActive ? 'Users.activate' : 'Users.deactivate')}
                  </a>
                }
              </div>
            ) : (
              <div className="col-lg-2"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
