import { PersonelFiltering } from './PersonelFiltering';

export const WonDistributionByFunction = ({
  data,
  setSelectedKey,
  t,
  changeHandler,
  listOfAllUsers,
  selectedKey,
  userFunctions,
  irrelevanceReasons,
  cancellationList,
}: any) => {
  return (
    <>
      <PersonelFiltering
        setSelectedKey={setSelectedKey}
        t={t}
        changeHandler={changeHandler}
        listOfAllUsers={listOfAllUsers}
        selectedKey={selectedKey}
        userFunctions={userFunctions}
        cancellationList={cancellationList}
        irrelevanceReasons={irrelevanceReasons}
      />
      {data?.data?.map((distribution: any) => {
        console.log(distribution);
        return (
          <div key={distribution?.id} className=' mb-5'>
            <h3 className='fw bold  mb-3 '>
              {`${distribution?.firstName} ${distribution?.lastName} `}
              <span className='fs-6'>{`- ( ${distribution?.email} )`}</span>
            </h3>
            <div className='table-responsive table-card'>
              <table className='table table-nowrap' id='invoiceTable'>
                <thead className='text-muted'>
                  <tr id='personel-row'>
                    <th className='col'>{t('personel.functionTitle')}</th>
                    <th className='col'>{t('personel.percent')}</th>
                    <th className='col'>{t('personel.total')}</th>
                    <th className='col'>{t('personel.totalWon')}</th>
                  </tr>
                </thead>
                <tbody className='list form-check-all' id='invoice-list-data'>
                  {distribution?.data?.map(
                    (reason: {
                      functionId: number;
                      functionTitle: string;
                      total: number;
                      totalWon: number;
                      percent: number;
                    }) => {
                      return (
                        <tr id='personel-row' key={reason?.functionId}>
                          <td className='col-6'>{reason?.functionTitle}</td>
                          <td className='col'>{reason?.percent} %</td>
                          <td className='col'>{reason?.total} %</td>
                          <td className='col'>{reason?.totalWon} %</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
    </>
  );
};
