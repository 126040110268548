import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { iPagination, iUseTenders, searchDto } from "../../model/shared/IHooks";
import { SuccessToast } from "../toasters/toasters";

export function useTenderCrud({
  getData,
  putData,
  deleteData,
  getMany,
  addData,
}: iUseTenders) {
  const inputRef = useRef<any>(null);
  const modalRef = useRef<any>(null);
  const { t } = useTranslation();
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<iPagination>({
    page: 1,
    perPage: 10,
    name: "",
    title: "",
    reason: "",
    email: "",
    client: "",
    supervisor: "",
    company: "",
    status: "",
    isRelevant: false,
    isWon: false,
    isSubmitted: false,
  });

  const [showForm, setShowForm] = useState(false);
  const [dataState, setDataState] = useState<any>();
  const [listOfAllData, setListOfAllData] = useState<any[]>();
  const [selectedData, setSelectedData] = useState<any>();
  const [selectedModalData, setSelectedModalData] = useState<any>();
  const actionName = getData?.name.substring(3);

  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    if (event.target.name === "maxRevenue") {
      setSelectedData((prev: any) => ({
        ...prev,
        [event.target.name]: +event.target.value,
      }));
      setDataState((prev: any) => ({
        ...prev,
        [event.target.name]: +event.target.value,
      }));
    } else {
      setDataState((prev: any) => ({
        ...prev,
        [event.target.name]:
          event.target.name === "isProlongation"
            ? (event?.target as HTMLInputElement).checked
            : event?.target?.value,
      }));
      if (getData?.name === "getTender") {
        setSelectedData((prev: any) => ({
          ...prev,
          tender: {
            ...prev?.tender,
            [event.target.name]: event.target.value,
          },
        }));
      } else {
        setSelectedData((prev: any) => ({
          ...prev,
          [event.target.name]: event.target.value,
        }));
      }
    }
  };

  const modalDataHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "file") {
      setSelectedData((prev: any) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    } else {
      setSelectedData((prev: any) => ({
        ...prev,
        company: {
          ...prev!.company,
          [event.target.name]: event.target.value,
        },
      }));
    }
  };

  const filterData = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (event.target.name === "email-filter") {
      setPagination((prev) => ({ ...prev, email: event.target.value }));
    } else {
      setPagination((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };

  const createData = () => {
    addData(dataState)
      .then((res) => {
        if (res !== undefined) {
          localStorage.removeItem("selectedCompanyID");
          setDataState({});
          SuccessToast(
            `${actionName} ${t("cruds.toasts.successfully_created")}`
          );
          setShowForm(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateData = () => {
    putData(selectedData!?.id!, selectedData!)
      .then((res) => {
        if (res) {
          const paginationState = searchDto(pagination);
          getMany(paginationState)
            .then((data) => {
              if (data) {
                SuccessToast(
                  `${t("cruds.toasts.successfully_updated")} ${actionName}`
                );
                setTotalPages(Math.ceil(data?.count / data?.perPage));
                setListOfAllData(data?.data);
              }
            })
            .catch((error) => error);
        }
      })
      .catch((error) => error);
  };

  const getCurrentDataID = (id: number) => {
    localStorage.setItem("selectedCompanyID", JSON.stringify(id));
    getData(id)
      .then((res) => {
        setSelectedData(res);
        setSelectedModalData(res);
      })
      .catch((error) => error);
  };

  const removeData = () => {
    deleteData!(selectedData!?.id!)
      .then((res) => {
        if (res !== undefined) {
          const paginationState = searchDto(pagination);
          getMany(paginationState)
            .then((data) => {
              if (data) {
                SuccessToast(
                  `${t("cruds.toasts.successfully_deleted")} ${actionName}`
                );
                setTotalPages(Math.ceil(data?.count / data?.perPage));
                setListOfAllData(data?.data);
              }
            })
            .catch((error) => error);
        }
      })
      .catch((error) => error);
  };

  useEffect(() => {
    const paginationState = searchDto(pagination);
    if (!showForm) {
      getMany(paginationState)
        .then((data) => {
          if (data) {
            setTotalPages(Math.ceil(data?.count / data?.perPage));
            setListOfAllData(data?.data);
          }
        })
        .catch((error) => error);
    }
  }, [page, showForm, getMany, JSON.stringify(pagination)]);

  return {
    page,
    handlePages,
    totalPages,
    listOfAllData,
    removeData,
    updateData,
    selectedModalData,
    createData,
    getCurrentDataID,
    changeHandler,
    showForm,
    setShowForm,
    modalDataHandler,
    selectedData,
    filterData,
    pagination,
    inputRef,
    modalRef,
  };
}
