import React, { useEffect, useState } from "react";
import { TenderTabs } from "../TenderTabs";
import { TenderCompanyItem } from "./TenderCompanyItem";
import { TenderTypeSwitcher } from "./TenderTypeSwitcher";
import {
  ErrorToast,
  SuccessToast,
  WarningToast,
} from "../../../../shared/toasters/toasters";
import { TenderService } from "../../service";
import {
  iBids,
  iCompanies,
  iCompanyBids,
  iUserFunctions,
} from "../../../../model/tender/ICompanyBids";
import { useLocation, useNavigate } from "react-router-dom";
import { TenderCompaniesTable } from "./TenderCompaniesTable";
import { EnumService } from "../../../../store/enums/enumService";
import { useTranslation } from "react-i18next";

export function TenderCompanies() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [bidderTypes, setBidderTypes] = useState<string[]>();
  const [companies, setCompanies] = useState<iCompanies[]>([]);
  const [isSingle, setIsSingle] = useState(true);
  const [companiesToBid, setCompaniesToBid] = useState<iBids[]>([]);
  const [numberOfCompanies, setNumberOfCompanies] = useState(1);
  const [userFunctions, setUserFunctions] = useState<iUserFunctions[]>();
  const [selectedCompaniesToBid, setSelectedCompaniesToBid] =
    useState<iCompanyBids>();
  const [biddedCompanies, setBiddedCompanies] = useState<iBids[]>();
  const [companiesToUpdate, setCompaniesToUpdate] = useState<iCompanyBids>();
  const tenderId = location?.pathname?.split("/")[3];
  useEffect(() => {
    if (tenderId) {
      TenderService.getTenderOfCompany(+tenderId).then((res) => {
        setBiddedCompanies(res.bids);
        setCompaniesToUpdate(res);
      });
    }
  }, [tenderId]);
  useEffect(() => {
    TenderService.getTenderCompaniesDropdown()
      .then((res) => {
        if (res) {
          setCompanies(
            res.company?.filter((company: any) => company?.title?.length > 0)
          );
        }
      })
      .catch((error) => ErrorToast(error));

    TenderService.getTenderUserFunctions()
      .then((res) => {
        res && setUserFunctions(res);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  useEffect(() => {
    if (selectedCompaniesToBid?.bids?.length) {
      let tempArr: iCompanies[] = [...companies];
      selectedCompaniesToBid.bids?.forEach((storedCompany) => {
        tempArr = tempArr.filter(
          (company) => company?.id !== storedCompany?.companyId
        );
      });
      setCompanies(tempArr);
    }
  }, [JSON.stringify(selectedCompaniesToBid)]);

  useEffect(() => {
    EnumService.getEnums()
      .then((res) => {
        if (isSingle) {
          setBidderTypes(
            res?.bidderType?.filter((reason) => reason !== "co_bidder")
          );
        } else {
          setBidderTypes(res?.bidderType);
        }
      })
      .catch((error) => ErrorToast(error));
  }, [isSingle]);

  const sendData = () => {
    let counter = 0;
    selectedCompaniesToBid?.bids?.forEach((item) => {
      if (item?.bidderType === "active_bidder") {
        counter++;
      }
    });
    if (counter === 0 || counter > 1)
      return WarningToast(
        `You need to have ${
          counter === 0 ? "only" : "maximum"
        } one Active bidder!`
      );
    TenderService.createTenderCompanyBid(selectedCompaniesToBid!, +tenderId)
      .then((res) => {
        if (res) {
          SuccessToast(
            `${t("Tenders.companies.toasts.success_created_bidds")}`
          );
          navigate(`/tender/checklist/${tenderId}`);
        }
      })
      .catch((error) => ErrorToast(error));
  };

  const clearLastCompany = () => {
    selectedCompaniesToBid?.bids.pop();
    if (numberOfCompanies > 1) {
      setNumberOfCompanies((prev) => prev - 1);
    }
  };

  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          <div className="card-body checkout-tab">
            <TenderTabs />
            {biddedCompanies?.length === 0 ? (
              <>
                <div className="row">
                  <TenderTypeSwitcher
                    isSingle={isSingle}
                    setIsSingle={setIsSingle}
                  />
                </div>
                <div className="row">
                  {[...Array(numberOfCompanies)].map((i, index) => (
                    <div className="col-md-4">
                      <TenderCompanyItem
                        isSingle={isSingle}
                        indexId={index}
                        userFunctions={userFunctions!}
                        bidderTypes={bidderTypes!}
                        setSelectedCompaniesToBid={setSelectedCompaniesToBid}
                        companiesToBid={companiesToBid}
                        setCompaniesToBid={setCompaniesToBid}
                        companies={companies}
                        key={i}
                      />
                    </div>
                  ))}
                </div>

                <div className={`row my-4 justify-content-end`}>
                  <div className="col-12 d-flex justify-content-end align-items-center">
                    {selectedCompaniesToBid?.bids[0] && (
                      <a
                        href="#"
                        className="btn btn-danger me-3"
                        onClick={clearLastCompany}
                      >
                        -{" "}
                        {numberOfCompanies > 1
                          ? `${t("Tenders.companies.remove_last_company")}`
                          : `${t("Tenders.companies.clear_company")}`}
                      </a>
                    )}

                    <a
                      href="#"
                      className="btn btn-success"
                      onClick={() => setNumberOfCompanies((prev) => prev + 1)}
                    >
                      + {t("Tenders.companies.add_company")}
                    </a>
                  </div>
                </div>
                {selectedCompaniesToBid?.bids[0]?.employees && (
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" onClick={sendData}>
                      {t("Tenders.companies.save_and_next")}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="card-body">
                <div className="table-responsive table-card">
                  <TenderCompaniesTable
                    companiesToUpdate={companiesToUpdate!}
                    setCompaniesToBid={setCompaniesToBid!}
                    companiesToBid={companiesToBid}
                    setSelectedCompaniesToBid={setSelectedCompaniesToBid}
                    companies={companies}
                    tenderId={tenderId}
                    bidderTypes={bidderTypes!}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
