import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Image } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { AuthService } from "../service";
import {
  ErrorToast,
  SuccessToast,
  WarningToast,
} from "../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";

export interface iForgotPassword {
  email: string;
  repeat_password?: string;
  password?: string;
  code?: string;
}

export function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [visibilityPassword, setVisibility] = useState(false);
  const [forgotData, setForgotData] = useState<iForgotPassword>({
    email: "",
  });
  const [searchParams] = useSearchParams();

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForgotData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const sendEmailForReset = () => {
    if (queryParams?.code) {
      if (forgotData?.repeat_password !== forgotData?.password)
        return WarningToast(
          `${t("forgot_password.toasts.enter_two_same_passwords")}!`
        );
      AuthService.forgotPasswordChange({
        ...forgotData,
        email: queryParams?.email,
        code: queryParams?.code,
      })
        .then((res) => {
          SuccessToast(
            `${t("forgot_password.toasts.successfully_changed_password")}!`
          );
          navigate("/");
        })
        .catch((error) => ErrorToast(error));
    } else {
      AuthService.forgotPasswordRequest(forgotData as iForgotPassword)
        .then((res) => {
          SuccessToast(`${t("forgot_password.toasts.check_email")}!`);
          navigate("/");
        })
        .catch((error) => ErrorToast(error));
    }
  };
  const [queryParams, setQueryParams] = useState({
    code: "",
    email: "",
  });

  useEffect(() => {
    setQueryParams({
      code: searchParams.get("code") as string,
      email: searchParams.get("email") as string,
    });
  }, []);

  return (
    <div className="auth-page-wrapper pt-5">
      <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div className="bg-overlay" />

        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1440 120"
          >
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
          </svg>
        </div>
        <canvas
          className="particles-js-canvas-el w-100 h-100"
          width="1903"
          height="380"
        />
      </div>

      <div className="auth-page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <Image
                      src="/images/logo/bbi_logo_white.png"
                      alt=""
                      height="70"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card mt-4">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">
                      {" "}
                      {queryParams?.code
                        ? `${t("forgot_password.restart_password")}`
                        : `${t("forgot_password.forgot_password")}?`}{" "}
                    </h5>
                  </div>

                  <div
                    className="alert alert-borderless alert-warning text-center mb-2 mx-2"
                    role="alert"
                  >
                    {queryParams?.code
                      ? `${t(
                          "forgot_password.enter_new_password_and_click_send"
                        )}`
                      : `${t("forgot_password.enter_your_email")}`}
                  </div>
                  <div className="p-2">
                    {queryParams?.code && (
                      <div className="mb-4">
                        <label className="form-label">
                          {t("forgot_password.enter_password")}
                        </label>
                        <div className="d-flex align-items-center">
                          <input
                            type={`${visibilityPassword ? "text" : "password"}`}
                            className="form-control"
                            id="password"
                            name="repeat_password"
                            onChange={changeHandler}
                            placeholder={t("forgot_password.enter_password")}
                          />
                          {visibilityPassword ? (
                            <i
                              className="ri-eye-fill align-middle cursor-pointer text-muted"
                              onClick={() => setVisibility(!visibilityPassword)}
                              style={{ marginLeft: "-1.5rem" }}
                            />
                          ) : (
                            <i
                              className="ri-eye-off-fill cursor-pointer text-muted"
                              onClick={() => setVisibility(!visibilityPassword)}
                              style={{ marginLeft: "-1.5rem" }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    <div className="mb-4">
                      <label className="form-label">
                        {queryParams?.code
                          ? `${t("forgot_password.repeat_password")}`
                          : `${t("forgot_password.email")}`}
                      </label>
                      <input
                        className="form-control"
                        id="email"
                        type={queryParams?.code ? "password" : "email"}
                        name={queryParams?.code ? "password" : "email"}
                        onChange={changeHandler}
                        onKeyDown={(event) =>
                          event.key === "Enter" && sendEmailForReset()
                        }
                        placeholder={`${t("forgot_password.enter")} ${
                          queryParams?.code
                            ? `${t("forgot_password.password")}`
                            : `${t("forgot_password.email")}`
                        }`}
                      />
                    </div>

                    <div className="text-center mt-4">
                      <button
                        className="btn btn-success w-100"
                        onClick={sendEmailForReset}
                      >
                        {queryParams?.code
                          ? `${t("forgot_password.update_password")}`
                          : `${t("forgot_password.send_link")}`}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {!queryParams?.code && (
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    {`${t("forgot_password.i_remember_my_password")}`}...{" "}
                    <Link
                      to="/"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {`${t("forgot_password.click_here")}`}
                    </Link>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
