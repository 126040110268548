import React from "react";

export const MailNotifications = ({
  t,
  profile,
  updateProfile,
  setChangeProfile,
}: any) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangeProfile((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
    console.log(event.target.checked);
  };

  return (
    <>
      <div>
        <label className="form-label" htmlFor="project-title-input">
          {t("profile.mail_notifications")}
        </label>
        <div className="form-check form-switch form-switch-lg" dir="ltr">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizelg"
            key={profile?.id}
            defaultChecked={profile?.isMailEnabled}
            name="isMailEnabled"
            onChange={handleChange}
          />
        </div>
      </div>
      <button onClick={updateProfile} className="btn btn-primary" type="submit">
        {t("modals.update")}
      </button>
    </>
  );
};
