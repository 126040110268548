import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { iPersonel } from '../../../model/statistics/Statistics';
import { PersonelTitles } from '../../../shared/enums/Enums';
import { ErrorToast } from '../../../shared/toasters/toasters';
import { EnumService } from '../../../store/enums/enumService';
import { PersonalService } from '../service';

import { SubmittedPercent } from './SubmittedPercent';
import { CrudService } from '../../cruds/service';
import { UserService } from '../../users/service';
import { User } from '../../../model/user/User';
import { TenderService } from '../../tender/service';
import { IrrelevanceDistribution } from './IrrelevanceDistribution';
import { WonDistributionByFunction } from './WonDistributionByFunction';
import { WonRevenueByFunction } from './WonRevenueByFunction';
import { CancellationDistribution } from './CancellationDistribution';

interface iPersonalQuery {
  key: string;
  fromDate: string | null;
  toDate: string | null;
  user: number | null;
  userFunction: number | null;
  cancellationReason: number | null;
  irrelevanceReason: number | null;
}

export const PersonnelContent = () => {
  const { t } = useTranslation();
  const [personalData, setPersonalData] = useState<iPersonel>();
  const [personalKeys, setPersonalKeys] = useState<string[]>([]);
  const [selectedKey, setSelectedKey] = useState<iPersonalQuery>();
  const [listOfAllUsers, setListOfAllUsers] = useState<User[]>([]);
  const [irrelevanceReasons, setIrrelevanceReasons] = useState([]);
  const [userFunctions, setUserFunctions] = useState([]);
  const [cancellationList, setCancellationList] = useState([]);

  const changeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.name === 'key') {
      setSelectedKey((prev) => ({
        ...prev,
        key: event.target.value,
        fromDate: null,
        toDate: null,
        user: null,
        userFunction: null,
        cancellationReason: null,
        irrelevanceReason: null,
      }));
    } else {
      setSelectedKey((selectedKey: any) => ({
        ...selectedKey,
        [event.target.name]:
          event.target.value === 'null' ? null : event.target.value,
      }));
    }
  };

  useEffect(() => {
    selectedKey?.key &&
      PersonalService.getPersonals(selectedKey)
        .then((res) => {
          setPersonalData(res);
        })
        .catch((error) => ErrorToast(error));
  }, [JSON.stringify(selectedKey)]);

  useEffect(() => {
    UserService.getUsers({ page: 1, perPage: 0, email: '', name: '' })
      .then((res) => {
        setListOfAllUsers(res.data);
      })
      .catch((error) => ErrorToast(error));

    TenderService.getTenderDetails()
      .then((res) => {
        setIrrelevanceReasons(res?.irrelevanceReason);
      })
      .catch((error) => ErrorToast(error));

    TenderService.getTenderUserFunctions()
      .then((res) => {
        setUserFunctions(res);
      })
      .catch((error) => ErrorToast(error));

    CrudService.getCancellations({ perPage: 0 })
      .then((res) => {
        setCancellationList(res?.data);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  useEffect(() => {
    EnumService.getEnums()
      .then((res) => {
        setPersonalKeys(res?.personal);
        setSelectedKey((prev: any) => ({ ...prev, key: res?.personal[0] }));
      })
      .catch((error) => ErrorToast(error));
  }, []);

  return (
    <div className='card'>
      <div className='card-body '>
        <div className='d-flex justify-content-end mt-3 '>
          <div className='w-25'>
            <label htmlFor='irrelevanceReason'>
              {t('personel.selectPersonel')}
            </label>
            <select
              name='key'
              onChange={changeHandler}
              className='form-select test'
            >
              <option hidden> {t('personel.percentage_of_submitted')} </option>
              {personalKeys?.map((item) => {
                return (
                  <option key={item} value={item}>
                    {t(
                      PersonelTitles[
                        item as keyof typeof PersonelTitles
                      ] as string
                    )}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        {selectedKey?.key === 'tender_submitted_percent' && personalData && (
          <SubmittedPercent
            data={personalData}
            setSelectedKey={setSelectedKey}
            t={t}
            changeHandler={changeHandler}
            listOfAllUsers={listOfAllUsers}
            selectedKey={selectedKey}
            userFunctions={userFunctions}
            cancellationList={cancellationList}
            irrelevanceReasons={irrelevanceReasons}
          />
        )}

        {selectedKey?.key === 'tender_irrelevance_distribution' && (
          <IrrelevanceDistribution
            data={personalData}
            setSelectedKey={setSelectedKey}
            t={t}
            changeHandler={changeHandler}
            listOfAllUsers={listOfAllUsers}
            selectedKey={selectedKey}
            userFunctions={userFunctions}
            cancellationList={cancellationList}
            irrelevanceReasons={irrelevanceReasons}
          />
        )}

        {selectedKey?.key === 'tender_won_distribution_by_function' && (
          <WonDistributionByFunction
            data={personalData}
            setSelectedKey={setSelectedKey}
            t={t}
            changeHandler={changeHandler}
            listOfAllUsers={listOfAllUsers}
            selectedKey={selectedKey}
            userFunctions={userFunctions}
            cancellationList={cancellationList}
            irrelevanceReasons={irrelevanceReasons}
          />
        )}

        {selectedKey?.key === 'tender_won_revenue_by_function' && (
          <WonRevenueByFunction
            data={personalData}
            setSelectedKey={setSelectedKey}
            t={t}
            changeHandler={changeHandler}
            listOfAllUsers={listOfAllUsers}
            selectedKey={selectedKey}
            userFunctions={userFunctions}
            cancellationList={cancellationList}
            irrelevanceReasons={irrelevanceReasons}
          />
        )}

        {selectedKey?.key === 'tender_cancellation_distribution' && (
          <CancellationDistribution
            data={personalData}
            setSelectedKey={setSelectedKey}
            t={t}
            changeHandler={changeHandler}
            listOfAllUsers={listOfAllUsers}
            selectedKey={selectedKey}
            userFunctions={userFunctions}
            cancellationList={cancellationList}
            irrelevanceReasons={irrelevanceReasons}
          />
        )}
      </div>
    </div>
  );
};
