import { currentLanguage } from '../../tender/form/details/TenderDetails';
import { German } from 'flatpickr/dist/l10n/de';
import { english as English } from 'flatpickr/dist/l10n/default';
import Flatpickr from 'react-flatpickr';

export const PersonelFiltering = ({
  setSelectedKey,
  t,
  changeHandler,
  listOfAllUsers,
  selectedKey,
  userFunctions,
  irrelevanceReasons,
  cancellationList,
}: any) => {
  function handleDatePicker(evtName: string, evtValue: string) {
    const value = new Date(evtValue);
    value.setHours(12);
    setSelectedKey((prev: any) => ({ ...prev, [evtName]: value }));
  }
  return (
    <div className='row my-4'>
      <div className='col-lg-2'>
        <label htmlFor='fromDate'>{t('personel.startDate')}</label>
        <Flatpickr
          className='form-control'
          name='fromDate'
          autocomplete='off'
          options={{
            disableMobile: true,
            dateFormat: 'd.m.Y',
            allowInput: true,
            locale: currentLanguage !== 'en' ? German : English,
          }}
          onChange={(e: any) => {
            handleDatePicker('fromDate', `${e[0]}`);
          }}
        />
      </div>
      <div className='col-lg-2'>
        <label htmlFor='fromDate'>{t('personel.endDate')}</label>
        <Flatpickr
          className='form-control'
          name='toDate'
          autocomplete='off'
          options={{
            disableMobile: true,
            dateFormat: 'd.m.Y',
            allowInput: true,
            locale: currentLanguage !== 'en' ? German : English,
          }}
          onChange={(e: any) => {
            handleDatePicker('toDate', `${e[0]}`);
          }}
        />
      </div>
      <div className='col-lg-3'>
        <label htmlFor='user'>{t('personel.user')}</label>
        <select name='user' className='form-select' onChange={changeHandler}>
          <option value='null'>{t('personel.showAll')}</option>
          {listOfAllUsers?.map(
            (user: { id: number; firstName: string; lastName: string }) => {
              return (
                <option value={user?.id} key={user?.id}>
                  {user?.firstName} {user?.lastName}
                </option>
              );
            }
          )}
        </select>
      </div>
      {(selectedKey?.key === 'tender_won_distribution_by_function' ||
        selectedKey?.key === 'tender_won_revenue_by_function') && (
        <div className='col-lg-3'>
          <label htmlFor='userFunction'>
            {t('Tenders.companies.fields.select_function')}
          </label>
          <select
            name='userFunction'
            className='form-select'
            onChange={changeHandler}
          >
            <option value='null'>{t('personel.showAll')}</option>
            {userFunctions?.map((func: { id: number; title: string }) => {
              return (
                <option value={func?.id} key={func?.id}>
                  {func?.title}
                </option>
              );
            })}
          </select>
        </div>
      )}
      {selectedKey?.key === 'tender_cancellation_distribution' && (
        <div className='col-lg-4'>
          <label htmlFor='cancellationReason'>
            {t('Tenders.Status.cancellation_reason')}
          </label>
          <select
            name='cancellationReason'
            className='form-select'
            onChange={changeHandler}
          >
            <option value='null'>{t('personel.showAll')}</option>
            {cancellationList?.map(
              (cancellation: { id: number; reason: string }) => {
                return (
                  <option value={cancellation?.id} key={cancellation?.id}>
                    {cancellation?.reason}
                  </option>
                );
              }
            )}
          </select>
        </div>
      )}
      {selectedKey?.key === 'tender_irrelevance_distribution' && (
        <div className='col-lg-3'>
          <label htmlFor='irrelevanceReason'>{t('side_bar.irrelevance')}</label>
          <select
            name='irrelevanceReason'
            className='form-select'
            onChange={changeHandler}
          >
            <option value='null'>{t('personel.showAll')}</option>
            {irrelevanceReasons?.map(
              (reason: { id: number; reason: string }) => {
                return (
                  <option value={reason?.id} key={reason?.id}>
                    {reason?.reason}
                  </option>
                );
              }
            )}
          </select>
        </div>
      )}
    </div>
  );
};
