import React from 'react';
import { PersonelFiltering } from './PersonelFiltering';

export const CancellationDistribution = ({
  data,
  setSelectedKey,
  t,
  changeHandler,
  listOfAllUsers,
  selectedKey,
  userFunctions,
  irrelevanceReasons,
  cancellationList,
}: any) => {
  console.log(data);
  return (
    <>
      <PersonelFiltering
        setSelectedKey={setSelectedKey}
        t={t}
        changeHandler={changeHandler}
        listOfAllUsers={listOfAllUsers}
        selectedKey={selectedKey}
        userFunctions={userFunctions}
        cancellationList={cancellationList}
        irrelevanceReasons={irrelevanceReasons}
      />

      {data?.data?.map((revenue: any) => {
        return (
          <div key={revenue?.id} className='mt-5'>
            <h3 className='fw bold  mb-3 '>
              {`${revenue?.firstName} ${revenue?.lastName} `}
              <span className='fs-6'>{`- ( ${revenue?.email} )`}</span>
            </h3>
            {/* <div className="row"> */}
            {revenue?.data?.map(
              (reason: {
                id: number;
                functionTitle: string;
                totalCanceled: number;
                percentCanceled: number;
                total: number;
                cancellationData: any;
              }) => {
                return (
                  <div className={`mt-3 mb-5`} key={reason?.id}>
                    <div className='table-responsive table-card'>
                      <table
                        className='table align-middle table-nowrap'
                        id='invoiceTable'
                      >
                        <thead className='text-muted'>
                          <tr id='personel-row'>
                            <th className='col'>
                              {t('personel.functionTitle')}
                            </th>
                            <th className='col'>
                              {t('personel.percentCanceled')}
                            </th>
                            <th className='col'>
                              {t('personel.totalCanceled')}
                            </th>
                            <th className='col'>{t('personel.total')}</th>
                          </tr>
                        </thead>
                        <tbody
                          className='list form-check-all'
                          id='invoice-list-data'
                        >
                          <tr id='personel-row'>
                            <td className='col-4'>{reason?.functionTitle}</td>
                            <td className='col-4'>{reason?.percentCanceled}</td>
                            <td className='col'>{reason?.totalCanceled}</td>
                            <td className='col'>{reason?.total}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {!!reason?.cancellationData?.length && (
                      <div className='table-responsive table-card mt-3 ms-3'>
                        <table
                          className='table align-middle table-nowrap'
                          id='invoiceTable'
                        >
                          <React.Fragment>
                            <thead className='text-muted'>
                              <tr id='personel-row'>
                                <th className='col-4'>
                                  {t('personel.cancelellationTitle')}
                                </th>
                                <th className='col-4'>
                                  {t('personel.percent')}
                                </th>
                                <th className='col'>{t('personel.total')}</th>
                              </tr>
                            </thead>

                            <tbody
                              className='list form-check-all'
                              id='invoice-list-data'
                            >
                              {reason?.cancellationData?.map(
                                (cancellation: any) => {
                                  return (
                                    <tr
                                      id='personel-row'
                                      key={cancellation?.cancellationTitle}
                                    >
                                      <td className='col-4'>
                                        {cancellation?.cancellationTitle}
                                      </td>
                                      <td className='col-4'>
                                        {cancellation?.percent}
                                      </td>
                                      <td className='col'>
                                        {cancellation?.total}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </React.Fragment>
                        </table>
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </div>
        );
      })}
    </>
  );
};
