import React from "react";
import {useTranslation} from "react-i18next";

interface iProps {
    modalId: string
    title: string,
    deleteItem: () => void
}

export const ConfirmDeleteModal = ({modalId, title, deleteItem}: iProps) => {
    const {t} = useTranslation();
    return (
        <div className="modal fade zoomIn" id={modalId} aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-0">
                    <div className="modal-header p-3 bg-soft-info">
                        <h5 className="modal-title"
                            id="exampleModalLabel">{t(`modals.are_you_sure_you_want_to_delete`)} {title}?</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                id="close-modal"/>
                    </div>
                    <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-center mt-3 w-100">
                            <button type="button" className="btn btn-light" id="close-modal"
                                    data-bs-dismiss="modal">
                                {t(`modals.close`)}
                            </button>
                            <button type="button" className="btn btn-success"
                                    onClick={deleteItem}
                                    id="close-modal" data-bs-dismiss="modal">
                                {t(`modals.delete`)}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}