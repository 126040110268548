import { requests } from '../utils/helpers/api.services';
import { ILogin } from './login/Login';
import {
  LOGIN_URL,
  REGISTER_URL,
  RESET_PASSWORD_URL,
  FIRST_TIME_SETUP,
} from '../utils/helpers/api.routes';
import { iRegister } from './register/Signup';
import { iForgotPassword } from './login/ForgotPassword';

export const AuthService = {
  login: (body: ILogin) => requests.post(LOGIN_URL, body),
  firstTimeSetup: (body: any) => requests.post(FIRST_TIME_SETUP, body),
  register: (body: iRegister) => requests.post(REGISTER_URL, body),
  forgotPasswordRequest: (body: iForgotPassword) =>
    requests.post(`${RESET_PASSWORD_URL}-request`, body),
  forgotPasswordChange: (body: iForgotPassword) =>
    requests.post(`${RESET_PASSWORD_URL}-change`, body),
};
