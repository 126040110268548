import { useTranslation } from "react-i18next";
import { PageTemplate } from "../../shared/layout/PageTemplate";
import { PersonnelContent } from "./components/PersonnelContent";

export function Personnel() {
  const { t } = useTranslation();
  return (
    <PageTemplate
      pageName={`${t("side_bar.personnel")}`}
      title={`${t("side_bar.personnel")}`}
      content={<PersonnelContent />}
    />
  );
}
