import {useTenderCrud} from "../../../../shared/hooks/useTenderCrud";
import {CrudService} from "../../service";
import {CrudCreate} from "../../../../shared/crud/CrudCreate";
import {CrudTable} from "../../../../shared/crud/CrudTable";
import {Pagination} from "../../../../shared/paginations/Paginations";
import {CrudUpdate} from "../../../../shared/crud/CrudUpdate";
import {CrudDelete} from "../../../../shared/crud/CrudDelete";

export const FunctionsList = () => {
    const {
        page,
        handlePages,
        totalPages,
        listOfAllData,
        updateData,
        removeData,
        createData,
        selectedModalData,
        getCurrentDataID,
        changeHandler,
        showForm,
        setShowForm,
        filterData,
        selectedData
    } = useTenderCrud({
        getData: CrudService.getFunction,
        getMany: CrudService.getFunctions,
        deleteData: CrudService.deleteFunction,
        putData: CrudService.putFunction,
        addData: CrudService.addFunction
    });

    const tableHead = ['Title', 'Created at', 'Updated at', 'Actions']

    return (
        <div className="col-12">
            <div className="row">
                <div className="col-12">
                    <CrudCreate showForm={showForm} setShowForm={setShowForm} createData={createData} mainField="title"
                                title="Functions"
                                changeHandler={changeHandler}/>
                </div>
            </div>
            <div className="row">
                <CrudTable filterData={filterData} getCurrentDataID={getCurrentDataID} title="Functions"
                           isDeleteCrud={true}
                           tableHead={tableHead} mainField="title"
                           listOfAllData={listOfAllData}/>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-end">
                {totalPages <= 1 ? '' :
                    <Pagination
                        page={page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                }
            </div>
            <CrudUpdate updateData={updateData} changeHandler={changeHandler} mainField="title" title="Functions"
                        selectedData={selectedData!}/>

            <CrudDelete removeData={removeData} selectedData={selectedData} mainField="title"
                        selectedModalData={selectedModalData}/>
        </div>
    )
}