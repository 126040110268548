import { PersonelFiltering } from './PersonelFiltering';

export const IrrelevanceDistribution = ({
  data,
  setSelectedKey,
  t,
  changeHandler,
  listOfAllUsers,
  selectedKey,
  userFunctions,
  irrelevanceReasons,
  cancellationList,
}: any) => {
  return (
    <>
      <PersonelFiltering
        setSelectedKey={setSelectedKey}
        t={t}
        changeHandler={changeHandler}
        listOfAllUsers={listOfAllUsers}
        selectedKey={selectedKey}
        userFunctions={userFunctions}
        cancellationList={cancellationList}
        irrelevanceReasons={irrelevanceReasons}
      />

      {data?.data?.map((distribution: any, i: number) => {
        console.log(distribution);

        return (
          <div key={i} className={`${i > 0 && 'mt-4'}`}>
            <h3 className='fw bold mb-3 '>
              {`${distribution?.firstName} ${distribution?.lastName} `}
              <span className='fs-6'>{`- ( ${distribution?.email} )`}</span>
            </h3>

            <div className='table-responsive table-card'>
              <table
                className='table align-middle table-nowrap'
                id='invoiceTable'
              >
                <thead className='text-muted'>
                  <tr id='personel-row'>
                    <th className='col-6'>{t('personel.reason')}</th>
                    <th className='col-6'>{t('personel.total')}</th>
                    <th className='col-6'>{t('personel.percent')}</th>
                  </tr>
                </thead>
                <tbody className='list form-check-all' id='invoice-list-data'>
                  {distribution?.reason?.map(
                    (reason: {
                      id: number;
                      reason: string;
                      total: number;
                      percent: number;
                    }) => {
                      return (
                        <tr id='personel-row' key={reason?.id}>
                          <td className='col-6'>{reason?.reason}</td>
                          <td className='col-6'>{reason?.total}</td>
                          <td className='col-6'>{reason?.percent} %</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
    </>
  );
};
