import { formatDate } from "../functions/Functions";
import { iTable } from "../../model/shared/ICrud";
import { useTranslation } from "react-i18next";
import { CrudTitle, TableTitle } from "../enums/Enums";

export const CrudTable = ({
  listOfAllData,
  getCurrentDataID,
  filterData,
  title,
  tableHead,
  mainField,
  isDeleteCrud = false,
}: iTable) => {
  const { t } = useTranslation();

  return (
    <div className="col-xl-12">
      <div className="card">
        <div className="card-header pb-0">
          <div className="d-flex justify-content-between">
            <h4 className="card-title mg-b-0">
              {`${t("cruds.list_of")}`}{" "}
              {t(
                CrudTitle[
                  title?.toLocaleLowerCase() as keyof typeof CrudTitle
                ] as string
              )}
            </h4>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-end position-relative mb-4">
            <input
              className="form-control w-25"
              name={mainField}
              onChange={filterData}
              placeholder={`${t("cruds.search")}...`}
              type="text"
              maxLength={30}
            />
            <i
              className="fas fa-search position-absolute"
              style={{ right: "20px" }}
            />
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-hover mb-0 text-md-nowrap">
              <thead>
                <tr>
                  <th>ID</th>
                  {tableHead?.map((data) => (
                    <th key={data}>
                      {" "}
                      {t(TableTitle[data as keyof typeof TableTitle] as string)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {listOfAllData!?.length > 0 ? (
                  listOfAllData?.map((data: any) => {
                    return (
                      <tr key={data.id}>
                        <th scope="row">{data.id}</th>
                        <td>{data[mainField] ? data[mainField] : "-"}</td>
                        <td>{formatDate(data.createdAt)}</td>
                        <td>{formatDate(data.updatedAt)}</td>
                        <td className="d-flex justify-content-center">
                          {isDeleteCrud && (
                            <button
                              className="btn btn-danger ripple me-1"
                              onClick={() => getCurrentDataID!(data.id)}
                              data-bs-target="#deleteModal"
                              data-bs-toggle="modal"
                            >
                              {t("modals.delete")}
                            </button>
                          )}
                          <button
                            className="btn btn-primary ripple"
                            onClick={() => getCurrentDataID!(data.id)}
                            data-bs-target="#modaldemo1"
                            data-bs-toggle="modal"
                          >
                            {t("modals.change")}
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
