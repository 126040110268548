import React from "react";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";

interface iEarningCreateProps {
  createEarning: () => void;
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  modalId: string;
  modalTitle: string;
  earningCreate?: {
    id: number;
    year: number;
    revenue: number;
    month: number;
  };
}

export const EarningModal = ({
  createEarning,
  changeHandler,
  modalId,
  modalTitle,
  earningCreate,
}: iEarningCreateProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="modal fade zoomIn"
        id={modalId}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content border-0">
            <div className="modal-header p-3 bg-soft-info">
              <h5 className="modal-title" id="exampleModalLabel">
                {modalTitle} {modalId === "earningCreate" ? "" : "-"}{" "}
                {earningCreate?.id}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-modal"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6 mt-2">
                  <label htmlFor="year">
                    {t("earnings.month")} & {t("earnings.year")}:
                  </label>
                  <input
                    type="month"
                    name="month_year"
                    placeholder={t("earnings.month")}
                    defaultValue={`${earningCreate?.year}-${
                      earningCreate!?.month < 10 ? "0" : ""
                    }${earningCreate?.month}`}
                    key={earningCreate?.id}
                    onChange={changeHandler}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mt-2">
                  <label htmlFor="year">{t("earnings.revenue")}:</label>
                  <input
                    type="number"
                    name="revenue"
                    placeholder={t("earnings.revenue")}
                    defaultValue={earningCreate?.revenue}
                    key={earningCreate?.id}
                    onChange={changeHandler}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="hstack gap-2 justify-content-end mt-3 w-100">
                <button
                  type="button"
                  className="btn btn-light"
                  id="close-modal"
                  data-bs-dismiss="modal"
                >
                  {t(`modals.close`)}
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={createEarning}
                  id="close-modal"
                  data-bs-dismiss="modal"
                >
                  {modalTitle === `${t("earnings.earning_create")}`
                    ? `${t(`modals.create`)}`
                    : `${t(`modals.change`)}`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
