import { toast } from 'react-toastify';
import { useTranslation as t } from 'react-i18next';

const customId = 'Network error';

export function ErrorToast(e: any) {
  let message = e.response?.data?.message
    ? e.response.data.message
    : `${t('errors.something_went_wrong')}`;

  if (Array.isArray(message)) {
    message = e.response?.data?.message[0];
  }

  toast.error(message, {
    toastId: customId,
  });
}

export function SuccessToast(props: string) {
  toast.success(props);
}

export function WarningToast(props: string) {
  toast.warning(props);
}
