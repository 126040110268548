import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { User } from '../../../model/user/User';
import { ErrorToast, SuccessToast } from '../../../shared/toasters/toasters';
import { Button, Form } from 'react-bootstrap';
import { multipartFormData } from '../../../utils/helpers/api.services';
import { BASE_URL, USER_URL } from '../../../utils/helpers/api.routes';
import { useSelector } from 'react-redux';
import { IAllStates } from '../../../model/shared/IAllStates';
import { UserService } from '../service';
import { initialCreateUser, initialUser } from '../dto/initialUser';
import { trimRoles } from './UserItem';
import { useTranslation } from 'react-i18next';

export function UserForm() {
  const { state }: any = useLocation();
  const id = state?.user?.id;
  const isEdit = state?.isEdit;
  const [user, setUser] = useState<User>(
    isEdit ? initialUser : initialCreateUser
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImage] = useState('');
  const [checked, setChecked] = useState<string[]>([]);
  const [visibilityPassword, setVisibility] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { userCompanyRoles } = useSelector((state: IAllStates) => state.enums);

  function editUser(event: any) {
    event.preventDefault();
    multipartFormData(
      selectedFile,
      `${USER_URL}/${id}`,
      JSON.stringify({
        ...user,
        roles: checked,
      })
    )
      .then((response) => {
        if (response) {
          SuccessToast(`${t('Users.toasts.successfully_updated')}!`);
          navigate(-1);
        }
      })
      .catch((err) => ErrorToast(err));
  }

  function createUser(event: any) {
    event.preventDefault();
    multipartFormData(
      selectedFile,
      USER_URL,
      JSON.stringify({
        ...user,
        roles: checked,
      }),
      'post'
    )
      .then((response) => {
        if (response) {
          SuccessToast(`${t('Users.toasts.successfully_created_user')}!`);
          navigate(-1);
        }
      })
      .catch((err) => ErrorToast(err));
  }

  useEffect(() => {
    if (isEdit) {
      UserService.getUser(id).then((data) => {
        setChecked(data.roles);
        setUser(data);
      });
    }
  }, []);

  function handleChange(
    evt: React.ChangeEvent<HTMLInputElement>,
    isNumber: boolean = false
  ) {
    const value = isNumber ? +evt.target.value : evt.target.value;
    setUser({
      ...user,
      [evt.target.name]: value,
    });
  }

  function handleCompanyChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUser((prev) => ({
      ...prev,
      contact: {
        ...prev.contact,
        [event.target.name]: event.target.value,
      },
    }));
  }

  const handleCheck = (event: any) => {
    let updatedList: any = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      //@ts-ignore
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  function handleImageInput(evt: any) {
    if (evt.target.files.length > 0) {
      setSelectedFile(evt.target.files[0]);
      setUser({
        ...user,
        profilePicture: {
          ...user.profilePicture,
          ['path']: URL.createObjectURL(evt.target.files[0]),
        },
      });
      setImage(URL.createObjectURL(evt.target.files[0]));
    }
  }

  return (
    <div className="col-12">
      <div className="row mx-0 w-100">
        <div className="col-lg-3">
          <div className="card">
            <div className="card-body p-4">
              <div className="text-center">
                <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                  {user?.profilePicture?.path || imagePath ? (
                    <img
                      src={
                        imagePath
                          ? imagePath
                          : `${BASE_URL}${user?.profilePicture?.path}`
                      }
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      alt="user-profile-image"
                    />
                  ) : (
                    <img
                      src="/assets/images/img-placeholder.png"
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      alt="user-profile-image"
                    />
                  )}
                  <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                    <input
                      id="profile-img-file-input"
                      type="file"
                      accept="image/*"
                      className="profile-img-file-input"
                      onChange={handleImageInput}
                    />
                    <label
                      htmlFor="profile-img-file-input"
                      className="profile-photo-edit avatar-xs"
                    >
                      <span className="avatar-title rounded-circle bg-light text-body">
                        <i className="ri-camera-fill" />
                      </span>
                    </label>
                  </div>
                </div>
                {isEdit && (
                  <div>
                    <h5 className="fs-16 mb-1">
                      {user?.firstName} {user?.lastName}
                    </h5>
                    <p className="text-muted mb-0">{user?.email}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-9">
          <div className="card">
            <div className="card-body p-4">
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <form>
                      <label htmlFor="firstnameInput" className="form-label">
                        <span className="text-danger">*</span>
                        {t('Users.form.name')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstnameInput"
                        placeholder={t('Users.form.name')}
                        defaultValue={user?.firstName}
                        name="firstName"
                        onChange={handleChange}
                        autoComplete="false"
                      />
                    </form>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <form>
                      <label htmlFor="lastnameInput" className="form-label">
                        <span className="text-danger">*</span>
                        {t('Users.form.last_name')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastnameInput"
                        placeholder={t('Users.form.last_name')}
                        defaultValue={user?.lastName}
                        name="lastName"
                        onChange={handleChange}
                        autoComplete="false"
                      />
                    </form>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <form>
                      <label htmlFor="phonenumberInput" className="form-label">
                        {t('Users.form.phone')}
                      </label>
                      <input
                        name="phone"
                        type="tel"
                        className="form-control"
                        id="phonenumberInput"
                        placeholder={t('Users.form.phone')}
                        defaultValue={user?.contact?.phone}
                        onChange={handleCompanyChange}
                        autoComplete="false"
                      />
                    </form>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <form>
                      <label htmlFor="emailInput" className="form-label">
                        <span className="text-danger">*</span>
                        {t('Users.form.email')}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="emailInput"
                        placeholder={t('Users.form.email')}
                        name="email"
                        onChange={handleChange}
                        defaultValue={user?.email}
                        autoComplete="false"
                      />
                    </form>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <form>
                      <label htmlFor="countryInput" className="form-label">
                        {t('Users.form.country')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="countryInput"
                        placeholder={t('Users.form.country')}
                        name="country"
                        onChange={handleCompanyChange}
                        defaultValue={user?.contact?.country}
                        autoComplete="false"
                      />
                    </form>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <form>
                      <label className="form-label">
                        {t('Users.form.city')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="cityInput"
                        placeholder={t('Users.form.city')}
                        name="city"
                        onChange={handleCompanyChange}
                        defaultValue={user?.contact?.city}
                        autoComplete="false"
                      />
                    </form>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <form>
                      <label htmlFor="emailInput" className="form-label">
                        {t('Users.form.zip')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t('Users.form.zip')}
                        name="zip"
                        onChange={handleCompanyChange}
                        defaultValue={user?.contact?.zip}
                        autoComplete="false"
                      />
                    </form>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <form>
                      <label htmlFor="emailInput" className="form-label">
                        {t('Users.form.address')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t('Users.form.address')}
                        name="address"
                        onChange={handleCompanyChange}
                        autoComplete="off"
                        defaultValue={isEdit ? user?.contact?.address : ''}
                      />
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 mb-3">
                  <Form autoComplete="off">
                    <Form.Group>
                      <div className="title">
                        <Form.Label>
                          <span className="text-danger">*</span>
                          {t('Users.form.role')}
                        </Form.Label>
                        <div
                          className="list-container"
                          defaultValue={user?.roles}
                        >
                          {userCompanyRoles.map((role) => (
                            <div className="d-flex align-items-center">
                              <input
                                className="form-check-input m-0"
                                key={role}
                                value={role}
                                checked={checked?.includes(role)}
                                type="checkbox"
                                onChange={handleCheck}
                                id={role}
                                autoComplete="false"
                              />
                              <label
                                htmlFor={role}
                                className="m-0 px-3 form-check-label"
                              >
                                {trimRoles(role)}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Form.Group>
                  </Form>
                </div>
                {isEdit ? (
                  ''
                ) : (
                  <div className="col-lg-6">
                    <label className="form-label" htmlFor="password-input">
                      <span className="text-danger">*</span>
                      {t('Users.form.password')}
                    </label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <input
                        name="password"
                        onChange={handleChange}
                        type={`${visibilityPassword ? 'text' : 'password'}`}
                        className="form-control pe-5"
                        placeholder="Enter password"
                        autoComplete="off"
                      />
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                        onClick={() => setVisibility(!visibilityPassword)}
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </button>
                    </div>
                  </div>
                )}
                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    <Link to="/users">
                      <Button type="button" className="btn btn-danger">
                        {t('modals.cancel')}
                      </Button>
                    </Link>
                    <Button
                      onClick={isEdit ? editUser : createUser}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {t('modals.update')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
