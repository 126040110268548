import React, { ChangeEvent, useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { AuthService } from '../service';
import { ErrorToast, SuccessToast } from '../../shared/toasters/toasters';
import { useTranslation } from 'react-i18next';

export interface ILogin {
  email: string;
  password: string;
}

export function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [state, setState] = useState<ILogin>({
    email: '',
    password: '',
  });
  const [visibilityPassword, setVisibility] = useState(false);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value.trim(),
    });
  }

  const login = (event: any) => {
    event.preventDefault();

    AuthService.login(state)
      .then((res) => {
        console.log(res);
        localStorage.setItem('token', res.token);
        localStorage.setItem('currentUser', JSON.stringify(res.user));
        SuccessToast(`${t('login.toasts.successfully_logged_in')}`);
        if (res.user.oneTimePassword) {
          navigate('/change-password');
        } else {
          navigate('/dashboard');
        }
      })
      .catch((error) => ErrorToast(error));
  };

  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      navigate('/dashboard');
    }
  }, []);

  return (
    <div className="auth-page-wrapper pt-5">
      <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div className="bg-overlay" />

        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1440 120"
          >
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
          </svg>
        </div>
      </div>

      <div className="auth-page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <Image
                      src="/images/logo/bbi_logo_white.png"
                      alt=""
                      height="70"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card mt-4">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">{t('login.welcome_back')}!</h5>
                    <p className="text-muted">
                      {t('login.sign_in_to_continue')} BBI.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    <div className="mb-3">
                      <label htmlFor="username" className="form-label">
                        {t('login.username')}
                      </label>
                      <input
                        name="email"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder={t('login.username')}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label" htmlFor="password-input">
                        {t('login.password')}
                      </label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <input
                          name="password"
                          onChange={handleChange}
                          type={`${visibilityPassword ? 'text' : 'password'}`}
                          className="form-control pe-5"
                          onKeyDown={(event) =>
                            event.key === 'Enter' && login(event)
                          }
                          placeholder={t('login.password')}
                          id="password-input"
                        />
                        <div className="float-end mt-2 mb-3">
                          <Link to="/forgot" className="text-muted">
                            {t('login.forgot_password')}?
                          </Link>
                        </div>
                        <button
                          className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button"
                          id="password-addon"
                          onClick={() => setVisibility(!visibilityPassword)}
                        >
                          {visibilityPassword ? (
                            <i className="ri-eye-fill align-middle" />
                          ) : (
                            <i className="ri-eye-off-fill" />
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="mt-4">
                      <button className="btn btn-success w-100" onClick={login}>
                        {t('login.sign_in')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
