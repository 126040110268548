import React, { useEffect, useState } from 'react';
import { TenderFilter } from './TenderFilter';
import { TenderItem } from './TenderItem';
import { Tender } from '../../../model/tender/Tender';
import { TenderService } from '../service';
import { ErrorToast } from '../../../shared/toasters/toasters';
import { Pagination } from '../../../shared/paginations/Paginations';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export function TenderList() {
  const { status } = useParams();
  const [filterParams, setFilter] = useState({
    page: Number(localStorage.getItem('lastPage')) || 1,
    perPage: 15,
    tenderNumber: '',
    title: '',
    status: status || 'recent',
    client: '',
    supervisor: '',
    tenderListField: '',
    tenderListIsAscending: 0,
  });
  const { t } = useTranslation();
  const [tenders, setTenders] = useState<Tender[]>();
  const [title, setTitle] = useState('recent');
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    return () => localStorage.setItem('lastPage', String(filterParams.page));
  }, [filterParams.page]);

  useEffect(() => {
    TenderService.getTenders(filterParams)
      .then((response) => {
        setTenders(response.data);
        setTotalPages(Math.ceil(response.count / response.perPage));
        localStorage.removeItem('lastPage');
      })
      .catch((err) => ErrorToast(err));
  }, [JSON.stringify(filterParams), title]);

  const filterTenders = (status: string) => {
    navigate(`/tenders/${status}`);
    setTitle(status);
    setFilter({
      ...filterParams,
      status: status,
      page: 1,
    });
  };

  const filterHandler = (e: any) => {
    console.log(e.target.name);
    setFilter((prev) => ({
      ...prev,
      tenderListField: e.target.name,
      tenderListIsAscending: prev?.tenderListIsAscending === 0 ? 1 : 0,
    }));
  };

  const titleFilter = (event: any) => {
    const value = event.target.value;
    setFilter({
      ...filterParams,
      [event.target.name]: value,
      page: 1,
    });
  };

  const handlePages = (updatePage: number) => {
    setFilter({ ...filterParams, page: updatePage });
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="card" id="invoiceList">
          <TenderFilter
            status={status}
            filterTenders={filterTenders}
            title={title}
            filterParams={filterParams}
            titleFilter={titleFilter}
            setFilter={setFilter}
          />

          <div className="card-body">
            <div className="table-responsive table-card">
              <table
                className="table align-middle table-nowrap"
                id="invoiceTable"
              >
                <thead className="text-muted">
                  <tr>
                    <th className="sort text-uppercase " data-sort="invoice_id">
                      ID
                      <button
                        className="gridjs-sort gridjs-sort-neutral"
                        onClick={filterHandler}
                        name="id"
                      />
                    </th>
                    <th className="sort text-uppercase" data-sort="country">
                      {t('Tenders.number')}
                      <button
                        className="gridjs-sort gridjs-sort-neutral"
                        onClick={filterHandler}
                        name="tenderNumber"
                      />
                    </th>
                    <th
                      className="sort text-uppercase"
                      data-sort="customer_name"
                    >
                      {t('Tenders.name')}
                      <button
                        className="gridjs-sort gridjs-sort-neutral"
                        onClick={filterHandler}
                        name="name"
                      />
                    </th>
                    <th className="sort text-uppercase" data-sort="country">
                      {t('Tenders.deadline')}
                      <button
                        className="gridjs-sort gridjs-sort-neutral"
                        onClick={filterHandler}
                        name="applicationDeadline"
                      />
                    </th>
                    <th className="sort text-uppercase" data-sort="country">
                      {t('Tenders.tabs.checklist')}
                    </th>
                    <th className="sort text-uppercase" data-sort="date">
                      {t('Tenders.tabs.companies')}
                    </th>
                    <th className="sort text-uppercase" data-sort="status">
                      {t('Tenders.tabs.status')}
                    </th>
                    <th className="sort text-uppercase" data-sort="action">
                      {t('Tenders.action')}
                    </th>
                  </tr>
                </thead>
                <tbody className="list form-check-all" id="invoice-list-data">
                  {tenders?.map((tender) => (
                    <TenderItem tender={tender} showCompaniesRow={true} />
                  ))}
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-end mt-3">
              {totalPages <= 1 ? (
                ''
              ) : (
                <Pagination
                  page={filterParams.page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
