import React, { useEffect, useState } from 'react';
import { UserFilter } from './UserFilter';
import { UserItem } from './UserItem';
import { UserService } from '../service';
import { User } from '../../../model/user/User';
import { ErrorToast } from '../../../shared/toasters/toasters';
import { Pagination } from '../../../shared/paginations/Paginations';
import { useNavigate } from 'react-router-dom';
import { UserConfirmationModal } from './UserConfirmationModal';
import { useTranslation } from 'react-i18next';

export function UserList() {
  const [filterParams, setFilter] = useState({
    page: 1,
    perPage: 15,
    name: '',
    email: '',
  });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [users, setUsers] = useState<User[]>([]);
  const [updateState, setUpdateState] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [isStateChanged, setIsStateChanged] = useState(false);

  useEffect(() => {
    UserService.getUsers(filterParams)
      .then((response) => {
        setTotalPages(Math.ceil(response.count / response.perPage));
        setUsers(response.data);
      })
      .catch((err) => {
        if (err.message === 'Request failed with status code 401') {
          localStorage.clear();
          navigate('/');
        }
        ErrorToast(err);
      });
  }, [updateState, filterParams, isStateChanged]);

  const handlePages = (updatePage: number) => {
    setFilter({ ...filterParams, page: updatePage });
  };

  const [selectedUser, setSelectedUser] = useState<User>();

  return (
    <>
      <div className="col-12 px-0">
        <UserFilter state={filterParams} setState={setFilter} />
      </div>
      <div className="col-12 px-0">
        <div className="team-list row mx-0 w-100 list-view-filter">
          {users?.map((user) => (
            <UserItem
              key={user?.id}
              user={user}
              setSelectedUser={setSelectedUser}
            />
          ))}
        </div>
        <UserConfirmationModal
          selectedUser={selectedUser!}
          setIsStateChanged={setIsStateChanged}
          isStateChanged={isStateChanged}
        />
        <div className="d-flex justify-content-end mt-3">
          {totalPages <= 1 ? (
            ''
          ) : (
            <Pagination
              page={filterParams.page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      </div>
    </>
  );
}
