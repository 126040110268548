import { PersonelFiltering } from './PersonelFiltering';

export const WonRevenueByFunction = ({
  data,
  setSelectedKey,
  t,
  changeHandler,
  listOfAllUsers,
  selectedKey,
  userFunctions,
  irrelevanceReasons,
  cancellationList,
}: any) => {
  return (
    <>
      <PersonelFiltering
        setSelectedKey={setSelectedKey}
        t={t}
        changeHandler={changeHandler}
        listOfAllUsers={listOfAllUsers}
        selectedKey={selectedKey}
        userFunctions={userFunctions}
        cancellationList={cancellationList}
        irrelevanceReasons={irrelevanceReasons}
      />
      {data?.data?.map((revenue: any) => {
        return (
          <div key={revenue?.id}>
            <h3 className='fw bold  my-3 '>
              {`${revenue?.firstName} ${revenue?.lastName} `}
              <span className='fs-6'>{`- ( ${revenue?.email} )`}</span>
            </h3>
            <div className='table-responsive table-card'>
              <table
                className='table align-middle table-nowrap'
                id='invoiceTable'
              >
                <thead className='text-muted'>
                  <tr id='personel-row'>
                    <th className='col-6'>{t('personel.functionTitle')}</th>
                    <th className='col-6'>{t('personel.revenue')}</th>
                  </tr>
                </thead>
                <tbody className='list form-check-all' id='invoice-list-data'>
                  {revenue?.data?.map(
                    (reason: {
                      id: number;
                      functionTitle: string;
                      revenue: number;
                    }) => {
                      return (
                        <tr id='personel-row' key={reason?.id}>
                          <td className='col-6'>{reason?.functionTitle}</td>
                          <td className='col-6'>{reason?.revenue}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
    </>
  );
};
