import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import {
  iBids,
  iCompanies,
  iCompanyBids,
} from "../../../../model/tender/ICompanyBids";
import { TenderTypeSwitcher } from "./TenderTypeSwitcher";
import { TenderService } from "../../service";
import {
  ErrorToast,
  SuccessToast,
  WarningToast,
} from "../../../../shared/toasters/toasters";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TenderCompaniesAddingNewUser } from "./TenderCompaniesAddingNewUser";
import { TenderCompanyItem } from "./TenderCompanyItem";

interface iTenderCompaniesTableProps {
  companiesToUpdate: iCompanyBids;
  tenderId: string;
  bidderTypes: string[];
  companies: iCompanies[];
  setCompaniesToBid: any;
  companiesToBid: any;
  setSelectedCompaniesToBid: any;
}

export const TenderCompaniesTable = ({
  companiesToUpdate,
  tenderId,
  companies,
  bidderTypes,
  setCompaniesToBid,
  companiesToBid,
  setSelectedCompaniesToBid,
}: iTenderCompaniesTableProps) => {
  const { t } = useTranslation();
  const [isSingle, setIsSingle] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState<iCompanyBids>();
  const [bidds, setBidds] = useState<iBids[]>([]);
  const [newBidds, setNewBidds] = useState<iBids[]>([]);

  const navigate = useNavigate();
  const [tenderStatus, setTenderStatus] = useState("");
  const [userFunctions, setUserFunctions] = useState([]);
  const [newEmployee, setNewEmployee] = useState({
    userType: "",
    userId: null,
    userFunction: null,
  });

  const [isAddingNewCompany, setIsAddingNewCompany] = useState(false);

  useEffect(() => {
    tenderId &&
      TenderService.getTender(+tenderId!).then((response) => {
        response && setTenderStatus(response?.status.toLowerCase());
      });
  }, [JSON.stringify(tenderId)]);
  const [placeToAddUser, setPlaceToAddUser] = useState<null | number>();
  useEffect(() => {
    if (companiesToUpdate) {
      setBidds(
        companiesToUpdate.bids?.map((company: any) => ({
          ...company,
          companyId: company?.company?.id,
          employees: company?.employees?.map((emp: any) => ({
            userId: emp?.employee?.id,
            userType: emp?.type,
            employee: {
              firstName: emp?.employee?.firstName,
              lastName: emp?.employee?.lastName,
            },
            userFunctionToDisplay: {
              id: emp?.userFunction?.id,
              title: emp?.userFunction?.title,
            },
            userFunction: emp?.userFunction?.id,
            // type: emp?.userType,
          })),
        }))
      );
    }
  }, [JSON.stringify(companiesToUpdate)]);

  useEffect(() => {
    TenderService.getTenderUserFunctions()
      .then((res) => {
        setUserFunctions(res);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  useEffect(() => {
    if (companiesToUpdate) {
      setIsSingle(
        companiesToUpdate?.tenderCompanyType === "single_application"
      );

      setDataToUpdate((prev: any) => ({
        ...prev,
        bids: bidds?.concat(newBidds),
        tenderCompanyType: isSingle ? "single_application" : "consortium",
      }));
    }
  }, [
    JSON.stringify(companiesToUpdate),
    JSON.stringify(bidds),
    JSON.stringify(newBidds),
  ]);

  useEffect(() => {
    if (companiesToUpdate) {
      setDataToUpdate((prev: any) => ({
        ...prev,
        tenderCompanyType: isSingle ? "single_application" : "consortium",
      }));
    }
  }, [isSingle]);

  const changeCompanyHandler = (
    event: React.ChangeEvent<HTMLSelectElement>,
    positionInArray: number
  ) => {
    const copy: any = [...bidds];
    copy[positionInArray] = {
      ...copy[positionInArray],
      companyId: +event.target.id,
    };
    setBidds(copy);
  };

  const changeBidderType = (
    event: React.ChangeEvent<HTMLSelectElement>,
    positionInArray: number
  ) => {
    const copy: any = [...bidds];
    copy[positionInArray] = {
      ...copy[positionInArray],
      bidderType: event.target.value,
    };
    setBidds(copy);
  };

  function getUniqueListBy(arr: any, key: any) {
    const test = arr.reduce((acc: any, curr: any) => {
      const index = acc.findIndex(
        (currentItem: any) => currentItem[key] === curr[key]
      );
      index > -1 ? acc.concat(curr) : (acc[index] = curr);
    }, []);

    return test;
  }

  const changeEmployeeTypeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>,
    positionInArray: number,
    indexInEmployeeArray: number
  ) => {
    const copy: any = [...bidds];
    const newEmployee = [
      ...copy[positionInArray].employees,
      (copy[positionInArray].employees[indexInEmployeeArray] = {
        ...copy[positionInArray].employees[indexInEmployeeArray],
        [event.target.name]: event.target.value,
      }),
    ];

    copy[positionInArray] = {
      ...copy[positionInArray],
      employees: getUniqueListBy(newEmployee, "userId"),
    };

    setBidds(copy);
  };

  const changeUserHandler = (
    event: React.ChangeEvent<HTMLSelectElement>,
    positionInArray: number,
    indexInEmployeeArray: number
  ) => {
    const copy: any = [...bidds];
    const employees = [
      ...copy[positionInArray].employees,
      (copy[positionInArray].employees[indexInEmployeeArray] = {
        ...copy[positionInArray].employees[indexInEmployeeArray],
        [event.target.name]: +event.target.value,
      }),
    ];
    const replacedUserId = employees[indexInEmployeeArray]?.userId;

    copy[positionInArray] = {
      ...copy[positionInArray],
      employees: employees?.filter((user) => user?.userId !== replacedUserId),
    };
    setBidds(copy);
  };

  const changeUserFunction = (
    event: React.ChangeEvent<HTMLSelectElement>,
    positionInArray: number,
    indexInEmployeeArray: number
  ) => {
    const copy = [...bidds];

    const employees = [
      ...copy[positionInArray].employees,
      (copy[positionInArray].employees[indexInEmployeeArray] = {
        ...copy[positionInArray].employees[indexInEmployeeArray],
        [event.target.name]: +event.target.value,
      }),
    ];

    copy[positionInArray] = {
      ...copy[positionInArray],
      employees: getUniqueListBy(employees, "userId"),
    };
    setBidds(copy);
  };

  const addNewUserToList = (positionInArray: number) => {
    if (placeToAddUser === undefined) {
      setPlaceToAddUser(positionInArray);
    } else {
      const copy = [...bidds];
      setPlaceToAddUser(undefined);
      copy[positionInArray] = {
        ...copy[positionInArray],
        // @ts-ignore
        employees: [...copy[positionInArray].employees, newEmployee],
      };
      setBidds(copy);
    }
  };

  const removeUserFromList = (arrayIndex: number, userId: number) => {
    const copy = [...bidds];
    const deletedUserArray = copy[arrayIndex].employees?.filter(
      (user) => user.userId !== userId
    );
    copy[arrayIndex] = {
      ...copy[arrayIndex],
      // @ts-ignore
      employees: deletedUserArray,
    };
    setBidds(copy);
  };

  const removeCompanyFromArray = (companyId: number) => {
    const copy = [...bidds];
    const deletedCompanyArray = copy?.filter(
      (company) => company.id !== companyId
    );
    setBidds(deletedCompanyArray);
  };

  const addNewCompany = () => {
    setIsAddingNewCompany(true);
  };

  const updateData = () => {
    TenderService.createTenderCompanyBid(dataToUpdate!, +tenderId)
      .then((res) => {
        if (res) {
          SuccessToast(
            `${t("Tenders.companies.toasts.success_update_bidders")}`
          );
          navigate(`/tender/checklist/${tenderId}`);
        }
      })
      .catch((error) => ErrorToast(error));
  };

  const getRemainingCompanies = useMemo(() => {
    const usedCompaniesIDs = bidds?.map(
      (company: { companyId: number }) => company?.companyId
    );

    return companies?.filter(
      (company: { id: number }) => !usedCompaniesIDs.includes(company?.id)
    );
  }, [companies, bidds]);
  // const allCompanies = companies?.filter(company=> company.id )

  return (
    <>
      <div className="row w-100">
        <TenderTypeSwitcher isSingle={isSingle} setIsSingle={setIsSingle} />
      </div>
      <div className="row w-100 my-3">
        <div className="col-md-12">
          <div className="row">
            {bidds?.map((bid) => {
              return (
                <div className="my-2 col-md-4" key={bid?.id}>
                  <div className="my-3">
                    <label htmlFor="companyId">
                      {t("Tenders.companies.fields.company")}:
                    </label>
                    <select
                      name="companyId"
                      placeholder={t(
                        "Tenders.companies.fields.search_companies"
                      )}
                      onChange={(e) =>
                        changeCompanyHandler(e, bid?.position as number)
                      }
                      key={bid?.id}
                      id={`${bid?.id}`}
                      className="form-select"
                    >
                      <option value={bid?.companyId} hidden>
                        {bid?.company?.title}
                      </option>
                      {companies?.map((company) => (
                        <option value={company?.id} key={company?.id}>
                          {company?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="my-3">
                    <label htmlFor="bidderType">
                      {t("Tenders.companies.fields.bidder_type")}:
                    </label>
                    <select
                      name="bidderType"
                      className="form-select "
                      onChange={(e) =>
                        changeBidderType(e, bid?.position as number)
                      }
                      id={`${bid?.id}`}
                    >
                      <option value={bid?.bidderType} hidden>
                        {bid?.bidderType?.split("_")?.join(" ")?.toUpperCase()}
                      </option>
                      {bidderTypes?.map((bidderType, i) => {
                        return (
                          <option key={i} value={bidderType}>
                            {bidderType?.split("_")?.join(" ")?.toUpperCase()}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {bid?.employees?.map((employeeType, i) => {
                    return (
                      <div className="my-2 border p-2 box-shadow" key={i}>
                        <label htmlFor="userType">
                          {t("Tenders.companies.employee_type")}:
                        </label>
                        <select
                          name="userType"
                          className={`form-select`}
                          id={`${bid?.id}`}
                          onChange={(e) =>
                            changeEmployeeTypeHandler(
                              e,
                              bid?.position as number,
                              i
                            )
                          }
                        >
                          <option value={employeeType?.userType} hidden>
                            {employeeType?.userType}
                          </option>
                          <option value="lead">
                            {t("Tenders.companies.lead")}
                          </option>
                          <option value="support">
                            {t("Tenders.companies.support")}
                          </option>
                        </select>
                        <div className="my-2">
                          <label htmlFor="userType">
                            {t("Tenders.companies.userFunction")}:
                          </label>
                          <select
                            name="userFunction"
                            className={`form-select`}
                            onChange={(e) =>
                              changeUserFunction(e, bid?.position as number, i)
                            }
                          >
                            <option
                              value={
                                employeeType?.userFunctionToDisplay?.id
                                  ? employeeType?.userFunctionToDisplay?.id
                                  : 0
                              }
                              hidden
                            >
                              {employeeType?.userFunctionToDisplay?.title
                                ? employeeType?.userFunctionToDisplay?.title
                                : "NO FUNCTION"}
                            </option>

                            {userFunctions?.map(
                              (func: { id: number; title: string }) => {
                                return (
                                  <option key={func?.id} value={func?.id}>
                                    {func?.title}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                        <div className="my-2">
                          <label htmlFor="userId">
                            {t("Tenders.companies.user")}:
                          </label>
                          <select
                            name="userId"
                            className={`form-select`}
                            id={`${bid?.id}`}
                            onChange={(e) =>
                              changeUserHandler(e, bid?.position as number, i)
                            }
                          >
                            <option value={employeeType?.employee?.id} hidden>
                              {employeeType?.employee?.firstName}{" "}
                              {employeeType?.employee?.lastName}
                            </option>
                            {bid?.users
                              ?.filter((user) => user.isActive)
                              ?.map((companyUser, i) => {
                                return (
                                  <option value={companyUser?.id} key={i}>
                                    {companyUser?.firstName}{" "}
                                    {companyUser?.lastName}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="my-2 d-flex justify-content-end">
                          <button
                            className="btn btn-danger"
                            onClick={() =>
                              removeUserFromList(
                                bid?.position as number,
                                employeeType?.userId as number
                              )
                            }
                          >
                            - {t("Tenders.companies.fields.removeUser")}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                  {placeToAddUser === bid?.position && (
                    <div className="my-2">
                      <TenderCompaniesAddingNewUser
                        userFunctions={userFunctions}
                        bid={bid}
                        setNewEmployee={setNewEmployee}
                      />
                    </div>
                  )}
                  <div className="d-flex align-items-center justify-content-between my-2">
                    <button
                      className="btn btn-danger"
                      onClick={() => removeCompanyFromArray(bid?.id as number)}
                    >
                      - {t("Tenders.companies.fields.removeCompany")}
                    </button>
                    {bid?.employees?.length < 3 && (
                      <button
                        className="btn btn-success"
                        onClick={() =>
                          addNewUserToList(bid?.position as number)
                        }
                      >
                        {placeToAddUser === bid?.position
                          ? `+ ${t("Tenders.companies.fields.insertUser")}`
                          : `+ ${t("Tenders.companies.fields.add")}`}
                      </button>
                    )}
                  </div>
                </div>
              );
            })}

            {isAddingNewCompany && (
              <div className="my-2 col-md-4">
                <TenderCompanyItem
                  isSingle={isSingle}
                  indexId={bidds?.length}
                  userFunctions={userFunctions!}
                  bidderTypes={bidderTypes!}
                  setSelectedCompaniesToBid={setDataToUpdate}
                  companiesToBid={newBidds}
                  setCompaniesToBid={setNewBidds}
                  companies={getRemainingCompanies}
                  // key={i}
                />
              </div>
            )}
          </div>
        </div>
        {tenderStatus !== "done" && (
          <div className="col-12 d-flex justify-content-end mt-3">
            {bidds?.length < 3 && !isAddingNewCompany && (
              <button className="btn btn-success me-3" onClick={addNewCompany}>
                {t("Tenders.companies.addNewCompany")}
              </button>
            )}

            <button className="btn btn-primary" onClick={updateData}>
              {t("Tenders.companies.save_and_next")}
            </button>
          </div>
        )}
      </div>
    </>
  );
};
