import { useTranslation } from 'react-i18next';
import { PersonelTitles } from '../../../shared/enums/Enums';
import { PersonelFiltering } from './PersonelFiltering';

export const SubmittedPercent = ({
  data: { name, key, data },
  setSelectedKey,
  t,
  changeHandler,
  listOfAllUsers,
  selectedKey,
  userFunctions,
  irrelevanceReasons,
  cancellationList,
}: any) => {
  return (
    <div key={key}>
      <PersonelFiltering
        setSelectedKey={setSelectedKey}
        t={t}
        changeHandler={changeHandler}
        listOfAllUsers={listOfAllUsers}
        selectedKey={selectedKey}
        userFunctions={userFunctions}
        cancellationList={cancellationList}
        irrelevanceReasons={irrelevanceReasons}
      />

      <div className='table-responsive'>
        <table
          className='table align-middle table-nowrap mb-0 '
          id='invoiceTable'
        >
          <thead className='text-muted '>
            <tr id='personel-row'>
              <th>{t('personel.firstName')}</th>
              <th>{t('personel.lastName')}</th>
              <th>{t('personel.company')}</th>
              <th>{t('personel.email')}</th>
              <th>{t('personel.totalRelevant')}</th>
              <th>{t('personel.totalSubmitted')}</th>
              <th>{t('personel.percent')}</th>
            </tr>
          </thead>
          <tbody className='list form-check-all' id='invoice-list-data'>
            {data?.map((item: any) => {
              return (
                <tr id='personel-row'>
                  <td className='text-start'>{item?.firstName}</td>
                  <td className='text-start'>{item?.lastName}</td>
                  <td className='text-start'>{item?.company}</td>
                  <td className='text-start'>{item?.email}</td>
                  <td className='text-start'>{item?.totalRelevant}</td>
                  <td className='text-start'>{item?.totalSubmitted}</td>
                  <td className='text-start'>{item?.percent} %</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
