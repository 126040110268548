import React, { SetStateAction } from 'react';
import { IChecklist } from '../../../../model/tender/IChecklist';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { BASE_URL } from '../../../../utils/helpers/api.routes';
import { PriortyTask, StatusTask } from '../../../../shared/enums/Enums';
import { useTranslation } from 'react-i18next';

interface iProps {
  list: IChecklist;
  setSelectedTaskID: React.Dispatch<SetStateAction<number>>;
  showEditModalHandler: () => void;
}

export function TenderCheckListItem({
  list,
  setSelectedTaskID,
  showEditModalHandler,
}: iProps) {
  const { t } = useTranslation();

  return (
    <>
      <tr
        className={`${
          list?.status === 'pending'
            ? 'bg-soft-warning'
            : list?.status === 'completed'
            ? 'bg-soft-success'
            : 'bg-soft-light'
        } cursor-pointer`}
      >
        <td>
          <a
            onClick={() => {
              setSelectedTaskID(list?.id);
              showEditModalHandler();
            }}
            className="w-100 h-100 d-block "
          >
            {list.title}
          </a>
        </td>
        <td className="client_name">
          <a
            href="#updateModal"
            data-bs-toggle="modal"
            onClick={() => {
              setSelectedTaskID(list?.id);
              showEditModalHandler();
            }}
            className="w-100 h-100 d-block"
          >
            <div className="flex-grow-1">
              <div className="avatar-group avatar-group d-flex align-items-center gap-2">
                <div className="avatar-group-item">
                  <Tooltip
                    title={`${list?.user?.firstName} ${list?.user?.lastName}`}
                    arrow
                    placement="top"
                  >
                    <>
                      <img
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/images/placeholderImage.png';
                        }}
                        src={`${BASE_URL}${list?.user?.profilePicture?.path}`}
                        alt="Employee logo"
                        className="rounded-circle avatar-xxs"
                      />
                    </>
                  </Tooltip>
                </div>
                <span>{list?.user?.firstName}</span>
              </div>
            </div>
          </a>
        </td>
        <td className="due_date">
          <a
            onClick={() => {
              setSelectedTaskID(list?.id);
              showEditModalHandler();
            }}
            className="w-100 h-100 d-block"
          >
            {moment(list?.dueDate).format('DD.MM.YYYY.')}
          </a>
        </td>
        <td className="status">
          <a
            onClick={() => {
              setSelectedTaskID(list?.id);
              showEditModalHandler();
            }}
            className="w-100 h-100 d-block"
          >
            <span
              className={`badge ${
                list?.status === 'pending'
                  ? 'bg-warning'
                  : list?.status === 'new' || 'completed'
                  ? 'bg-success'
                  : 'bg-danger'
              } text-uppercase`}
            >
              {t(
                StatusTask[list?.status as keyof typeof StatusTask] as string
              )?.toUpperCase()}
            </span>
          </a>
        </td>
        <td className="priority">
          <a
            onClick={() => {
              setSelectedTaskID(list?.id);
              showEditModalHandler();
            }}
            className="w-100 h-100 d-block"
          >
            <span
              className={`badge ${
                list?.priority === 'high'
                  ? 'bg-danger'
                  : list?.priority === 'medium'
                  ? 'bg-warning'
                  : 'bg-success'
              } text-uppercase`}
            >
              {t(
                PriortyTask[
                  list?.priority as keyof typeof PriortyTask
                ] as string
              )?.toUpperCase()}
            </span>
          </a>
        </td>
        <td>
          <a
            className="edit-item-btn"
            href="#updateModal"
            data-bs-toggle="modal"
            onClick={() => {
              setSelectedTaskID(list?.id);
              showEditModalHandler();
            }}
          >
            <i className="ri-eye-fill align-bottom me-2 text-muted" />
          </a>
          <a
            href="#deleteTask"
            data-bs-toggle="modal"
            onClick={() => setSelectedTaskID(list?.id)}
          >
            <i className="ri-delete-bin-2-fill text-danger cursor-pointer" />
          </a>
        </td>
      </tr>
    </>
  );
}
