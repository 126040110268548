import React, { SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { German } from "flatpickr/dist/l10n/de";
import { english as English } from "flatpickr/dist/l10n/default";
import { currentLanguage } from "../../details/TenderDetails";

interface iSubmissionStatusProps {
  setSubmissionStatus: React.Dispatch<SetStateAction<any>>;
  setCreateTenderStatus: React.Dispatch<SetStateAction<any>>;
  changeHandler: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  submissionStatus: boolean | null;
  dropdownContent: any;
  tenderInfo: any;
  createTenderStatus: any;
  isSuperAdmin: boolean;
  isDisabled: boolean;
}

export const SubmissionStatus = ({
  setSubmissionStatus,
  submissionStatus,
  dropdownContent,
  setCreateTenderStatus,
  tenderInfo,
  isSuperAdmin,
  createTenderStatus,
  changeHandler,
  isDisabled,
}: iSubmissionStatusProps) => {
  const { t } = useTranslation();
  const changeSubmissionStatus = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSubmissionStatus(event?.target?.id === "submitted");
  };

  function handleDatePicker(evtName: string, evtValue: string) {
    const value = new Date(evtValue);
    value.setHours(12);
    setCreateTenderStatus((prev: any) => ({
      ...prev,
      handInDate: value.toISOString(),
    }));
  }

  return (
    <div className={`col-md-6`}>
      <h5>{t("Tenders.Status.submission_status")}</h5>
      <div className="form-check mt-4">
        <input
          className="form-check-input"
          type="radio"
          name="canceled"
          id="submitted"
          checked={submissionStatus ? submissionStatus : false}
          onChange={changeSubmissionStatus}
          disabled={tenderInfo?.status === "done"}
        />
        <label className="form-check-label" htmlFor="submitted">
          <h4 className="text-success">{t("Tenders.Status.submitted")}</h4>
        </label>
      </div>

      {submissionStatus && (
        <div className="row my-2">
          <div className="col-lg-4">
            <label className="form-label" htmlFor="project-title-input">
              {t("Tenders.Status.hand_in_date")}
            </label>
            <Flatpickr
              className="form-control"
              options={{
                disableMobile: true,
                locale: currentLanguage !== "en" ? German : English,
                dateFormat: "d.m.Y",
                allowInput: true,
              }}
              defaultValue={
                createTenderStatus?.handInDate
                  ? moment(createTenderStatus?.handInDate)?.format("DD.MM.YYYY")
                  : ""
              }
              onChange={(e: any) => {
                handleDatePicker("handInDate", `${e[0]}`);
              }}
              name="handInDate"
              disabled={!isSuperAdmin && tenderInfo?.status === "done"}
            />
          </div>
          <div className="col-lg-4 my-3 my-lg-0">
            <label htmlFor="choices-status-input" className="form-label">
              {t("Tenders.Status.hand_by")}
            </label>
            <select
              className="form-select"
              onChange={changeHandler}
              disabled={isDisabled}
              defaultValue=""
              name="handInBy"
              id="choices-status-input"
            >
              {createTenderStatus?.handInBy?.firstName ? (
                <option value={createTenderStatus?.handInBy?.id} hidden>
                  {createTenderStatus?.handInBy?.firstName}{" "}
                  {createTenderStatus?.handInBy?.lastName}
                </option>
              ) : (
                <option disabled />
              )}
              {dropdownContent?.handInByList?.map((handBy: any) => (
                <option value={handBy?.id} key={handBy?.id}>
                  {handBy?.firstName} {handBy?.lastName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-4">
            <label className="form-label" htmlFor="project-title-input">
              {t("Tenders.Status.max_revenue")}
            </label>
            <input
              type="number"
              name="maxRevenue"
              placeholder={t("Tenders.Status.max_revenue")}
              className="form-control"
              id="project-title-input"
              disabled={isDisabled}
              // defaultValue={createTenderStatus?.maxRevenue}
              value={createTenderStatus?.maxRevenue}
              onChange={changeHandler}
            />
          </div>
        </div>
      )}

      <div className="form-check mt-4">
        <input
          className="form-check-input"
          type="radio"
          name="canceled"
          id="canceled"
          onChange={changeSubmissionStatus}
          disabled={tenderInfo?.status === "done"}
          checked={submissionStatus === null ? false : !submissionStatus}
        />
        <label className="form-check-label" htmlFor="canceled">
          <h4 className="text-danger">{t("Tenders.Status.canceled")}</h4>
        </label>
      </div>
      {!(submissionStatus === null || submissionStatus) && (
        <>
          <div className="my-2">
            <label htmlFor="choices-status-input" className="form-label">
              {t("Tenders.Status.cancellation_reason")}
            </label>
            <select
              className="form-select"
              id="choices-status-input"
              name="cancellationReason"
              disabled={isDisabled}
              onChange={changeHandler}
              defaultValue=""
            >
              {createTenderStatus?.cancellationReason?.id ? (
                <option
                  value={createTenderStatus?.cancellationReason?.id}
                  hidden
                >
                  {createTenderStatus?.cancellationReason?.reason}
                </option>
              ) : (
                <option disabled />
              )}
              {dropdownContent?.cancellationReasons?.map(
                (cancellation: any) => (
                  <option value={cancellation?.id} key={cancellation?.id}>
                    {cancellation?.reason}
                  </option>
                )
              )}
            </select>
          </div>

          <div className="mt-3">
            <label className="form-label">
              {t("Tenders.Status.reason_text")}
            </label>
            <textarea
              rows={8}
              className="form-control"
              name="cancellationDescription"
              disabled={isDisabled}
              defaultValue={createTenderStatus?.cancellationDescription}
              onChange={changeHandler}
            />
          </div>
        </>
      )}
    </div>
  );
};
