import React, { useEffect, useRef, useState } from "react";
import { WarningToast } from "../../../../shared/toasters/toasters";
import {
  iCompanies,
  iUserFunctions,
} from "../../../../model/tender/ICompanyBids";
import Select from "react-select";
import { themeSelect } from "../../list/TenderFilter";
import { useTranslation } from "react-i18next";

interface iTenderCompanyItemProps {
  isSingle: boolean;
  companies: iCompanies[];
  userFunctions: iUserFunctions[];
  indexId: number;
  companiesToBid: any;
  setCompaniesToBid: any;
  setSelectedCompaniesToBid: any;
  bidderTypes: string[];
}

export const selectOptions = (
  arr: any,
  param: any,
  param2?: any,
  userType?: string,
  bidderType?: string
) => {
  return arr?.map((typ: any) => {
    return {
      value: typ.id,
      label: param2 ? `${typ[param]} ${typ[param2]}` : typ[param],
      users: typ.users,
      userType: userType,
      bidderType: bidderType,
    };
  });
};

export function TenderCompanyItem({
  isSingle,
  bidderTypes,
  companies,
  companiesToBid,
  indexId,
  setCompaniesToBid,
  userFunctions,
  setSelectedCompaniesToBid,
}: iTenderCompanyItemProps) {
  const { t } = useTranslation();
  const [employeeCompanyId, setEmployeeCompanyId] = useState<number>();
  const [numberOfSupportEmployees, setNumberOfSupportEmployees] = useState(0);
  const [selectedCompanyId, setSelectedCompanyId] = useState(-1);
  const [companyUsers, setCompanyUsers] = useState<any>([]);
  const [showFields, setShowFields] = useState(false);
  const changeSelectHandler = (e: any, type: string) => {
    const copy = [...companiesToBid];
    const index = copy.findIndex((company) => company.employees === undefined);
    const wrongCompanyID = copy?.findIndex(
      (company) => company?.companyId === selectedCompanyId
    );
    if (index !== -1) {
      copy.splice(index, 1);
    } else if (wrongCompanyID !== -1) {
      copy.splice(wrongCompanyID, 1);
    } else {
      setCompanyUsers(
        e?.users?.map((user: any) => ({ ...user, companyId: e?.value }))
      );
      setShowFields(true);
      setCompaniesToBid((prev: any) => {
        return [
          ...prev,
          {
            [type]: e?.value,
            bidderType: e?.bidderType,
          },
        ];
      });
      numberOfSupportEmployees === 0 &&
        setNumberOfSupportEmployees((prev) => prev + 1);
    }
  };

  const changeHandler = (event: any) => {
    let tempArr = [...companyUsers];
    companyUsers?.forEach((user: any) => {
      tempArr = tempArr.filter((users) => users?.id !== event?.value);
    });
    setCompanyUsers(tempArr);
    const copy = [...companiesToBid];
    const companyId = (
      companyUsers?.find((user: any) => +user?.id === +event?.value) as any
    )?.companyId;
    const companyToFind = companiesToBid.find(
      (company: any) => company?.companyId === companyId
    );
    companyToFind.employees = [
      ...(companyToFind?.employees ?? []),
      {
        userId: +event?.value,
        userType: event?.userType,
      },
    ];
    companyToFind.lastUserId = +event?.value;
    const companyIndex = companiesToBid?.findIndex(
      (company: any) => company?.companyId === companyId
    );
    copy.splice(companyIndex, 1, companyToFind);
    setCompaniesToBid(copy);
  };

  useEffect(() => {
    setSelectedCompaniesToBid((prev: any) => ({
      ...prev,
      bids: companiesToBid,
      tenderCompanyType: isSingle ? "single_application" : "consortium",
    }));
  }, [JSON.stringify(companiesToBid), JSON.stringify(isSingle)]);

  const changeCompanyBidder = (
    event: React.ChangeEvent<HTMLSelectElement>,
    companyId: number
  ) => {
    const copy = [...companiesToBid];
    const companyToFind = companiesToBid?.find(
      (company: any) => company?.companyId === companyId
    );
    if (companyToFind) {
      companyToFind.bidderType = event?.target.value;
    }
    const companyIndex = companiesToBid?.findIndex(
      (company: any) => company?.bidderType === companyId
    );
    copy.splice(companyIndex, 1, companyToFind);
    console.log(copy);

    setCompaniesToBid(copy);
  };

  const changeFunctionHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const copy = [...companiesToBid];
    const userId = companiesToBid?.find(
      (company: any) => company?.companyId === employeeCompanyId
    )?.lastUserId;
    const companyEmpoyees = companiesToBid?.find(
      (company: any) => company?.companyId === employeeCompanyId
    );
    const dataToUpdate = companyEmpoyees?.employees?.findIndex(
      (user: any) => user?.userId === +userId
    );
    if (companyEmpoyees) {
      companyEmpoyees.employees[dataToUpdate].userFunction =
        +event.target.value;
    }
    const companyIndex = companiesToBid?.findIndex(
      (company: any) => company?.companyId === employeeCompanyId
    );
    copy.splice(companyIndex, 1, companyEmpoyees);
    setCompaniesToBid(copy);
  };

  let companiesRef: any = useRef(null);
  const getValue = () => {
    setSelectedCompanyId(companiesRef?.current?.state?.selectValue[0]?.value);
  };

  const removeLastUser = (indexId: number) => {
    const copy = [...companiesToBid];
    const lastBid = copy?.[indexId]?.employees;
    lastBid.pop();
    setCompaniesToBid(copy);
    setNumberOfSupportEmployees(numberOfSupportEmployees - 1);
  };

  useEffect(() => {
    if (companyUsers?.length) {
      setEmployeeCompanyId(companyUsers[0]?.companyId);
    }
  }, [JSON.stringify(companyUsers)]);

  return (
    <>
      <div className="d-flex flex-column w-100">
        <Select
          placeholder={t("Tenders.companies.fields.search_companies")}
          options={selectOptions(
            companies,
            "title",
            "",
            "",
            `${
              companiesToBid?.length === 0
                ? bidderTypes && bidderTypes[0]
                : bidderTypes && bidderTypes[1]
            }`
          )}
          onChange={(e) => changeSelectHandler(e, "companyId")}
          theme={themeSelect}
          className="my-2"
          isDisabled={companiesToBid[indexId!]?.companyId}
          onMenuOpen={getValue}
          ref={companiesRef}
        />
        {showFields && (
          <select
            name="bidderType"
            className="form-select my-2"
            onChange={(e) => changeCompanyBidder(e, companyUsers[0]?.companyId)}
          >
            {bidderTypes?.map((bidder, i) => {
              return (
                <option
                  value={`${bidder}`}
                  disabled={
                    bidder === "active_bidder" && companiesToBid?.length > 1
                  }
                  key={i}
                >
                  {bidder?.split("_")?.join(" ")?.toUpperCase()}
                </option>
              );
            })}
          </select>
        )}
        {showFields &&
          numberOfSupportEmployees > 0 &&
          [...Array(1)].map((index, i) => {
            return (
              <React.Fragment key={index}>
                <Select
                  placeholder={t(
                    "Tenders.companies.fields.select_lead_employee"
                  )}
                  className="my-2"
                  isDisabled={companiesToBid[indexId!]?.employees}
                  options={selectOptions(
                    companyUsers,
                    `firstName`,
                    "lastName",
                    "lead"
                  )}
                  onChange={(e) => changeHandler(e)}
                  theme={themeSelect}
                />
                <select
                  name="userFunction"
                  className="form-select my-2"
                  // disabled={companiesToBid[indexId!]?.employees?.[i]?.userFunction}
                  id={`functionOrderId-${-1}`}
                  onChange={(e) => changeFunctionHandler(e)}
                >
                  <option hidden>
                    {t("Tenders.companies.fields.select_function")}
                  </option>
                  {userFunctions?.map((userFunc) => (
                    <option value={userFunc?.id} key={userFunc?.id}>
                      {userFunc?.title}
                    </option>
                  ))}
                </select>
              </React.Fragment>
            );
          })}
        {showFields &&
          numberOfSupportEmployees > 1 &&
          [...Array(numberOfSupportEmployees - 1)].map((index: number, i) => {
            return (
              <React.Fragment key={index}>
                <Select
                  placeholder={t(
                    "Tenders.companies.fields.select_support_employee"
                  )}
                  options={selectOptions(
                    companyUsers,
                    `firstName`,
                    "lastName",
                    "support"
                  )}
                  onChange={(e) => changeHandler(e)}
                  isDisabled={
                    companiesToBid[indexId!]?.employees?.length > i + 1
                  }
                  theme={themeSelect}
                  className="my-2"
                />
                <select
                  name="userFunction"
                  className="form-select my-2"
                  key={index}
                  // disabled={companiesToBid[indexId!]?.employees?.[i + 1]?.userFunction}
                  id={`functionOrderId-${i}`}
                  onChange={(e) => changeFunctionHandler(e)}
                >
                  <option hidden>
                    {t("Tenders.companies.fields.select_function")}
                  </option>
                  {userFunctions?.map((userFunc) => (
                    <option value={userFunc?.id} key={userFunc?.id}>
                      {userFunc?.title}
                    </option>
                  ))}
                </select>
              </React.Fragment>
            );
          })}
        {showFields && (
          <div className="col-lg-2 mb-2 p-0 d-flex align-items-center">
            {numberOfSupportEmployees > 1 && (
              <button
                className="btn btn-danger me-1"
                onClick={() => removeLastUser(+indexId as number)}
              >
                {t("Tenders.companies.fields.remove")}
              </button>
            )}
            <button
              className="btn btn-success "
              onClick={() => {
                if (numberOfSupportEmployees <= 20) {
                  setNumberOfSupportEmployees(numberOfSupportEmployees + 1);
                } else {
                  WarningToast(
                    `${t("Tenders.companies.toasts.maximum_supporters")}`
                  );
                }
              }}
            >
              {t("Tenders.companies.fields.add")}
            </button>
          </div>
        )}
      </div>
    </>
  );
}
