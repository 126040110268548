import React from 'react';
import { Tender } from '../../../model/tender/Tender';
import { TenderItem } from '../../tender/list/TenderItem';
import { useTranslation } from 'react-i18next';

interface IProps {
  relevanceList: Tender[];
  handleChange: (name: string, tendeList: string) => void;
}

export function DashboardRelevanceList({
  relevanceList,
  handleChange,
}: IProps) {
  const { t } = useTranslation();

  return (
    <div className="col-lg-12">
      <div className="tab-content text-muted">
        <div className="tab-pane active" id="recent" role="tabpanel">
          <div className="card" id="invoiceList">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">
                {t('dashboard.relevance_check_list.title')}
              </h4>
            </div>
            <div className="card-body">
              <div className="table-responsive table-card">
                <table
                  className="table align-middle table-nowrap"
                  id="invoiceTable"
                >
                  <thead className="text-muted">
                    <tr>
                      <th
                        onClick={() => handleChange('id', 'relevance')}
                        data-column-id="name"
                        className="gridjs-th gridjs-th-sort text-uppercase bg-white text-black-50"
                      >
                        <div className="gridjs-th-content">Id</div>
                        <button className="gridjs-sort gridjs-sort-neutral"></button>
                      </th>
                      <th
                        onClick={() => handleChange('number', 'relevance')}
                        className="gridjs-th gridjs-th-sort text-uppercase bg-white text-black-50"
                        data-sort="country"
                      >
                        <div className="gridjs-th-content">
                          {t('dashboard.relevance_check_list.number')}
                        </div>
                        <button className="gridjs-sort gridjs-sort-neutral"></button>
                      </th>
                      <th
                        onClick={() => handleChange('name', 'relevance')}
                        data-column-id="name"
                        className="gridjs-th gridjs-th-sort text-uppercase bg-white text-black-50"
                      >
                        <div className="gridjs-th-content">
                          {t('dashboard.relevance_check_list.name')}
                        </div>
                        <button className="gridjs-sort gridjs-sort-neutral"></button>
                      </th>
                      <th
                        onClick={() => handleChange('deadline', 'relevance')}
                        data-column-id="name"
                        className="gridjs-th gridjs-th-sort text-uppercase bg-white text-black-50"
                      >
                        <div className="gridjs-th-content">
                          {t('dashboard.relevance_check_list.deadline')}
                        </div>
                        <button className="gridjs-sort gridjs-sort-neutral"></button>
                      </th>
                      <th className="sort text-uppercase" data-sort="country">
                        {t('dashboard.relevance_check_list.checklist')}
                      </th>
                      <th className="sort text-uppercase" data-sort="status">
                        {t('dashboard.relevance_check_list.status')}
                      </th>
                      <th className="sort text-uppercase" data-sort="action">
                        {t('dashboard.relevance_check_list.action')}
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="list form-check-all bg-soft-warning"
                    id="invoice-list-data"
                  >
                    {relevanceList?.map((tender) => (
                      <TenderItem tender={tender} showCompaniesRow={false} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
