import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function InternalError() {
  const { t } = useTranslation();
  return (
    <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
      <div className="auth-page-content overflow-hidden p-0">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-4 text-center">
              <div className="error-500 position-relative">
                <img
                  src="assets/images/error500.png"
                  alt=""
                  className="img-fluid error-500-img error-img"
                />
                <h1 className="title text-muted">500</h1>
              </div>
              <div>
                <h4>Internal Server Error!</h4>
                <p className="text-muted w-75 mx-auto">{t("errors.500")}</p>
                <Link to="/" className="btn btn-success">
                  <i className="mdi mdi-home me-1" />
                  {t("errors.back_to_home")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
