import { requests } from '../../utils/helpers/api.services';
import { STATISTICS_URL } from '../../utils/helpers/api.routes';
import { TENDER_DETAILS_COMPANY } from '../../utils/helpers/api.routes';
import { iStatistics } from '../../model/statistics/Statistics';

export const StatisticsService = {
  getStatistics: (body: iStatistics) => requests.get(`${STATISTICS_URL}`, body),
  getCompanies: () => requests.get(`${TENDER_DETAILS_COMPANY}`),
  getTendersMonthlyRevenue: (body: any) =>
    requests.get(STATISTICS_URL + '/monthly-revenue-list', body),
  getRevenueViaExport: (body: any) =>
    requests.get(`${STATISTICS_URL + '/export-monthly-revenue-list'}`, body),
};
