import axios, { AxiosResponse } from 'axios';

const language = localStorage.getItem('language');

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
    accept: 'text/html',
    'Accept-Language': `${language ? language : 'de'}`,
  },
  validateStatus: function (status: number) {
    return status >= 200 && status < 300;
  },
};

export const instance = axios.create(defaultOptions);

instance.interceptors.request.use(function (config) {
  // const token = localStorage.getItem("token") ?? "{}"
  const token = localStorage.getItem('token') ?? '{}';
  config!.headers!.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

const responseBody = (response: AxiosResponse) => response.data;
const rejectResponse = (arg: any) => Promise.reject(arg);

export const requests = {
  get: (url: string, params?: any) =>
    instance
      .get(url, { params })
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  post: (url: string, body: {}) =>
    instance
      .post(url, body)
      .then(responseBody, rejectResponse)
      .catch((e) => {
        throw e;
      }),
  put: (url: string, body?: {}) =>
    instance
      .put(url, body)
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  patch: (url: string, body: {}) =>
    instance
      .patch(url, body)
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  delete: (url: string) =>
    instance
      .delete(url)
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
};

export function multipartFormData(
  files: any,
  url: string,
  body?: any,
  method = 'put'
) {
  const formData = new FormData();
  if (files?.length) {
    for (let file in files) {
      formData.append('file', files[file]);
    }
  } else {
    formData.append('file', files);
  }
  formData.append('body', body);
  if (method === 'put') {
    return instance.put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } else {
    return instance.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

// export function exportExcel(url: string, method = 'get'): Promise<any> {
//   // console.log(body);

//   // if (method === 'get') {
//   return instance.get(url, {
//     headers: {
//       'Content-Type':
//         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//       'Content-Disposition': 'attachment',
//     },
//   });
//   // }
// }
