import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function SideBar({ t }: any) {
  const location = useLocation();

  const body = document.querySelector('body');
  const closeMenu = () => {
    body!.classList.remove('vertical-sidebar-enable');
  };
  const pathname = location.pathname;
  const currentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const isSuperAdmin = currentUser?.roles?.some(
    (role: string) => role === 'ROLE_SUPER_ADMIN'
  );

  const isOnlyWorker = currentUser?.roles?.every(
    (role: string) => role === 'ROLE_COMPANY_WORKER'
  );

  const isCompanyAdmin = currentUser?.roles?.some(
    (role: string) => role === 'ROLE_COMPANY_ADMINISTRATOR'
  );
  return (
    <>
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <Link to="/dashboard" className="logo logo-light">
            <span className="logo-sm">
              <img
                src="../../images/logo/bbi_logo_white.png"
                alt=""
                height="22"
              />
            </span>
            <span className="logo-lg">
              <img
                src="../../images/logo/bbi_logo_white.png"
                alt=""
                height="45"
              />
            </span>
          </Link>
          <button
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line" />
          </button>
        </div>

        <div id="scrollbar">
          <div className="container-fluid">
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
              <li className="menu-title">
                <span data-key="t-menu">{t('side_bar.menu')}</span>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link menu-link ${
                    location.pathname === '/dashboard' && 'active'
                  }`}
                  onClick={closeMenu}
                  to="/dashboard"
                >
                  <i className=" ri-dashboard-line" />{' '}
                  <span data-key="t-dashboards">
                    {t('side_bar.dashboards')}
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link menu-link ${
                    location.pathname?.includes('/tender') && 'active'
                  }`}
                  onClick={closeMenu}
                  to="/tenders"
                >
                  <i className="ri-checkbox-multiple-fill" />{' '}
                  <span data-key="t-dashboards">{t('side_bar.tenders')}</span>
                </Link>
              </li>
              {!isOnlyWorker && (
                <li className="nav-item">
                  <Link
                    className={`nav-link menu-link ${
                      location.pathname === '/company' && 'active'
                    }`}
                    onClick={closeMenu}
                    to="/company"
                  >
                    <i className="ri-bank-line" />{' '}
                    <span data-key="t-dashboards">{t('side_bar.company')}</span>
                  </Link>
                </li>
              )}

              {!isOnlyWorker && (
                <li className="nav-item">
                  <Link
                    className={`nav-link menu-link ${
                      location.pathname === '/users' && 'active'
                    }`}
                    onClick={closeMenu}
                    to="/users"
                  >
                    <i className="ri-user-search-line" />{' '}
                    <span data-key="t-dashboards">{t('side_bar.users')}</span>
                  </Link>
                </li>
              )}

              {(isSuperAdmin || isCompanyAdmin) && (
                <li className="nav-item">
                  <a
                    className={`nav-link menu-link ${
                      pathname?.includes('/crud') && 'active'
                    } collapsed`}
                    href="#sidebarMultilevel"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarMultilevel"
                  >
                    <i className="ri-share-line"></i>{' '}
                    <span data-key="t-multi-level">{t('side_bar.crud')}</span>
                  </a>
                  <div
                    className={`menu-dropdown collapse ${
                      pathname?.includes('/crud') && 'show'
                    }`}
                    id="sidebarMultilevel"
                  >
                    <ul className="nav nav-sm flex-column">
                      {/* <li className="nav-item">
                        <Link
                          to="/crud/clients"
                          className={`${
                            pathname?.includes('/clients') && 'active'
                          } nav-link`}
                          data-key="t-level-1.1"
                        >
                          {t('side_bar.clients')}
                        </Link>
                      </li> */}
                      <li className="nav-item ">
                        <Link
                          to="/crud/cancellation"
                          className={`${
                            pathname?.includes('/cancellation') && 'active'
                          } nav-link`}
                          data-key="t-level-1.1"
                        >
                          {' '}
                          {t('side_bar.cancellation')}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/crud/irrelevance"
                          className={`${
                            pathname?.includes('/irrelevance') && 'active'
                          } nav-link`}
                          data-key="t-level-1.1"
                        >
                          {' '}
                          {t('side_bar.irrelevance')}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/crud/loss"
                          className={`${
                            pathname?.includes('/loss') && 'active'
                          } nav-link`}
                          data-key="t-level-1.1"
                        >
                          {' '}
                          {t('side_bar.loss')}{' '}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/crud/type"
                          className={`${
                            pathname?.includes('/type') && 'active'
                          } nav-link`}
                          data-key="t-level-1.1"
                        >
                          {' '}
                          {t('side_bar.tender_type')}{' '}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/crud/locations"
                          className={`${
                            pathname?.includes('/locations') && 'active'
                          } nav-link`}
                          data-key="t-level-1.1"
                        >
                          {' '}
                          {t('side_bar.locations')}{' '}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/crud/functions"
                          className={`${
                            pathname?.includes('/functions') && 'active'
                          } nav-link`}
                          data-key="t-level-1.1"
                        >
                          {' '}
                          {t('side_bar.functions')}{' '}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/crud/commissioner"
                          className={`${
                            pathname?.includes('/commissioner') && 'active'
                          } nav-link`}
                          data-key="t-level-1.1"
                        >
                          {' '}
                          {t('side_bar.commissioner')}{' '}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              )}
              {!isOnlyWorker && (
                <li className="nav-item">
                  <Link
                    className={`nav-link menu-link ${
                      location.pathname === '/external-earnings' && 'active'
                    }`}
                    onClick={closeMenu}
                    to="/external-earnings"
                  >
                    <i className="ri-money-euro-box-line" />{' '}
                    <span data-key="t-dashboards">
                      {t('side_bar.earnings')}
                    </span>
                  </Link>
                </li>
              )}

              {isSuperAdmin && (
                <li className="nav-item">
                  <Link
                    className={`nav-link menu-link ${
                      location.pathname === '/personnel' && 'active'
                    }`}
                    onClick={closeMenu}
                    to="/personnel"
                  >
                    <i className="ri-table-line" />{' '}
                    <span data-key="t-dashboards">
                      {t('side_bar.personnel')}
                    </span>
                  </Link>
                </li>
              )}
              {!isOnlyWorker && (
                <li className="nav-item">
                  <Link
                    className={`nav-link menu-link ${
                      location.pathname === '/statistics' && 'active'
                    }`}
                    onClick={closeMenu}
                    to="/statistics"
                  >
                    <i className=" ri-line-chart-line" />{' '}
                    <span data-key="t-dashboards">
                      {t('side_bar.statistics')}
                    </span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
