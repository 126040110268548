import React, { useEffect, useState } from "react";
import { ChangePassword } from "./ChangePassword";
import { iUserData } from "../../../model/user/User";
import { ProfileService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { multipartFormData } from "../../../utils/helpers/api.services";
import { BASE_URL, PROFILE_URL } from "../../../utils/helpers/api.routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MailNotifications } from "./MailNotifications";
import { UserInfo } from "./UserInfo";

export function Profile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [profile, setProfile] = useState<iUserData>();
  const [changeProfile, setChangeProfile] = useState<iUserData>();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImage] = useState("");

  useEffect(() => {
    ProfileService.getProfile()
      .then((res) => {
        res && setProfile(res);
      })
      .catch((err) => ErrorToast(err));
  }, []);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (
      event.target.name === "phone" ||
      event.target.name === "city" ||
      event.target.name === "address" ||
      event.target.name === "country" ||
      event.target.name === "zip"
    ) {
      setChangeProfile((prev: any) => ({
        ...prev,
        contact: {
          ...prev?.contact,
          [event.target.name]: event.target.value.trim(),
        },
      }));
    } else {
      setChangeProfile((prev: any) => ({
        ...prev,
        [event.target.name]: event.target.value.trim(),
      }));
    }
    console.log("usao");
  }

  function updateProfile(event: any) {
    event.preventDefault();
    navigate("/dashboard");
    multipartFormData(
      selectedFile,
      PROFILE_URL,
      changeProfile
        ? JSON.stringify({
            ...changeProfile,
            contact: changeProfile.contact
              ? changeProfile.contact
              : profile?.contact,
          })
        : JSON.stringify(profile),
      "put"
    )
      .then((response) => {
        SuccessToast(`${t("profile.toasts.successfully_updated_user")}`);
      })
      .catch((err) => ErrorToast(err));
  }

  function handleImageInput(evt: any) {
    if (evt.target.files.length > 0) {
      setSelectedFile(evt.target.files[0]);
      setImage(URL.createObjectURL(evt.target.files[0]));
    }
  }

  function returnToDashboard() {
    navigate("/dashboard");
  }

  return (
    <>
      <div className="row mt-5">
        <div className="col-xxl-3">
          <div className="card mt-n5">
            <div className="card-body p-4">
              <div className="text-center">
                <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                  {profile?.profilePicture?.path || imagePath ? (
                    <img
                      src={
                        imagePath
                          ? imagePath
                          : `${BASE_URL}${profile?.profilePicture?.path}`
                      }
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = "/images/placeholderImage.png";
                      }}
                      alt="user-profile-image"
                    />
                  ) : (
                    <img
                      src="/assets/images/img-placeholder.png"
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      alt="user-profile-image"
                    />
                  )}
                  <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                    <input
                      onChange={handleImageInput}
                      id="profile-img-file-input"
                      name="file"
                      type="file"
                      accept="image/*"
                      className="profile-img-file-input"
                    />
                    <label
                      htmlFor="profile-img-file-input"
                      className="profile-photo-edit avatar-xs"
                    >
                      <span className="avatar-title rounded-circle bg-light text-body">
                        <i className="ri-camera-fill"></i>
                      </span>
                    </label>
                  </div>
                </div>
                <h5 className="fs-16 mb-1">
                  {profile?.firstName} {profile?.lastName}
                </h5>
                <p className="text-muted mb-0">{profile?.email}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-9">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <ul
                className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#personalDetails"
                    role="tab"
                    aria-selected="true"
                  >
                    <i className="fas fa-home"></i>
                    {t("profile.personal_details")}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#changePassword"
                    role="tab"
                    aria-selected="false"
                  >
                    <i className="far fa-user"></i>
                    {t("profile.change_password")}
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#mailNotifications"
                    role="tab"
                    aria-selected="false"
                  >
                    <i className="far fa-user"></i>
                    {t("profile.mail_notifications")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body p-4">
              <div className="tab-content">
                <div
                  className="tab-pane active"
                  id="personalDetails"
                  role="tabpanel"
                >
                  <UserInfo
                    t={t}
                    profile={profile}
                    handleChange={handleChange}
                    updateProfile={updateProfile}
                    returnToDashboard={returnToDashboard}
                    setChangeProfile={setChangeProfile}
                  />
                </div>
                <div className="tab-pane" id="changePassword" role="tabpanel">
                  <ChangePassword />
                </div>
                <div
                  className="tab-pane"
                  id="mailNotifications"
                  role="tabpanel"
                >
                  <div className=" d-flex align-items-end justify-content-between">
                    <MailNotifications
                      t={t}
                      profile={profile}
                      setChangeProfile={setChangeProfile}
                      updateProfile={updateProfile}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
