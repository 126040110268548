import React, { FocusEventHandler, useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Form } from 'react-bootstrap';
import { CompanyService } from '../service';
import {
  ErrorToast,
  SuccessToast,
  WarningToast,
} from '../../../shared/toasters/toasters';
import {
  iCompanyDocument,
  iInitialCompany,
  iUserCompany,
} from '../../../model/company/Company';
import { DocumentComponent } from '../../../shared/components/DocumentComponent';
import { FileUploader } from 'react-drag-drop-files';
import { ConfirmDeleteModal } from '../../../shared/components/ConfirmDeleteModal';
import { BASE_URL } from '../../../utils/helpers/api.routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function CompanyForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const inputRef = useRef<any>(null);
  const [updateStates, setUpdateStates] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState<iCompanyDocument>();
  const [imagePath, setImage] = useState('');
  const [companyInfo, setCompanyInfo] = useState<iUserCompany>({
    title: '',
    email: '',
    address: '',
    zip: '',
    regNumber: '',
    country: '',
    phone: '',
    logo: {},
  });
  const [companyInfoCopy, setCompanyInfoCopy] =
    useState<iUserCompany>(companyInfo);
  const fileToPreview = [];
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyInfo((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const handleChange = (files: any) => {
    setCompanyInfo((prev) => ({ ...prev, file: files }));
  };

  useEffect(() => {
    CompanyService.getCompany()
      .then((res) => {
        setCompanyInfo(res);
        setCompanyInfoCopy(res);
      })
      .catch((error) => ErrorToast(error));
  }, [updateStates]);

  console.log(companyInfo);
  const updateCompanyInfo = () => {
    // if (
    //   JSON.stringify(companyInfo) === JSON.stringify(companyInfoCopy) &&
    //   !imagePath
    // )

    const data = new FormData();
    if (companyInfo?.file) {
      for (let file in companyInfo?.file) {
        data.append('file', companyInfo?.file[file]);
      }
    }

    if (inputRef?.current?.files?.length) {
      for (let file in inputRef?.current?.files) {
        data.append('logo', inputRef.current.files[file]);
      }
    }

    data.append(
      'body',
      JSON.stringify({
        ...companyInfo,
        regNumber: companyInfo?.regNumber,
        title: companyInfo?.title,
        email: companyInfo?.email,
        address: companyInfo?.address,
        zip: companyInfo?.zip,
        country: companyInfo?.country,
        phone: companyInfo?.phone,
      })
    );

    CompanyService.updateCompany(data)
      .then((res) => {
        if (res) {
          setUpdateStates(!updateStates);
          setCompanyInfo((prev: any) => ({ ...prev, file: null }));
          SuccessToast(`${t('Company.toasts.successfully_updated_company')}`);
          navigate('/dashboard');
        }
      })
      .catch((error) => ErrorToast(error));
  };

  function handleImageInput(evt: any) {
    if (evt.target.files.length > 0) {
      setImage(URL.createObjectURL(evt.target.files[0]));
    }
  }

  const deleteDocument = () => {
    if (selectedDocument?.id) {
      CompanyService.deleteCompanyDocument(selectedDocument?.id)
        .then((res) => {
          if (res != undefined) {
            SuccessToast(
              `${t('Tenders.toasts.successfully_deleted_document')}`
            );
            setUpdateStates(!updateStates);
          }
        })
        .catch((err) => ErrorToast(err));
    }
  };
  if (companyInfo?.file) {
    const myFiles: any[] = [];
    for (let file in companyInfo?.file) {
      myFiles.push(companyInfo?.file[file]);
    }
    const goodFile = myFiles.slice(0, myFiles.length - 2);
    for (const file of goodFile) {
      fileToPreview.push(file);
    }
  }

  const fileTypes = ["JPG", "PNG", "GIF", "PDF", "DOCX", "DOC", "XLS", "XLSX"];

  return (
    <div className="col-12 px-0">
      <Row className="mx-0 p-md-3">
        <div className="col-xl-3">
          <div className="card">
            <div className="card-body p-4">
              <div className="text-center">
                <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                  {companyInfoCopy?.logo || imagePath ? (
                    <img
                      src={
                        imagePath
                          ? imagePath
                          : `${BASE_URL}${companyInfoCopy?.logo?.path}`
                      }
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      alt="user-profile-image"
                    />
                  ) : (
                    <img
                      src="/assets/images/img-placeholder.png"
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      alt="user-profile-image"
                    />
                  )}
                  <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                    <input
                      id="profile-img-file-input"
                      type="file"
                      accept="image/*"
                      className="profile-img-file-input"
                      ref={inputRef}
                      onChange={handleImageInput}
                    />
                    <label
                      htmlFor="profile-img-file-input"
                      className="profile-photo-edit avatar-xs"
                    >
                      <span className="avatar-title rounded-circle bg-light text-body">
                        <i className="ri-camera-fill" />
                      </span>
                    </label>
                  </div>
                </div>
                <div>
                  <h5 className="fs-16 mb-1">{companyInfoCopy?.title}</h5>
                  <p className="text-muted mb-0">{companyInfoCopy?.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9">
          <div className="card px-3">
            <Row className="w-100 my-3 ">
              <Col xl={8}>
                <h5 className="mt-2 mb-4">{t('Company.title')}</h5>
                <Row className="mb-2">
                  <div className="col-12 my-2">
                    <Form.Label>{t('Company.name')}</Form.Label>
                    <Form.Control
                      name="title"
                      value={companyInfo?.title}
                      onChange={changeHandler}
                    />
                  </div>

                  <div className="col-12 my-2">
                    <Form.Label>{t('Company.reg_number')}</Form.Label>
                    <Form.Control
                      name="regNumber"
                      value={companyInfo?.regNumber}
                      onChange={changeHandler}
                    />
                  </div>

                  <div className="col-md-6 my-2">
                    <Form.Label>{t('Company.address')}</Form.Label>
                    <Form.Control
                      name="address"
                      value={companyInfo?.address}
                      onChange={changeHandler}
                    />
                  </div>

                  <div className="col-md-6 my-2">
                    <Form.Label>{t('Company.zip')}</Form.Label>
                    <Form.Control
                      name="zip"
                      value={companyInfo?.zip}
                      onChange={changeHandler}
                    />
                  </div>
                  <Form.Group as={Col}>
                    <Form.Label>{t('Company.country')}</Form.Label>
                    <Form.Control
                      name="country"
                      value={companyInfo?.country}
                      onChange={changeHandler}
                    />
                  </Form.Group>
                </Row>
                <h5 className="my-4">{t('Company.contact_details')}</h5>
                <Row className="mb-2">
                  <div className="col-md-6">
                    <Form.Label>{t('Company.email')}</Form.Label>
                    <Form.Control
                      name="email"
                      value={companyInfo?.email}
                      onChange={changeHandler}
                    />
                  </div>

                  <div className="col-md-6">
                    <Form.Label>{t('Company.phone')}</Form.Label>
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      value={companyInfo?.phone}
                      onSelect={(e: any) => {
                        setCompanyInfo((prev: any) => ({
                          ...prev,
                          phone: e.target.value,
                        }));
                      }}
                      onChange={changeHandler}
                    />
                  </div>
                </Row>
              </Col>
              <Col xl={4}>
                <div>
                  <h5 className="mt-2">{t('Company.upload')}</h5>
                  <p className="text-muted">{t('Company.upload_title')}</p>
                  <label
                    htmlFor="fileInput"
                    className="w-100 cursor-pointer fileInput"
                  >
                    <FileUploader
                      handleChange={handleChange}
                      name="file"
                      multiple
                      children={
                        <div className="dropzone dz-clickable d-flex align-items-center justify-content-center flex-column">
                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                          <h4 className="text-center">
                            {t('Company.upload_text')}
                          </h4>
                        </div>
                      }
                      types={fileTypes}
                    />
                  </label>
                </div>
                <h5 className="mt-3">{t('Company.documents')}</h5>
                <div className="file-wrapper">
                  {companyInfo?.documents!?.length > 0 ? (
                    companyInfo?.documents?.map(
                      (document: iCompanyDocument, i) => (
                        <DocumentComponent
                          setSelectedDocument={setSelectedDocument}
                          key={i}
                          setUpdateList={setUpdateStates}
                          updateList={updateStates}
                          modalId="deleteCompanyDocument"
                          document={document}
                        />
                      )
                    )
                  ) : (
                    <>
                      <p className="text-muted">
                        {t('Company.no_document_text')}
                      </p>
                    </>
                  )}
                  {!!fileToPreview.length &&
                    fileToPreview?.map((document) => {
                      return (
                        <div key={document?.name}>
                          <div className="d-flex border border-dashed p-2 rounded position-relative my-2">
                            <div className="flex-shrink-0 avatar-xs">
                              <div className="avatar-title bg-soft-danger text-danger fs-15 rounded">
                                <i className="ri-image-2-line" />
                              </div>
                            </div>
                            <div className="flex-shrink-0"></div>
                            <div className="flex-grow-1 overflow-hidden ms-2">
                              <h6 className="text-truncate mb-0">
                                {document.name}
                              </h6>
                              <small>{document.size} KB</small>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <ConfirmDeleteModal
                    modalId="deleteCompanyDocument"
                    title={`${t('Company.titles.company_document')}`}
                    deleteItem={deleteDocument}
                  />
                </div>
              </Col>
              <div className="col-12">
                <div className="text-end my-2">
                  <button
                    className="btn btn-danger me-2"
                    onClick={() => {
                      navigate('/dashboard');
                    }}
                  >
                    {t('modals.cancel')}
                  </button>

                  <button
                    type="button"
                    id="updateDataButton"
                    className="btn btn-primary w-sm"
                    onClick={updateCompanyInfo}
                  >
                    {t('Company.save')}
                  </button>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </Row>
    </div>
  );
}
