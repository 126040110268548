import React, {ChangeEvent} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import {SuccessToast} from "../../shared/toasters/toasters";
import {AuthService} from '../service';
export interface iRegister{
    firstName: string,
    lastName: string,
    email: string,
    password:string
}
export function Signup() {
    const navigate = useNavigate();

    const [registerState, setRegisterState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    })
    function changeHandler(event: ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        setRegisterState({
            ...registerState,
            [event.target.name]: value,
        });
    }

    const sendData = () => {
        AuthService.register(registerState!).then(res => {
            if (res !== undefined) {
                SuccessToast('You are successfully register, please login!')
                navigate('/')
            }
        }).catch(error => error);
    }


    return (
        <div className="bg-primary">
            <div className="page-single">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main py-45 justify-content-center mx-auto">
                            <div className="card-sigin mt-5 mt-md-0">
                                <div className="main-card-signin d-md-flex">
                                    <div className="wd-100p">
                                        <div className="d-flex mb-4"><img
                                            src="/images/favicon.png" className="sign-favicon ht-40"
                                            alt="logo"/></div>
                                        <div>
                                            <div className="main-signup-header">
                                                <h2 className="text-dark">Get Started</h2>
                                                <h6 className="font-weight-normal mb-4">It's free to signup and only
                                                    takes a
                                                    minute.</h6>

                                                <div className="form-group">
                                                    <label>Firstname </label> <input
                                                    className="form-control"
                                                    name="firstName"
                                                    onChange={changeHandler}
                                                    placeholder="Enter your firstname and lastname" type="text"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Lastname </label>
                                                    <input
                                                        className="form-control"
                                                        name="lastName"
                                                        onChange={changeHandler}
                                                        placeholder="Enter your lastname" type="text"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input className="form-control"
                                                           name="email"
                                                           onChange={changeHandler}
                                                           placeholder="Enter your email"
                                                           type="text"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <input className="form-control"
                                                           name="password"
                                                           onChange={changeHandler}
                                                           placeholder="Enter your password"
                                                           type="password"/>
                                                </div>
                                                <button className="btn btn-primary btn-block" onClick={sendData}>
                                                    Create
                                                    Account
                                                </button>

                                                <div className="main-signup-footer mt-3 text-center">
                                                    <p>Already have an account? <Link to="/">Sign In</Link></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}