import { Tender } from '../../../model/tender/Tender';
import { Tooltip } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../utils/helpers/api.routes';
import { useTranslation } from 'react-i18next';
import { FilterStatus } from '../../../shared/enums/Enums';
import moment from 'moment';

interface IProps {
  tender: Tender;
  showCompaniesRow: boolean;
}

export function TenderItem({ tender, showCompaniesRow }: IProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const passData = () => {
    navigate(`/tender/details/${tender.id}`, { state: { isEdit: true } });
  };

  const navigateToTenderInfo = (totalData: number, route: string) => {
    if (totalData > 0) {
      navigate(`/tender/${route}/${tender?.id}`, { state: { isEdit: true } });
    } else {
      navigate(`/tender/details/${tender?.id}`, { state: { isEdit: true } });
    }
  };

  return (
    <>
      <tr>
        <td className="id cursor-pointer">
          <a onClick={passData} className="fw-medium text-muted">
            {tender.id}
          </a>
        </td>
        <td className="number ">
          <p className="m-0">{tender?.tenderNumber}</p>
        </td>
        <td className="customer_name">
          <div className="d-flex align-items-center justify-content-center">
            <h5 className="mb-1 fs-15">
              <a onClick={passData} className="text-dark">
                {tender.title}
              </a>
            </h5>
            <div className="flex-shrink-0">
              <ul className="link-inline mb-0">
                <li className="list-inline-item">
                  <a
                    className="cursor-pointer text-muted"
                    onClick={() =>
                      navigateToTenderInfo(
                        tender.metrics?.messages as number,
                        'comments'
                      )
                    }
                  >
                    <i className="ri-question-answer-line align-bottom" />
                    {tender.metrics?.messages}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="cursor-pointer text-muted"
                    onClick={() =>
                      navigateToTenderInfo(
                        tender.metrics?.totalFiles as number,
                        'details'
                      )
                    }
                  >
                    <i className="ri-attachment-2 align-bottom" />{' '}
                    {tender.metrics?.totalFiles}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </td>
        <td className="country">
          <div className="text-muted">
            <i className="ri-calendar-event-fill me-1 align-bottom" />
            {tender?.applicationDeadline
              ? `${moment(tender?.applicationDeadline)?.format('DD.MM.YYYY')}`
              : `${t('Tenders.invalid_date')}`}
          </div>
        </td>
        <td>
          <div className="mt-auto">
            <div className="d-flex mb-2 justify-content-center">
              <div className="flex-shrink-0">
                <a
                  className="cursor-pointer text-muted"
                  onClick={() =>
                    navigateToTenderInfo(
                      tender.metrics?.totalTasks as number,
                      'checklist'
                    )
                  }
                >
                  <div>
                    <i className="ri-list-check align-bottom me-1 text-muted" />
                    {tender.metrics?.completedTasks}/
                    {tender.metrics?.totalTasks}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </td>
        {showCompaniesRow && (
          <td>
            {tender!?.bids!?.length > 0 ? (
              <div className="d-flex align-items-center ">
                <div className="flex-grow-1">
                  <div className="avatar-group d-flex justify-content-center">
                    {tender?.bids?.map((employee) => {
                      return (
                        <Link
                          to={`/tender/companies/${tender?.id}`}
                          key={employee?.id}
                        >
                          <div className="avatar-group-item">
                            <Tooltip
                              title={`${employee?.company?.title}`}
                              arrow
                              placement="top"
                            >
                              <img
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src =
                                    '/images/placeholderImage.png';
                                }}
                                src={`${BASE_URL}${employee?.company?.logo?.path}`}
                                alt="Employee logo"
                                className="rounded-circle avatar-xxs object-cover"
                              />
                            </Tooltip>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              '-'
            )}
          </td>
        )}
        <td className="status">
          <span
            className={`badge ${
              tender.status === 'pending'
                ? 'badge-soft-secondary'
                : tender.status === 'won' || 'submitted'
                ? 'badge-soft-success'
                : 'badge-soft-danger'
            }  text-uppercase`}
          >
            {t(
              FilterStatus[
                tender?.status as keyof typeof FilterStatus
              ] as string
            )}
          </span>
        </td>
        <td>
          <ul className="list-inline hstack gap-2 mb-0 d-flex justify-content-center">
            <li
              className="list-inline-item"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-placement="top"
              title=""
              data-bs-original-title="View"
            >
              <a
                onClick={passData}
                className="text-primary d-inline-block cursor-pointer"
              >
                <i className="ri-eye-fill fs-16" />
              </a>
            </li>
          </ul>
        </td>
      </tr>
    </>
  );
}
