import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Tender } from '../../../model/tender/Tender';
import { ErrorToast, WarningToast } from '../../../shared/toasters/toasters';
import { TenderService } from '../service';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

export function TenderTabs() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTender, setSelectedTender] = useState<Tender>();

  const { id } = useParams();
  const isCreateTender = location.pathname.split('/')[2] === 'create';
  const navigateToCompanies = (tender: Tender, route: string) => {
    if (tender.status === 'pending' && route === 'companies') {
      WarningToast(
        `${t(`Tenders.toasts.select_relevant_or_irrelevant`)} ${route}!`
      );
    } else if (!tender?.isRelevant) {
      WarningToast(`${t('Tenders.toasts.select_bidded_companies')} ${route}!`);
    } else {
      navigate(`/tender/${route}/${id}`);
    }
  };

  useEffect(() => {
    id &&
      TenderService.getTender(+id!)
        .then((res) => {
          res && setSelectedTender(res);
        })
        .catch((error) => ErrorToast(error));
  }, [id]);

  return (
    <>
      <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
        <ul className="nav nav-pills nav-justified custom-nav" role="tablist">
          {isCreateTender ? (
            <li className="nav-item" role="presentation">
              <span
                className={`nav-link fs-15 p-3 cursor-none ${
                  location.pathname === `/tender/details/${id}` && 'active'
                }`}
                data-position="0"
              >
                <i className="ri-file-edit-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2" />
                {t('Tenders.tabs.create')}
              </span>
            </li>
          ) : (
            <li className="nav-item" role="presentation">
              <Link
                to={`/tender/details/${id}`}
                state={{ isEdit: true }}
                className={`nav-link fs-15 p-3 ${
                  location.pathname === `/tender/details/${id}` && 'active'
                }`}
                data-position="0"
              >
                <i className="ri-file-edit-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2" />
                {t('Tenders.tabs.details')}
              </Link>
            </li>
          )}
          {!isCreateTender && (
            <>
              <li className="nav-item" role="presentation">
                <p
                  onClick={() =>
                    navigateToCompanies(selectedTender!, 'companies')
                  }
                  className={`nav-link fs-15 p-3 cursor-pointer ${
                    location.pathname === `/tender/companies/${id}` && 'active'
                  }`}
                >
                  <i className="ri-building-4-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2" />
                  {t('Tenders.tabs.companies')}
                </p>
              </li>
              <li className="nav-item" role="presentation">
                <p
                  onClick={() =>
                    navigateToCompanies(selectedTender!, 'checklist')
                  }
                  className={`nav-link fs-15 p-3 cursor-pointer ${
                    location.pathname === `/tender/checklist/${id}` && 'active'
                  }`}
                  data-position="0"
                >
                  <i className="ri-list-check-2 fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2" />
                  {t('Tenders.tabs.checklist')}
                </p>
              </li>
              <li className="nav-item" role="presentation">
                <p
                  onClick={() =>
                    navigateToCompanies(selectedTender!, 'comments')
                  }
                  className={`nav-link fs-15 p-3 cursor-pointer ${
                    location.pathname === `/tender/comments/${id}` && 'active'
                  }`}
                  data-position="0"
                >
                  <i className="ri-message-2-fill fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2" />
                  {t('Tenders.tabs.comments')}
                </p>
              </li>
              <li className="nav-item" role="presentation">
                <p
                  onClick={() => navigateToCompanies(selectedTender!, 'status')}
                  className={`nav-link fs-15 p-3 cursor-pointer ${
                    location.pathname === `/tender/status/${id}` && 'active'
                  }`}
                  data-position="0"
                >
                  <i className="ri-checkbox-circle-fill fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2" />
                  {t('Tenders.tabs.status')}
                </p>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
}
