import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  ErrorToast,
  SuccessToast,
  WarningToast,
} from '../../shared/toasters/toasters';
import { AuthService } from '../service';
import { useTranslation } from 'react-i18next';

export const FirstLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userPassword, setUserPassword] = useState({
    password: '',
    confirmPassword: '',
  });
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);

  const logoutUser = () => {
    localStorage.clear();
    navigate('/');
  };

  const changeHandler = (event: any) => {
    setUserPassword((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const resetHandler = () => {
    if (userPassword.password !== userPassword.confirmPassword)
      return WarningToast('Password is not match!');

    AuthService.firstTimeSetup({ password: userPassword.password })
      .then((res) => {
        navigate('/dashboard');
        SuccessToast('Successfully changed password!');
      })
      .catch((error) => ErrorToast(error));
  };

  window.onpopstate = function (e: any) {
    console.log(e.type);
    if (e.type === 'popstate') {
      localStorage.setItem('pageURL', '/change-password');
    }
  };

  return (
    <div className="auth-page-wrapper pt-5">
      {/* auth page bg */}
      <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div className="bg-overlay" />
        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1440 120"
          >
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
          </svg>
        </div>
        <canvas
          className="particles-js-canvas-el"
          width={1715}
          height={380}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      {/* auth page content */}
      <div className="auth-page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <a className="d-inline-block auth-logo" href="/">
                    <img
                      src="/images/logo/bbi_logo_white.png"
                      alt=""
                      height={70}
                      className=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* end row */}
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card mt-4">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">
                      {t('change_password.create_new_password')}
                    </h5>
                    <p className="text-muted">
                      {t('change_password.one_time_paragraph')}
                    </p>
                  </div>
                  <div className="p-2">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="password-input">
                        {t('change_password.password')}
                      </label>

                      <div className="position-relative auth-pass-inputgroup">
                        <input
                          type={passwordVisibility ? 'text' : 'password'}
                          className="form-control pe-5 password-input"
                          placeholder={t('change_password.enter_password')}
                          id="password-input"
                          aria-describedby="passwordInput"
                          name="password"
                          onChange={changeHandler}
                        />
                        <button
                          className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          type="button"
                          id="password-addon"
                        >
                          <i
                            className="ri-eye-fill align-middle"
                            onClick={() =>
                              setPasswordVisibility(!passwordVisibility)
                            }
                          />
                        </button>
                      </div>
                      <div id="passwordInput" className="form-text">
                        {t('change_password.must_be')}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="confirm-password-input"
                      >
                        {t('change_password.confirm_password')}
                      </label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <input
                          type={confirmPasswordVisibility ? 'text' : 'password'}
                          className="form-control pe-5 password-input"
                          placeholder={t('change_password.confirm_password')}
                          id="confirm-password-input"
                          name="confirmPassword"
                          onChange={changeHandler}
                          required
                        />
                        <button
                          className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          type="button"
                          id="confirm-password-input"
                        >
                          <i
                            className="ri-eye-fill align-middle"
                            onClick={() =>
                              setConfirmPasswordVisibility(
                                !confirmPasswordVisibility
                              )
                            }
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mt-4 d-flex flex-column align-items-center gap-3">
                      <button
                        onClick={resetHandler}
                        className="btn btn-success w-100"
                        type="button"
                      >
                        {t('change_password.reset_password')}
                      </button>
                      <button
                        className="btn btn-danger w-100"
                        type="button"
                        onClick={logoutUser}
                      >
                        {t('change_password.logout')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
