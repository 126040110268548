import React, { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { German } from 'flatpickr/dist/l10n/de';
import { english as English } from 'flatpickr/dist/l10n/default';
import { currentLanguage } from '../../details/TenderDetails';
import { TenderStatus } from '../TenderStatus';

interface iTenderApprovementProps {
  setApprovementStatus: React.Dispatch<SetStateAction<any>>;
  changeRevenueHandler: React.Dispatch<SetStateAction<any>>;
  approvementStatus: boolean | null;
  changeHandler: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  dropdownContent: any;
  createTenderStatus: any;
  setCreateTenderStatus: any;
  setTenderInfo: any;
  tenderInfo: any;
  isDisabled: boolean;
  filteredArr: {}[];
  isSuperAdmin: any;
}

export const TenderApprovement = ({
  setApprovementStatus,
  approvementStatus,
  changeHandler,
  setTenderInfo,
  tenderInfo,
  createTenderStatus,
  setCreateTenderStatus,
  changeRevenueHandler,
  dropdownContent,
  isDisabled,
  filteredArr,
  isSuperAdmin,
}: iTenderApprovementProps) => {
  const { t } = useTranslation();
  const [result, setResult] = useState(0);
  const [amountSum, setAmountSum] = useState(0);

  const changeApprovementStatus = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setApprovementStatus(event?.target?.id === 'won');
  };

  const changeAmountHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    month: string
  ) => {
    const copy = [...createTenderStatus.monthlyRevenue];

    const revenueIndex = copy?.findIndex(
      (revenue: { month: string }) => revenue?.month === month
    );

    copy[revenueIndex] = {
      month: month,
      amount: +event.target.value,
    };
    setCreateTenderStatus((prev: any) => ({ ...prev, monthlyRevenue: copy }));
  };

  function handleDatePicker(evtName: string, evtValue: string) {
    const value = new Date(evtValue);
    value.setHours(12);
    console.log(value);

    if (evtName === 'endDateOfPayment') {
      setCreateTenderStatus((prev: any) => ({
        ...prev,
        monthlyRevenue: generateMonths(
          tenderInfo?.startDate
            ? tenderInfo?.startDate
            : createTenderStatus?.startDate,
          value as unknown as Date,
          evtName
        ),
      }));

      setTenderInfo((prev: any) => ({ ...prev, [evtName]: value }));
    } else {
      setTenderInfo((prev: any) => ({ ...prev, [evtName]: value }));
    }
  }

  const generateMonths = (
    startDates: Date,
    endDate: Date,
    dateType: string
  ) => {
    if (dateType === 'endDateOfPayment') {
      let startDate = new Date(startDates);

      if (startDate === null || endDate === null) {
        return [];
      }
      if (endDate <= startDate) {
        return [];
      }

      startDate = new Date(startDate.getTime());
      startDate.setDate(1);
      endDate = new Date(endDate.getTime());

      const months: string[] = [];
      const endMonth = getFormattedYearMonth(endDate);
      while (true) {
        const month = getFormattedYearMonth(startDate);
        months.push(month);
        if (month === endMonth) {
          break;
        }
        startDate.setMonth(new Date(startDate).getMonth() + 1);
      }

      return months?.map((str) => {
        const monthObj = createTenderStatus?.monthlyRevenue?.find(
          (revenue: any) => revenue.month === str
        );

        return { month: str, amount: monthObj?.amount || null };
      });
    }
  };

  const getFormattedYearMonth = (date: Date) => {
    let month: any = new Date(date).getMonth();
    month++;
    if (month < 10) {
      month = '0' + String(month);
    } else {
      month = String(month);
    }

    return `${new Date(date).getFullYear()}-${month}`;
  };

  const sumOfFilteredArr = filteredArr.reduce(
    (prev: number, cur: any) => prev + cur.percent,
    0
  );

  const calculateMonthlyRevenue = () => {
    let copy = [...createTenderStatus.monthlyRevenue];
    const resetedValues = copy.map((item) => {
      return { month: item.month, amount: null };
    });

    setCreateTenderStatus((prev: any) => ({
      ...prev,
      monthlyRevenue: resetedValues,
    }));
    setResult(
      +(
        (createTenderStatus?.maxRevenue * sumOfFilteredArr) /
        100 /
        filteredArr.length /
        createTenderStatus?.monthlyRevenue?.length
      )?.toFixed(2)
    );
  };

  const calculateSumHandler = () => {
    let sum = 0;
    createTenderStatus?.monthlyRevenue?.forEach(
      (revenue: { month: string; amount: number }) => {
        if (revenue?.amount === null) {
          sum +=
            tenderInfo!?.maxRevenue /
            createTenderStatus?.monthlyRevenue?.length;
        } else {
          sum += revenue.amount;
        }
      }
    );
    setAmountSum(sum);
    console.log(amountSum);
  };

  console.log(createTenderStatus, tenderInfo);
  return (
    <div className="col-lg-6">
      <h5>{t('Tenders.Status.tender_approvement')}</h5>

      <div className="form-check mt-4">
        <input
          className="form-check-input"
          type="radio"
          name="approvement"
          checked={approvementStatus ? approvementStatus : false}
          disabled={tenderInfo?.status === 'done'}
          onChange={changeApprovementStatus}
          id="won"
        />
        <label className="form-check-label" htmlFor="won">
          <h4 className="text-success">{t('Tenders.Status.won')}</h4>
        </label>
      </div>

      {approvementStatus && (
        <div className="row my-2">
          <div className="col-lg-4">
            <label htmlFor="startDate" className="form-label">
              {t('Tenders.Status.start_date')}
            </label>
            <Flatpickr
              className="form-control"
              options={{
                disableMobile: true,
                dateFormat: 'd.m.Y',
                allowInput: true,
              }}
              defaultValue={
                tenderInfo?.startDate
                  ? moment(tenderInfo?.startDate)?.format('DD.MM.YYYY')
                  : ''
              }
              name="startDate"
              disabled={true}
            />
          </div>
          <div className="col-lg-4">
            <label htmlFor="endDate" className="form-label">
              {t('Tenders.Status.end_date')}
            </label>

            <Flatpickr
              className="form-control"
              autocomplete="off"
              options={{
                disableMobile: true,
                dateFormat: 'd.m.Y',
                allowInput: true,
                locale: currentLanguage !== 'en' ? German : English,
              }}
              defaultValue={
                tenderInfo?.endDate
                  ? moment(tenderInfo?.endDate)?.format('DD.MM.YYYY')
                  : ''
              }
              name="endDate"
              disabled={isDisabled && !tenderInfo.isProlongation}
              onChange={(e: any) => {
                handleDatePicker('endDate', `${e[0]}`);
              }}
              onClose={(e: any) => {
                handleDatePicker('endDate', `${e[0]}`);
              }}
            />
          </div>
          <div className="col-lg-4">
            <label htmlFor="endDateOfPayment" className="form-label">
              End date of payment
            </label>
            <Flatpickr
              className="form-control"
              autocomplete="off"
              options={{
                disableMobile: true,
                dateFormat: 'd.m.Y',
                allowInput: true,
                locale: currentLanguage !== 'en' ? German : English,
              }}
              defaultValue={
                createTenderStatus.endDateOfPayment
                  ? moment(createTenderStatus.endDateOfPayment)?.format(
                      'DD.MM.YYYY'
                    )
                  : moment(tenderInfo?.endDate)?.format('DD.MM.YYYY')
              }
              name="endDateOfPayment"
              onChange={(e: any) => {
                handleDatePicker('endDateOfPayment', `${e[0]}`);
              }}
              onClose={(e: any) => {
                handleDatePicker('endDateOfPayment', `${e[0]}`);
              }}
            />
          </div>

          <div className="col-12 mt-5 pb-2">
            <h5>{t('Tenders.Status.revenue_split')}</h5>
          </div>

          {tenderInfo?.bids?.map((bid: any, i: number) => {
            return (
              <div className="col-lg-6 mt-2" key={i}>
                <label htmlFor="endDate" className="form-label">
                  {`${t('Company.name')}`}: {bid?.company?.title}
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="revenueSplit"
                  id={bid?.company?.id}
                  onChange={changeRevenueHandler}
                  disabled={isDisabled && !tenderInfo.isProlongation}
                  defaultValue={
                    tenderInfo?.status === 'done'
                      ? createTenderStatus?.revenueSplit[i]?.percent
                      : ''
                  }
                  placeholder={`${t('Tenders.Status.revenue_split')} %`}
                />
              </div>
            );
          })}
          <div className="wrapper">
            <button
              onClick={calculateMonthlyRevenue}
              className="btn btn-primary mt-4 col-2"
            >
              Calculate
            </button>
          </div>
          <div className="col-12 mt-5">
            <h5>{t('Tenders.Status.monthlyRevenue')}</h5>
          </div>
          <div className="col-12 px-0">
            <div className="row mx-0 mt-2 w-100">
              {createTenderStatus?.monthlyRevenue?.map(
                (revenue: { month: string; amount: number }, i: number) => {
                  return (
                    <div className="col-lg-2" key={i}>
                      <label htmlFor={revenue?.month}>{revenue?.month}</label>
                      <input
                        type="number"
                        className="form-control test"
                        disabled={isDisabled && !tenderInfo.isProlongation}
                        value={result > 0 ? result : revenue.amount}
                        onChange={(e) => changeAmountHandler(e, revenue?.month)}
                        name="amount"
                      />
                    </div>
                  );
                }
              )}
            </div>
            <div className="d-flex align-items-center mt-4 gap-4">
              <button
                className="btn btn-primary col-2"
                onClick={calculateSumHandler}
              >
                Check Sum
              </button>
              {amountSum > 0 && (
                <p
                  className={
                    amountSum > createTenderStatus?.maxRevenue
                      ? 'text-danger m-0'
                      : 'text-success m-0'
                  }
                >
                  {amountSum == createTenderStatus?.maxRevenue
                    ? `Sum(${amountSum}) is equal to Max revenue(${createTenderStatus.maxRevenue})`
                    : amountSum > createTenderStatus?.maxRevenue
                    ? `Sum is ${amountSum} and is bigger then  max revenue (${createTenderStatus.maxRevenue})`
                    : `Sum is ${amountSum} and is smaller then  max revenue (${createTenderStatus.maxRevenue})`}
                </p>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="form-check mt-4">
        <input
          className="form-check-input"
          type="radio"
          name="approvement"
          checked={approvementStatus === null ? false : !approvementStatus}
          disabled={tenderInfo?.status === 'done'}
          onChange={changeApprovementStatus}
          id="lost"
        />
        <label className="form-check-label" htmlFor="lost">
          <h4 className="text-danger">{t('Tenders.Status.lost')}</h4>
        </label>
      </div>

      {!(approvementStatus === null || approvementStatus) && (
        <>
          <div className="my-2">
            <label htmlFor="choices-status-input" className="form-label">
              {t('Tenders.Status.lost_reason')}
            </label>
            <select
              className="form-select"
              id="choices-status-input"
              name="lostReason"
              defaultValue=""
              disabled={isDisabled}
              onChange={changeHandler}
            >
              {createTenderStatus?.lostReason?.id ? (
                <option value={createTenderStatus?.lostReason?.id} hidden>
                  {createTenderStatus?.lostReason?.reason}
                </option>
              ) : (
                <option hidden />
              )}
              {dropdownContent?.lostReasons?.map((reason: any) => (
                <option value={reason?.id} key={reason?.id}>
                  {reason?.reason}
                </option>
              ))}
            </select>
          </div>

          <div className="my-2">
            <label className="form-label">
              {t('Tenders.Status.lost_description')}
            </label>
            <div>
              <textarea
                rows={8}
                className="form-control"
                name="lostDescription"
                disabled={isDisabled}
                defaultValue={createTenderStatus?.lostDescription}
                onChange={changeHandler}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
