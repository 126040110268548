export const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_MODE
    : process.env.REACT_APP_PRO_MODE;

export const LOGIN_URL = BASE_URL + 'signin';
export const REGISTER_URL = BASE_URL + 'register';
export const RESET_PASSWORD_URL = BASE_URL + 'forgot-password';
export const FIRST_TIME_SETUP = BASE_URL + 'password-first-time-setup';

export const USER_URL = BASE_URL + 'app/users';

export const ENUMS_URL = BASE_URL + 'enums';

export const TENDER_URL = BASE_URL + 'app/tender';

export const TENDER_FILTER_URL =
  BASE_URL + 'app/tender/dropdowns/tender-filters';

export const TENDER_DETAILS_URL =
  BASE_URL + 'app/tender/dropdowns/tender-details';

export const TENDER_DETAILS_COMPANY =
  BASE_URL + 'app/tender/dropdowns/tender-companies';

export const DELETE_TENDER_DOCUMENT = BASE_URL + 'app/tender/document';

export const TENDER_CHECKLIST_URL = BASE_URL + 'app/tender/task';

export const TENDER_CHECKLIST_WORKERS_URL =
  BASE_URL + 'app/tender/task/workers';

export const TENDER_CHECKLIST_DOCUMENTS_URL =
  BASE_URL + 'app/tender/task/company-documents';

export const TENDER_COMMENTS_URL = BASE_URL + 'app/tender/comment';

export const TENDER_USER_FUNCTIONS_DROPDOWN_URL =
  BASE_URL + 'app/tender/dropdowns/user-functions';

export const POSSIBLE_SUPERVISORS_URL =
  BASE_URL + 'app/tender/dropdowns/possible-supervisors';

export const TENDER_STATUS_URL = BASE_URL + 'app/tender/status';

export const COMPANY_URL = BASE_URL + 'app/company';

export const DASHBOARD_URL = BASE_URL + 'app/dashboard';

export const PROFILE_URL = BASE_URL + 'app/profile';

export const NOTIFY_LIST_URL = BASE_URL + 'app/common/notification/list';

export const NOTIFY_COUNT_URL =
  BASE_URL + 'app/common/notification/unread-count';

export const EARNINGS_URL = BASE_URL + 'app/external-earnings';

export const STATISTICS_URL = BASE_URL + 'app/statistics';

export const DROPDOWNS_URL = BASE_URL + 'app/predefined-dropdowns';

export const CLIENT_URL = DROPDOWNS_URL + '/client';

export const CANCELLATION_URL = DROPDOWNS_URL + '/cancellation';

export const IRRELEVANCE_URL = DROPDOWNS_URL + '/irrelevance';

export const LOSS_URL = DROPDOWNS_URL + '/loss';

export const TYPE_URL = DROPDOWNS_URL + '/tender-type';

export const LOCATION_URL = DROPDOWNS_URL + '/location';

export const FUNCTION_URL = DROPDOWNS_URL + '/function';

export const COMMISSIONER_URL = DROPDOWNS_URL + '/commissioner';

export const PERSONAL_URL = BASE_URL + 'app/personal';
