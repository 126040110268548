import { TenderList } from "./list/TenderList";
import { TenderDetails } from "./form/details/TenderDetails";
import { PageTemplate } from "../../shared/layout/PageTemplate";
import { TenderComments } from "./form/comments/TenderComments";
import { TenderCheckList } from "./form/checklist/TenderCheckList";
import { TenderStatus } from "./form/status/TenderStatus";
import { TenderCompanies } from "./form/companies/TenderCompanies";
import { useTranslation } from "react-i18next";

export function Tender() {
  const { t } = useTranslation();

  return (
    <PageTemplate
      pageName={`${t("header.title.tenders")}`}
      title={`${t("header.title.tenders")}`}
      content={<TenderList />}
    />
  );
}

export function TenderDetailsPage() {
  const { t } = useTranslation();
  return (
    <PageTemplate
      pageName={`${t("header.title.tenders_details")}`}
      title={`${t("header.title.tenders_details")}`}
      content={<TenderDetails />}
    />
  );
}

export function TenderCompaniesPage() {
  const { t } = useTranslation();
  return (
    <PageTemplate
      pageName={`${t("header.title.tenders_companies")}`}
      title={`${t("header.title.tenders_companies")}`}
      content={<TenderCompanies />}
    />
  );
}

export function TenderChecklistPage() {
  const { t } = useTranslation();
  return (
    <PageTemplate
      pageName={`${t("header.title.tenders_checklist")}`}
      title={`${t("header.title.tenders_checklist")}`}
      content={<TenderCheckList />}
    />
  );
}

export function TenderCommentsPage() {
  const { t } = useTranslation();
  return (
    <PageTemplate
      pageName={`${t("header.title.tenders_comments")}`}
      title={`${t("header.title.tenders_comments")}`}
      content={<TenderComments />}
    />
  );
}

export function TenderStatusPage() {
  const { t } = useTranslation();
  return (
    <PageTemplate
      pageName={`${t("header.title.tenders_status")}`}
      title={`${t("header.title.tenders_status")}`}
      content={<TenderStatus />}
    />
  );
}
