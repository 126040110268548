import React, { ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUserFilterParams } from '../dto/IUserFilterParams';
import { useTranslation } from 'react-i18next';

interface IProps {
  state: IUserFilterParams;
  setState: React.Dispatch<React.SetStateAction<IUserFilterParams>>;
}

export function UserFilter({ state, setState }: IProps) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
  };

  const passData = () => {
    navigate('/user/form', { state: { isEdit: false } });
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="row g-2">
          <div className="col-sm-4">
            <div className="search-box">
              <input
                type="text"
                className="form-control"
                placeholder={t('Users.search_name')}
                name="name"
                onChange={handleChange}
              />
              <i className="ri-search-line search-icon"></i>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="search-box">
              <input
                type="text"
                className="form-control"
                placeholder={t('Users.search_email')}
                name="email"
                onChange={handleChange}
              />
              <i className="ri-search-line search-icon"></i>
            </div>
          </div>
          <div className="col-sm-auto ms-auto">
            <div className="list-grid-nav hstack gap-1">
              <button
                onClick={passData}
                className="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#addmembers"
              >
                <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                {t('Users.add_members')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
