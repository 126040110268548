import React from "react";
import {Tooltip} from "@mui/material";
import {BASE_URL} from "../../../../../utils/helpers/api.routes";
import {useTranslation} from "react-i18next";

interface iProps {
    otherData: any
}


export const TenderCommentsHeader = ({otherData}: iProps) => {
    const {t} = useTranslation()
    return (
        <div className="p-3 user-chat-topbar">
            <div className="row align-items-center">
                <div className="col-sm-4 col-8 d-flex flex-column-reverse align-items-baseline">
                    <div className="d-flex align-items-center flex-column-reverse">
                        <div className="flex-grow-1 overflow-hidden">
                            <div className="flex-grow-1">
                                <div className="avatar-group mt-2">
                                    {otherData?.bids?.map((employee: any) => {
                                        return (
                                            <div className="avatar-group-item">
                                                <Tooltip
                                                    title={`${employee?.employees[0]?.employee?.company?.title}`}
                                                    arrow placement="top">
                                                    <img
                                                        onError={({currentTarget}) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src = "/images/placeholderImage.png";
                                                        }}
                                                        src={`${BASE_URL}${employee?.employees[0]?.employee?.company?.logo?.path}`}
                                                        alt="Employee logo"
                                                        className="rounded-circle avatar-xxs object-cover"/>
                                                </Tooltip>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                        <h5 className="text-truncate mb-0 fs-16">
                            {t("Tenders.Comments.title")}: {otherData?.title}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    )
}