import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ForgotPassword } from '../authentication/login/ForgotPassword';
import { Signup } from '../authentication/register/Signup';
import { NotFound } from '../shared/errors/404';
import { InternalError } from '../shared/errors/500';
import { UserFormPage, Users } from './users';
import { ProfileTemplate } from './profile/ProfileTemplate';
import {
  Tender,
  TenderCommentsPage,
  TenderDetailsPage,
  TenderCompaniesPage,
  TenderChecklistPage,
  TenderStatusPage,
} from './tender';
import { Dashboard } from './dashboard';
import { Company } from './company';
import { Statistics } from './statistics';
import { Login } from '../authentication/login/Login';
import { Notification } from './notification';
import { ExternalEarnings } from './earnings';
// import { Clients } from './cruds/clients';
import { Cancellation } from './cruds/cancellation';
import { Irrelevance } from './cruds/irrelevance';
import { Loss } from './cruds/loss';
import { Type } from './cruds/type';
import { Locations } from './cruds/locations';
import { Functions } from './cruds/functions';
import { Commissioner } from './cruds/commissioner';
import { Personnel } from './personnel';
import { FirstLogin } from '../authentication/firstLogin/FirstLogin';

export function BaseRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/change-password" element={<FirstLogin />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/tenders" element={<Tender />} />
        <Route path="/tenders/:status" element={<Tender />} />
        <Route path="/external-earnings" element={<ExternalEarnings />} />
        <Route path="/tender/details/:id" element={<TenderDetailsPage />} />
        <Route path="/tender/create" element={<TenderDetailsPage />} />
        <Route path="/tender/companies/:id" element={<TenderCompaniesPage />} />
        <Route path="/tender/checklist/:id" element={<TenderChecklistPage />} />
        <Route path="/tender/comments/:id" element={<TenderCommentsPage />} />
        <Route path="/tender/status/:id" element={<TenderStatusPage />} />
        <Route path="/company" element={<Company />} />
        <Route path="/users" element={<Users />} />
        <Route path="/user/form" element={<UserFormPage />} />
        <Route path="/statistics" element={<Statistics />} />
        {/* <Route path="/crud/clients" element={<Clients />} /> */}
        <Route path="/crud/cancellation" element={<Cancellation />} />
        <Route path="/crud/irrelevance" element={<Irrelevance />} />
        <Route path="/crud/loss" element={<Loss />} />
        <Route path="/crud/type" element={<Type />} />
        <Route path="/crud/locations" element={<Locations />} />
        <Route path="/crud/functions" element={<Functions />} />
        <Route path="/crud/commissioner" element={<Commissioner />} />
        <Route path="/notifications" element={<Notification />} />
        <Route path="/personnel" element={<Personnel />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/500" element={<InternalError />} />
        <Route path="/profile" element={<ProfileTemplate />} />
      </Routes>
    </>
  );
}
