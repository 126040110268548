import React, {SetStateAction} from 'react'
import {useTranslation} from "react-i18next";

interface iTenderTypeSwitcherProps {
    isSingle?: boolean,
    setIsSingle: React.Dispatch<SetStateAction<boolean>>
}

export function TenderTypeSwitcher({isSingle, setIsSingle}: iTenderTypeSwitcherProps) {
    const {t} = useTranslation()

    return (
        <>
            <div className="col-6 col-lg-2">
                <div className="form-check mb-2">
                    <input className="form-check-input" type="radio" onChange={() => setIsSingle(true)}
                           checked={isSingle} name="flexRadioDefault" id="flexRadioDefault1"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                        {t("Tenders.companies.single_application")}
                    </label>
                </div>
            </div>
            <div className=" col-6 col-lg-2">
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault"
                           onChange={() => setIsSingle(false)} checked={!isSingle}
                           id="flexRadioDefault2"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                        {t("Tenders.companies.consortium")}
                    </label>
                </div>
            </div>
        </>
    )
}