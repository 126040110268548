import { IDashboard } from "./IDashboard";

export const InitialDashboardData: IDashboard = {
  lost: 0,
  canceled: 0,
  relevanceList: [],
  personalTenders: [],
  tenders: [],
  total: 0,
  won: 0,
};
