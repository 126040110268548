import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { iDashboard } from '../../../model/dashboard/Dashboard';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

interface IProps {
  dashboardStats: iDashboard;
}

export function DashboardCards({ dashboardStats }: IProps) {
  const { t } = useTranslation();

  return (
    <div className="col-12">
      <div className="row">
        <div className="col-xl-3 col-lg-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    {t('dashboard.cards.total')}{' '}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <CountUp
                      start={0}
                      duration={1}
                      end={dashboardStats?.total}
                      className="counter-value"
                      data-target="559.25"
                    >
                      {({ countUpRef }) => (
                        <div>
                          <span ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                  </h4>
                  <Link
                    state={{ active: 'recent' }}
                    to="/tenders/recent"
                    className="text-decoration-underline"
                  >
                    {t('dashboard.cards.view_all_tenders')}
                  </Link>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-info rounded fs-3">
                    <i className="bx bx-dollar-circle text-info" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    {t('dashboard.cards.win')}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <CountUp
                      start={0}
                      duration={1}
                      end={dashboardStats?.won}
                      className="counter-value"
                      data-target="559.25"
                    >
                      {({ countUpRef }) => (
                        <div>
                          <span ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                  </h4>
                  <Link
                    state={{ active: 'won' }}
                    to="/tenders/won"
                    className="text-decoration-underline"
                  >
                    {t('dashboard.cards.view_all_won_tenders')}
                  </Link>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-success rounded fs-3">
                    <i className="mdi mdi-checkbox-marked-circle-outline text-success" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    {t('dashboard.cards.lost')}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <CountUp
                      start={0}
                      duration={1}
                      end={dashboardStats?.lost}
                      className="counter-value"
                      data-target="559.25"
                    >
                      {({ countUpRef }) => (
                        <div>
                          <span ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                  </h4>

                  <Link
                    state={{ active: 'lost' }}
                    to="/tenders/lost"
                    className="text-decoration-underline"
                  >
                    {t('dashboard.cards.view_all_lost_tenders')}
                  </Link>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-danger rounded fs-3">
                    <i className=" mdi mdi-close-circle-outline text-danger" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    {t('dashboard.cards.canceled')}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <CountUp
                      start={0}
                      duration={1}
                      end={dashboardStats?.canceled}
                      className="counter-value"
                      data-target="559.25"
                    >
                      {({ countUpRef }) => (
                        <div>
                          <span ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                  </h4>
                  <Link
                    state={{ active: 'canceled' }}
                    to="/tenders/canceled"
                    className="text-decoration-underline"
                  >
                    {t('dashboard.cards.view_all_canceled_tenders')}
                  </Link>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-warning rounded fs-3">
                    <i className=" mdi mdi-calendar-clock text-warning" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
