import {BaseModel} from '../../model/shared/BaseModel';
import {User} from '../../model/user/User';
import {requests} from '../../utils/helpers/api.services';
import {USER_URL} from '../../utils/helpers/api.routes';
import {IUserFilterParams} from './dto/IUserFilterParams';

export const UserService = {
    getUsers: (params: IUserFilterParams): Promise<BaseModel<User[]>> => requests.get(USER_URL,params),
    getUser: (id: number): Promise<User> => requests.get(`${USER_URL}/${id}`),
    updateUser: (id: number, body: User) => requests.put(`${USER_URL}/${id}`, body),
    deleteUser: (id: number) => requests.delete(`${USER_URL}/${id}`),
    deactivateUser:(id: number): Promise<User> => requests.put(`${USER_URL}/change-is-active-status/${id}`)
}