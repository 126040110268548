import { Modals } from "../modals/Modals";
import { iDataDeleteModal } from "../../model/shared/ICrud";
import { formatDate } from "../functions/Functions";
import { useTranslation } from "react-i18next";

export const CrudDelete = ({
  removeData,
  selectedModalData,
  mainField,
}: iDataDeleteModal) => {
  const { t } = useTranslation();
  return (
    <Modals
      modalId="deleteModal"
      buttonType="btn-primary"
      title={`${t("cruds.do_you_want_to_delete")}`}
      saveButtonName={`${t("modals.delete")}`}
      updateChanges={removeData}
      body={
        <div>
          <div>
            <label htmlFor="id" className="font-weight-bold">
              ID:
            </label>
            <span className="ms-1">{selectedModalData?.id}</span>
          </div>
          <div>
            <label htmlFor="reason" className="font-weight-bold">
              {t("cruds.reason")}:
            </label>
            <span className="ms-1 ">{selectedModalData?.[mainField]}</span>
          </div>
          <div>
            <label htmlFor="createdAt" className="font-weight-bold">
              {t("cruds.createdAt")}:
            </label>
            <span className="ms-1 ">
              {formatDate(selectedModalData!?.createdAt as string)}
            </span>
          </div>
          <div>
            <label htmlFor="updatedAt" className="font-weight-bold">
              {t("cruds.updatedAt")}:
            </label>
            <span className="ms-1 ">
              {formatDate(selectedModalData!?.updatedAt as string)}
            </span>
          </div>
        </div>
      }
    />
  );
};
