import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAllStates } from '../../../model/shared/IAllStates';
import { FilterStatus } from '../../../shared/enums/Enums';
import { useTranslation } from 'react-i18next';
import { log } from 'console';
import { useParams } from 'react-router';
export function TenderTabs({ filterTenders, filterParams, setFilter }: any) {
  const { t } = useTranslation();
  const { filter } = useSelector((state: IAllStates) => state.enums);
  const { status } = useParams();
  useEffect(() => {
    if (status) {
      setFilter && setFilter({ ...filterParams, status: status });
    }
  }, [status]);

  return (
    <div className="col-lg-10">
      <ul
        className="nav nav-tabs nav-tabs-custom nav-success nav-justified"
        role="tablist"
      >
        {filter?.app?.appTenderFilterStatus?.map((filter: string) => (
          <li className="nav-item">
            <a
              className={`nav-link ${status === filter && 'active'}`}
              onClick={() => {
                filterTenders(filter);
              }}
              data-bs-toggle="tab"
              role="tab"
              aria-selected="true"
            >
              {t(FilterStatus[filter as keyof typeof FilterStatus] as string)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
