import { Header } from "./Header";
import { SideBar } from "./SideBar";
import { useTranslation } from "react-i18next";

interface iPageTemplate {
  pageName?: string;
  title?: string;
  content?: React.ReactElement;
}

export function PageTemplate({ content, pageName, title }: iPageTemplate) {
  const { t } = useTranslation();
  const body = document.querySelector("body");

  const closeMenu = () => {
    body!.classList.remove("vertical-sidebar-enable");
  };

  return (
    <div className="layout-wrapper">
      <Header />
      <SideBar t={t} />
      <div className="vertical-overlay" onClick={closeMenu}></div>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">{pageName}</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a>{pageName}</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {title?.toUpperCase()}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-0 w-100">{content}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
