import {requests} from '../../utils/helpers/api.services';
import {
    BASE_URL,
    DELETE_TENDER_DOCUMENT,
    POSSIBLE_SUPERVISORS_URL,
    TENDER_CHECKLIST_DOCUMENTS_URL,
    TENDER_CHECKLIST_URL,
    TENDER_CHECKLIST_WORKERS_URL,
    TENDER_COMMENTS_URL,
    TENDER_DETAILS_URL,
    TENDER_FILTER_URL, TENDER_STATUS_URL,
    TENDER_URL, TENDER_USER_FUNCTIONS_DROPDOWN_URL
} from '../../utils/helpers/api.routes';
import {iTenderComments, Tender} from '../../model/tender/Tender';
import {BaseModel} from '../../model/shared/BaseModel';
import {ISupervisor} from '../../model/user/ISupervisor';
import {iCompanyBids} from "../../model/tender/ICompanyBids";
import {IChecklist, ICreateTender} from '../../model/tender/IChecklist';
import {User} from '../../model/user/User';
import {IDocument} from '../../model/shared/IDocument';

export const TenderService = {
    getTenders: (params: any): Promise<BaseModel<Tender[]>> => requests.get(TENDER_URL, params),
    getTender: (id: number): Promise<Tender> => requests.get(`${TENDER_URL}/detail/${id}`),
    getTenderFilters: () => requests.get(TENDER_FILTER_URL),
    getTenderDetails: () => requests.get(TENDER_DETAILS_URL),
    deleteTenderDocument: (id: number) => requests.delete(`${DELETE_TENDER_DOCUMENT}/${id}`),
    getPossibleSupervisors: (id: string): Promise<ISupervisor[]> => requests.get(`${POSSIBLE_SUPERVISORS_URL}/${id}`),
    getTenderCompaniesDropdown: () => requests.get(`${TENDER_URL}/dropdowns/tender-companies`),
    createTenderCompanyBid: (body: iCompanyBids, id: number) => requests.post(`${TENDER_URL}/company/${id}`, body),
    updateTenderCompanyBid: (body: iCompanyBids, id: number) => requests.put(`${TENDER_URL}/company/${id}`, body),
    getTenderOfCompany: (id: number) => requests.get(`${TENDER_URL}/company/${id}`),
    getTenderTaskList: (id: number, params: any): Promise<BaseModel<IChecklist[]>> => requests.get(`${TENDER_CHECKLIST_URL}/list/${id}`, params),
    getTaskListWorkers: (id: number, params: any): Promise<BaseModel<User[]>> => requests.get(`${TENDER_CHECKLIST_WORKERS_URL}/${id}`, params),
    getTaskListDocuments: (id: number, params: any): Promise<BaseModel<IDocument[]>> => requests.get(`${TENDER_CHECKLIST_DOCUMENTS_URL}/${id}`, params),
    getTenderTask: (id: number): Promise<IChecklist> => requests.get(`${TENDER_CHECKLIST_URL}/${id}`),
    createTenderTask: (id: number, body: ICreateTender): Promise<IChecklist> => requests.post(`${TENDER_CHECKLIST_URL}/${id}`, body),
    updateTenderTask: (id: number, body: ICreateTender): Promise<IChecklist> => requests.put(`${TENDER_CHECKLIST_URL}/${id}`, body),
    deleteTaskDocument: (id: number) => requests.delete(`${TENDER_CHECKLIST_URL}/delete-document/${id}`),
    deleteCompanyTaskDocument: (taskId: number, documentId: number) => requests.delete(`${TENDER_CHECKLIST_URL}/delete-company-document/${taskId}/${documentId}`),
    deleteTenderTask: (id: number) => requests.delete(`${TENDER_CHECKLIST_URL}/delete-task/${id}`),
    getTenderComments: (id: number, body?: any) => requests.get(`${TENDER_COMMENTS_URL}/${id}`, body),
    createTenderComment: (id: number, body: iTenderComments) => requests.post(`${TENDER_COMMENTS_URL}/${id}`, body),
    getTenderUserFunctions: () => requests.get(TENDER_USER_FUNCTIONS_DROPDOWN_URL),
    getTenderStatusDropdowns: (id: number) => requests.get(`${TENDER_STATUS_URL}/dropdowns/${id}`),
    createTenderStatus: (body: any, id: number) => requests.post(`${TENDER_STATUS_URL}/${id}`, body),
    getTenderStatus: (id: number) => requests.get(`${TENDER_STATUS_URL}/${id}`)
}