import React, { useEffect, useState } from 'react';
import { NotificationService } from '../service';
import { ErrorToast } from '../../../shared/toasters/toasters';
import { INotification } from '../../../model/notification/INotification';
import { Pagination } from '../../../shared/paginations/Paginations';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import { useTranslation } from 'react-i18next';

export function NotificationList() {
  const { t } = useTranslation();
  const [filterParams, setFilter] = useState({
    page: 1,
    perPage: 10,
  });

  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    NotificationService.getNotify(filterParams)
      .then((response) => {
        setNotifications(response.data);
        setTotalPages(response.totalPages);
      })
      .catch((err) => ErrorToast(err));
  }, [filterParams]);

  const handlePages = (updatePage: number) => {
    setFilter({ ...filterParams, page: updatePage });
  };

  return (
    <>
      {notifications &&
        notifications.map((notify, i) => {
          return (
            <div className='list-group my-2' key={i}>
              <Link
                to={`/tender/details/${notify?.tender?.id}`}
                state={{ isEdit: true }}
                className='list-group-item list-group-item-action'
              >
                <div className='float-end'>
                  {notify.viewed
                    ? `${t('notifications.viewed')}`
                    : `${t('notifications.new')}`}
                </div>
                <div className='d-flex mb-2 align-items-center'>
                  <div className='flex-shrink-0'>
                    <div className='avatar-xs me-3'>
                      <span className='avatar-title bg-soft-danger text-danger rounded-circle fs-16'>
                        <i className='bx bx-message-square-dots' />
                      </span>
                    </div>
                  </div>
                  <div className='flex-grow-1 ms-3'>
                    <p className='list-text mb-0 fs-12'>
                      {moment(notify.createdAt)
                        ?.locale(`${localStorage.getItem('language') ?? 'de'}`)
                        ?.fromNow()}
                    </p>
                  </div>
                </div>
                <p
                  className={`${
                    !notify.viewed && 'fw-bold'
                  } mt-0 mb-2  fs-13 lh-base`}
                >
                  {' '}
                  {notify.message}
                </p>
              </Link>
            </div>
          );
        })}

      <div className='d-flex justify-content-end mt-3 mb-5'>
        {totalPages <= 1 ? (
          ''
        ) : (
          <Pagination
            page={filterParams.page}
            totalPages={totalPages}
            handlePagination={handlePages}
          />
        )}
      </div>
    </>
  );
}
