import React, { useEffect, useState } from 'react';
import { TenderTabs } from '../TenderTabs';
import { TenderApprovement } from './components/TenderApprovement';
import { SubmissionStatus } from './components/SubmissionStatus';
import { TenderService } from '../../service';
import { ErrorToast, SuccessToast } from '../../../../shared/toasters/toasters';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Tender } from '../../../../model/tender/Tender';
import { useTranslation } from 'react-i18next';

export function TenderStatus() {
  const location = useLocation();
  const currentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const isSuperAdmin = currentUser?.roles?.some(
    (role: string) => role === 'ROLE_SUPER_ADMIN'
  );
  const tenderId = location?.pathname?.split('/')[3];
  const [tenderInfo, setTenderInfo] = useState<Tender>();
  const [updateState, setUpdateState] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [approvementStatus, setApprovementStatus] = useState(null);
  const [dropdownContent, setDropdownContent] = useState({
    cancellationReasons: [],
    client: [],
    handInByList: [],
    lostReasons: [],
  });

  const [currentStep, setCurrentStep] = useState(false);
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [createTenderStatus, setCreateTenderStatus] = useState({
    isSubmitted: false,
    isWon: false,
    handInDate: new Date().toISOString(),
    handInBy: null,
    maxRevenue: tenderInfo!?.maxRevenue,
    endDate: null,
    endDateOfPayment: null,
    startDate: null,
    cancellationReason: null,
    cancellationDescription: '',
    lostReason: null,
    lostDescription: '',
    client: null,
    monthlyRevenue: [],
    revenueSplit: [],
  });
  const [revenueSplit, setRevenueSplit] = useState({
    revenueSplit: [
      {
        companyId: 0,
        percent: 0,
      },
    ],
  });

  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    let value = +event.target.value ? +event.target.value : event.target.value;
    setCreateTenderStatus((prev: any) => ({
      ...prev,
      [event.target.name]: value,
    }));
  };

  const changeRevenueHandler = (event: any) => {
    let copy: any = { ...revenueSplit };

    const companyIndex = revenueSplit?.revenueSplit?.findIndex(
      (company) => +company?.companyId === +event?.target?.id
    );
    if (companyIndex === -1) {
      console.log('desava se if');
      setRevenueSplit((prev) => ({
        ...prev,
        revenueSplit: [
          ...prev.revenueSplit,
          {
            companyId: +event.target.id,
            percent: +event?.target?.value,
          },
        ],
      }));
    } else {
      console.log('desava se else');
      copy.revenueSplit[companyIndex] = {
        companyId: +event.target.id,
        percent: +event?.target?.value,
      };
      setRevenueSplit((prev) => ({
        ...prev,
        revenueSplit: copy?.revenueSplit,
      }));
    }
  };

  let removeFirstElementRevenues = revenueSplit?.revenueSplit?.filter(
    (percente) => percente?.percent !== 0
  );

  const editedArray = revenueSplit?.revenueSplit?.filter(
    (company) => company?.companyId !== 0
  );

  const combinedArray = editedArray.concat(createTenderStatus?.revenueSplit);
  const filteredArr = combinedArray.reduce((acc: any, current: any) => {
    const x = acc.find((item: any) => item.companyId === current.companyId);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const sendStatus = () => {
    const revenueSplitToSend = filteredArr?.length
      ? filteredArr
      : removeFirstElementRevenues;
    console.log(removeFirstElementRevenues);
    const monthlyRevenueArray: any[] = [];
    createTenderStatus?.monthlyRevenue?.forEach(
      (revenue: { month: string; amount: number }) => {
        if (revenue?.amount === null) {
          monthlyRevenueArray.push({
            month: revenue?.month,
            amount: (revenue.amount = +(
              tenderInfo!?.maxRevenue /
              createTenderStatus?.monthlyRevenue?.length
            )?.toFixed(2)),
          });
        } else {
          monthlyRevenueArray.push({
            month: revenue?.month,
            amount: revenue?.amount,
          });
        }
      }
    );
    console.log(revenueSplitToSend?.length);
    TenderService.createTenderStatus(
      {
        ...createTenderStatus,
        isSubmitted: submissionStatus,
        isWon: approvementStatus,
        client: createTenderStatus?.client ? createTenderStatus?.client : null,
        handInDate: createTenderStatus?.handInDate
          ? createTenderStatus?.handInDate
          : null,
        handInBy: +(createTenderStatus?.handInBy as any)?.id
          ? +(createTenderStatus?.handInBy as any)?.id
          : createTenderStatus?.handInBy,
        lostReason: +(createTenderStatus?.lostReason as any)?.id
          ? +(createTenderStatus?.lostReason as any)?.id
          : createTenderStatus?.lostReason,
        revenueSplit:
          revenueSplit?.revenueSplit?.length > 1
            ? removeFirstElementRevenues
            : createTenderStatus?.revenueSplit.length
            ? createTenderStatus?.revenueSplit
            : null,

        //  createTenderStatus?.revenueSplit.length
        //   ? createTenderStatus?.revenueSplit
        //   : tenderInfo?.status === 'done'
        //   ? revenueSplitToSend
        //   : revenueSplit?.revenueSplit?.length > 1
        //   ? removeFirstElementRevenues
        //   : null,
        monthlyRevenue:
          createTenderStatus?.monthlyRevenue.length > 0
            ? monthlyRevenueArray
            : null,
        startDate: tenderInfo?.startDate,
        endDateOfPayment:
          tenderInfo?.endDateOfPayment || createTenderStatus?.endDateOfPayment,
        endDate: tenderInfo?.endDate,
      },
      +tenderId
    )
      .then((res) => {
        if (res) {
          setUpdateState(!updateState);

          navigate('/dashboard');
          SuccessToast(`${t('Tenders.toasts.success')}`);
        }
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    tenderId &&
      TenderService.getTenderStatusDropdowns(+tenderId)
        .then((res) => {
          if (res) {
            setDropdownContent({
              cancellationReasons: res?.cancellationReasons,
              client: res?.client,
              handInByList: res?.handInByList,
              lostReasons: res?.lostReasons,
            });
          }
        })
        .catch((error) => ErrorToast(error));
  }, [tenderId]);
  useEffect(() => {
    TenderService.getTenderStatus(+tenderId)
      .then((res) => {
        if (res.isSubmitted !== null) {
          setCreateTenderStatus(res);
          setSubmissionStatus(res?.isSubmitted);
          setCurrentStep(true);
          setApprovementStatus(res?.isWon);
        }
      })
      .catch((error) => ErrorToast(error));
  }, [updateState]);

  useEffect(() => {
    tenderId &&
      TenderService.getTender(+tenderId!).then((response) => {
        if (response) {
          setCreateTenderStatus((prev) => ({
            ...prev,
            maxRevenue: response.maxRevenue,
          }));
          setTenderInfo(response);
        }
      });
  }, [JSON.stringify(tenderId)]);

  const isDisabled = !isSuperAdmin && tenderInfo?.status === 'done';

  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          <div className="card-body checkout-tab">
            <TenderTabs />
            <div className="row my-4">
              <SubmissionStatus
                submissionStatus={submissionStatus}
                setCreateTenderStatus={setCreateTenderStatus}
                dropdownContent={dropdownContent}
                isSuperAdmin={isSuperAdmin}
                changeHandler={changeHandler}
                isDisabled={isDisabled}
                tenderInfo={tenderInfo}
                createTenderStatus={createTenderStatus}
                setSubmissionStatus={setSubmissionStatus}
              />
              {currentStep && (
                <TenderApprovement
                  approvementStatus={approvementStatus}
                  isDisabled={isDisabled}
                  changeHandler={changeHandler}
                  setTenderInfo={setTenderInfo}
                  changeRevenueHandler={changeRevenueHandler}
                  tenderInfo={tenderInfo}
                  createTenderStatus={createTenderStatus}
                  dropdownContent={dropdownContent}
                  setCreateTenderStatus={setCreateTenderStatus}
                  setApprovementStatus={setApprovementStatus}
                  filteredArr={filteredArr}
                  isSuperAdmin={isSuperAdmin}
                />
              )}
            </div>

            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary"
                onClick={sendStatus}
                disabled={!tenderInfo?.isProlongation && isDisabled}
              >
                {t('Tenders.Status.save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
