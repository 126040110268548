import React from "react";
import { useTranslation } from "react-i18next";

export const TenderCompaniesAddingNewUser = ({
  userFunctions,
  bid,
  setNewEmployee,
}: any) => {
  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (event.target.name === "userFunction") {
      console.log(event.target.value);

      const selectedFunction = userFunctions?.find(
        (func: { id: number }) => func.id === +event.target.value
      );
      setNewEmployee((prev: any) => ({
        ...prev,
        userFunctionToDisplay: {
          id: +event.target.value,
          title: selectedFunction?.title,
        },
        userFunction: +event.target.value,
      }));
    } else if (event.target.name === "userId") {
      const selectedUser = bid?.users?.find(
        (user: { id: number }) => user?.id === +event.target.value
      );
      setNewEmployee((prev: any) => ({
        ...prev,
        employee: {
          id: +event.target.value,
          firstName: selectedUser?.firstName,
          lastName: selectedUser?.lastName,
        },
        userId: +event.target.value,
      }));
    } else if (event.target.name === "userType") {
      setNewEmployee((prev: { type: string }) => ({
        ...prev,
        type: event.target.value,
        userType: event.target.value,
      }));
    }
  };

  const { t } = useTranslation();
  return (
    <>
      <hr className="my-3" />
      <div className="my-2">
        <label htmlFor="userType">
          {t("Tenders.companies.employee_type")}:
        </label>
        <select
          name="userType"
          className={`form-select`}
          onChange={changeHandler}
        >
          <option hidden />

          <option value="lead">{t("Tenders.companies.lead")}</option>
          <option value="support">{t("Tenders.companies.support")}</option>
        </select>
        <div className="my-2">
          <label htmlFor="userType">
            {t("Tenders.companies.userFunction")}:
          </label>
          <select
            name="userFunction"
            className={`form-select`}
            onChange={changeHandler}
          >
            <option hidden />
            {userFunctions?.map((func: { id: number; title: string }) => {
              return (
                <option key={func?.id} value={func?.id}>
                  {func?.title}
                </option>
              );
            })}
          </select>
        </div>
        <div className="my-2">
          <label htmlFor="userId">{t("Tenders.companies.user")}:</label>
          <select
            name="userId"
            className={`form-select`}
            onChange={changeHandler}
          >
            <option hidden />

            {bid?.users
              ?.filter((user: any) => user.isActive)
              ?.map((companyUser: any, i: number) => {
                return (
                  <option value={companyUser?.id} key={i}>
                    {companyUser?.firstName} {companyUser?.lastName}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
    </>
  );
};
