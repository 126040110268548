export const UserInfo = ({
  t,
  profile,
  handleChange,
  returnToDashboard,
  updateProfile,
  setChangeProfile,
}: any) => {
  return (
    <>
      <form action="#">
        <div className="row">
          <div className="col-lg-6">
            <div className="mb-3">
              <label htmlFor="firstnameInput" className="form-label">
                {" "}
                {t("profile.first_name")}
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={250}
                id="firstnameInput"
                placeholder={t("profile.first_name")}
                name="firstName"
                defaultValue={profile?.firstName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="mb-3">
              <label htmlFor="lastnameInput" className="form-label">
                {t("profile.last_name")}
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={250}
                id="lastnameInput"
                placeholder={t("profile.last_name")}
                name="lastName"
                defaultValue={profile?.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                {t("profile.phone")}
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={250}
                id="phone"
                onSelect={(e: any) => {
                  setChangeProfile((prev: any) => ({
                    ...prev,
                    contact: {
                      ...prev?.contact,
                      phone: e.target.value,
                    },
                  }));
                }}
                placeholder={t("profile.phone")}
                name="phone"
                defaultValue={profile?.contact.phone}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="mb-3">
              <label htmlFor="emailInput" className="form-label">
                {t("profile.email")}
              </label>
              <input
                type="email"
                className="form-control"
                maxLength={250}
                id="emailInput"
                placeholder="Enter your email"
                name="email"
                defaultValue={profile?.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="col-lg-3">
            <div className="mb-3">
              <label htmlFor="address" className="form-label">
                {t("profile.address")}
              </label>

              <input
                type="text"
                className="form-control"
                placeholder="Address"
                name="address"
                id="address"
                defaultValue={profile?.contact.address}
                onChange={handleChange}
                onSelect={(e: any) => {
                  setChangeProfile((prev: any) => ({
                    ...prev,
                    contact: {
                      ...prev?.contact,
                      address: e.target.value,
                    },
                  }));
                }}
              />
            </div>
          </div>

          <div className="col-lg-3">
            <div className="mb-3">
              <label htmlFor="city" className="form-label">
                {t("profile.city")}
              </label>

              <input
                type="text"
                className="form-control"
                maxLength={250}
                id="city"
                onChange={handleChange}
                defaultValue={profile?.contact?.city}
                placeholder="City"
                name="city"
                onSelect={(e: any) => {
                  setChangeProfile((prev: any) => ({
                    ...prev,
                    contact: {
                      ...prev?.contact,
                      city: e.target.value,
                    },
                  }));
                }}
              />
            </div>
          </div>

          <div className="col-lg-3">
            <div className="mb-3">
              <label htmlFor="country" className="form-label">
                {t("profile.country")}
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={250}
                id="country"
                placeholder="Country"
                name="country"
                onChange={handleChange}
                defaultValue={profile?.contact.country}
                onSelect={(e: any) => {
                  setChangeProfile((prev: any) => ({
                    ...prev,
                    contact: {
                      ...prev?.contact,
                      country: e.target.value,
                    },
                  }));
                }}
              />
            </div>
          </div>

          <div className="col-lg-3">
            <div className="mb-3">
              <label htmlFor="zip" className="form-label">
                {t("profile.zip")}
              </label>
              <input
                type="text"
                className="form-control"
                minLength={5}
                maxLength={12}
                id="zip"
                placeholder="Enter zipcode"
                name="zip"
                onChange={handleChange}
                defaultValue={profile?.contact.zip}
                onSelect={(e: any) => {
                  setChangeProfile((prev: any) => ({
                    ...prev,
                    contact: {
                      ...prev?.contact,
                      zip: e.target.value,
                    },
                  }));
                }}
              />
            </div>
          </div>

          <div className="col-lg-12">
            <div className="hstack gap-2 justify-content-end">
              <button
                onClick={returnToDashboard}
                type="button"
                className="btn btn-danger"
              >
                {t("modals.cancel")}
              </button>
              <button
                onClick={updateProfile}
                className="btn btn-primary"
                type="submit"
              >
                {t("modals.update")}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
