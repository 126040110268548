import {useTenderCrud} from "../../../../shared/hooks/useTenderCrud";
import {CrudService} from "../../service";
import {CrudCreate} from "../../../../shared/crud/CrudCreate";
import {CrudTable} from "../../../../shared/crud/CrudTable";
import {Pagination} from "../../../../shared/paginations/Paginations";
import {CrudUpdate} from "../../../../shared/crud/CrudUpdate";
import {CrudDelete} from "../../../../shared/crud/CrudDelete";

export const LocationList = () => {
    const {
        page,
        handlePages,
        totalPages,
        listOfAllData,
        updateData,
        removeData,
        createData,
        selectedModalData,
        getCurrentDataID,
        changeHandler,
        showForm,
        setShowForm,
        filterData,
        selectedData
    } = useTenderCrud({
        getData: CrudService.getLocation,
        getMany: CrudService.getLocations,
        putData: CrudService.putLocation,
        addData: CrudService.addLocation
    });
    const tableHead = ['Title', 'Created at', 'Updated at', 'Actions']

    return (
        <div className="col-12">
            <div className="row">
                <div className="col-12">
                    <CrudCreate showForm={showForm} setShowForm={setShowForm} createData={createData} mainField="title"
                                title="Location"
                                changeHandler={changeHandler}/>
                </div>
            </div>
            <div className="row">
                <CrudTable filterData={filterData} getCurrentDataID={getCurrentDataID} title="Location"
                           isDeleteCrud={false}
                           tableHead={tableHead} mainField="title"
                           listOfAllData={listOfAllData}/>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-end">
                {totalPages <= 1 ? '' :
                    <Pagination
                        page={page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                }
            </div>
            <CrudUpdate updateData={updateData} changeHandler={changeHandler} mainField="title" title="Location"
                        selectedData={selectedData!}/>

        </div>
    )
}