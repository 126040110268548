import React from 'react';
import { BASE_URL } from '../../../../../utils/helpers/api.routes';
import moment from 'moment';
import { iTenderCommentsResponse } from '../../../../../model/tender/iTenderComments';

interface iProps {
  listOfComments: iTenderCommentsResponse[];
  lastMessageRef: any;
}

export const TenderCommentsList = ({
  listOfComments,
  lastMessageRef,
}: iProps) => {
  return (
    <div className='position-relative' id='users-chat'>
      <div
        className='chat-conversation p-3 p-lg-4 '
        id='chat-conversation'
        data-simplebar='init'
      >
        <div className='simplebar-wrapper' style={{ margin: '-24px' }}>
          <div className='simplebar-height-auto-observer-wrapper'>
            <div className='simplebar-height-auto-observer' />
          </div>
          <div className='simplebar-mask'>
            <div
              className='simplebar-offset'
              style={{ right: '0px', bottom: '0px' }}
            >
              <div
                className='simplebar-content-wrapper'
                role='region'
                aria-label='scrollable content'
              >
                <div
                  className='simplebar-content mt-0'
                  style={{ padding: '24px', overflow: 'auto' }}
                >
                  <ul
                    className='list-unstyled chat-conversation-list'
                    id='users-conversation'
                  >
                    {listOfComments?.map((comment) => {
                      return (
                        <li
                          className={`chat-list ${
                            comment?.isMyMessage ? 'right' : 'left'
                          }`}
                          key={comment?.id}
                        >
                          <div className='conversation-list'>
                            <div className='chat-avatar'>
                              <img
                                className='object-cover'
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src =
                                    '/images/placeholderImage.png';
                                }}
                                src={`${BASE_URL}${comment?.user?.profilePicture?.path}`}
                                alt='User logo'
                              />
                            </div>
                            <div className='user-chat-content'>
                              <div className='ctext-wrap'>
                                <div className='ctext-wrap-content'>
                                  <p className='mb-0 ctext-content'>
                                    {comment?.message}
                                  </p>
                                </div>
                              </div>
                              <div
                                className={`conversation-name d-flex align-items-center  ${
                                  comment?.isMyMessage ? 'flex-row-reverse' : ''
                                }`}
                              >
                                <small className='text-muted time'>
                                  {moment(comment?.createdAt).format(
                                    'DD.MM.YYYY - HH:mm'
                                  )}{' '}
                                  -{' '}
                                </small>
                                <small className='text-muted'>
                                  {comment?.user?.firstName}{' '}
                                  {comment?.user?.lastName}
                                </small>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                    <p ref={lastMessageRef} />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
