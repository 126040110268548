import React, { SetStateAction } from 'react';
import { UserService } from '../service';
import {
  ErrorToast,
  SuccessToast,
  WarningToast,
} from '../../../shared/toasters/toasters';
import { User } from '../../../model/user/User';
import { useTranslation } from 'react-i18next';

interface iConfirmationModalProps {
  selectedUser: User;
  isStateChanged: boolean;
  setIsStateChanged: React.Dispatch<SetStateAction<boolean>>;
}

export const UserConfirmationModal = ({
  selectedUser,
  setIsStateChanged,
  isStateChanged,
}: iConfirmationModalProps) => {
  const deactivateUser = (id: number) => {
    if (selectedUser?.roles?.find((role) => role.includes('SUPERVISOR')))
      return WarningToast(
        `${t('Users.toasts.not_able_to_deactivate_supervisor')}`
      );
    UserService.deactivateUser(id)
      .then((res) => {
        SuccessToast(
          res.isActive
            ? `${t('Users.toasts.successfully_activate_user')}`
            : `${t('Users.toasts.successfully_activate_deactivate')}`
        );
        setIsStateChanged(!isStateChanged);
      })
      .catch((err) => ErrorToast(err));
  };

  const { t } = useTranslation();

  return (
    <div
      className="modal fade zoomIn"
      id="showModal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content border-0">
          <div className="modal-header p-3 bg-soft-info">
            <h5 className="modal-title" id="exampleModalLabel">
              {t('modals.are_you_sure_you_want_to_deactive')}{' '}
              {selectedUser?.isActive
                ? `${t('modals.deactivated')}`
                : `${t('modals.activated')}`}{' '}
              ?
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            />
          </div>
          <div className="modal-footer">
            <div className="hstack gap-2 justify-content-center mt-3 w-100">
              <button
                type="button"
                className="btn btn-light"
                id="close-modal"
                data-bs-dismiss="modal"
              >
                {t('modals.close')}
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => deactivateUser(selectedUser?.id)}
                id="close-modal"
                data-bs-dismiss="modal"
              >
                {t('modals.change')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
