import React from "react";
import { Modals } from "../modals/Modals";
import { iDataUpdateModalProps } from "../../model/shared/ICrud";
import { useTranslation } from "react-i18next";

export const CrudUpdate = ({
  selectedData,
  changeHandler,
  updateData,
  mainField,
  title,
}: iDataUpdateModalProps) => {
  const { t } = useTranslation();
  return (
    <Modals
      modalId="modaldemo1"
      title={`${title} - ID: ${selectedData?.id}`}
      body={
        <div className="form-group mb-0">
          <label htmlFor="exampleInputEmail1">
            {t("cruds.enter")} {t("cruds.name")}
          </label>
          <input
            type="text"
            className="form-control updateModalField"
            name={mainField}
            key={selectedData?.[mainField]}
            defaultValue={selectedData?.[mainField]}
            onBlur={changeHandler}
          />
        </div>
      }
      updateChanges={updateData!}
    />
  );
};
