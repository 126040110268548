import { requests } from "../../utils/helpers/api.services";
import {
  CANCELLATION_URL,
  CLIENT_URL,
  COMMISSIONER_URL,
  FUNCTION_URL,
  IRRELEVANCE_URL,
  LOCATION_URL,
  LOSS_URL,
  TYPE_URL,
} from "../../utils/helpers/api.routes";
import { ErrorToast } from "../../shared/toasters/toasters";

export const CrudService = {
  // CLIENTS
  getClient: (id: number) =>
    requests
      .get(`${CLIENT_URL}/${id}`)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  getClients: (body: any) =>
    requests
      .get(`${CLIENT_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  addClient: (body: any) =>
    requests
      .post(`${CLIENT_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  putClient: (id: number, body: any) =>
    requests
      .put(`${CLIENT_URL}/${id}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),

  // CANCELLATIONS
  getCancellation: (id: number) =>
    requests
      .get(`${CANCELLATION_URL}/${id}`)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  getCancellations: (body: any) =>
    requests
      .get(`${CANCELLATION_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  addCancellation: (body: any) =>
    requests
      .post(`${CANCELLATION_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  putCancellation: (id: number, body: any) =>
    requests
      .put(`${CANCELLATION_URL}/${id}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  deleteCancellation: (id: number) =>
    requests
      .delete(`${CANCELLATION_URL}/${id}`)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),

  // IRRELEVANCE
  getIrrelevance: (id: number) =>
    requests
      .get(`${IRRELEVANCE_URL}/${id}`)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  getIrrelevances: (body: any) =>
    requests
      .get(`${IRRELEVANCE_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  addIrrelevance: (body: any) =>
    requests
      .post(`${IRRELEVANCE_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  putIrrelevance: (id: number, body: any) =>
    requests
      .put(`${IRRELEVANCE_URL}/${id}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  deleteIrrelevance: (id: number) =>
    requests
      .delete(`${IRRELEVANCE_URL}/${id}`)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),

  // LOSS
  getLoss: (id: number) =>
    requests
      .get(`${LOSS_URL}/${id}`)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  getLosses: (body: any) =>
    requests
      .get(`${LOSS_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  addLoss: (body: any) =>
    requests
      .post(`${LOSS_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  putLoss: (id: number, body: any) =>
    requests
      .put(`${LOSS_URL}/${id}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  deleteLoss: (id: number) =>
    requests
      .delete(`${LOSS_URL}/${id}`)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),

  // TENDER TYPE
  getType: (id: number) =>
    requests
      .get(`${TYPE_URL}/${id}`)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  getTypes: (body: any) =>
    requests
      .get(`${TYPE_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  addType: (body: any) =>
    requests
      .post(`${TYPE_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  putType: (id: number, body: any) =>
    requests
      .put(`${TYPE_URL}/${id}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  deleteType: (id: number) =>
    requests
      .delete(`${TYPE_URL}/${id}`)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),

  // LOCATIONS
  getLocation: (id: number) =>
    requests
      .get(`${LOCATION_URL}/${id}`)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  getLocations: (body: any) =>
    requests
      .get(`${LOCATION_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  addLocation: (body: any) =>
    requests
      .post(`${LOCATION_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  putLocation: (id: number, body: any) =>
    requests
      .put(`${LOCATION_URL}/${id}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),

  // FUNCTIONS
  getFunction: (id: number) =>
    requests
      .get(`${FUNCTION_URL}/${id}`)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  getFunctions: (body: any) =>
    requests
      .get(`${FUNCTION_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  addFunction: (body: any) =>
    requests
      .post(`${FUNCTION_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  putFunction: (id: number, body: any) =>
    requests
      .put(`${FUNCTION_URL}/${id}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  deleteFunction: (id: number) =>
    requests
      .delete(`${FUNCTION_URL}/${id}`)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),

  // COMMISSIONER
  getCommissioner: (id: number) =>
    requests
      .get(`${COMMISSIONER_URL}/${id}`)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  getCommissioners: (body: any) =>
    requests
      .get(`${COMMISSIONER_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  addCommissioner: (body: any) =>
    requests
      .post(`${COMMISSIONER_URL}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
  putCommissioner: (id: number, body: any) =>
    requests
      .put(`${COMMISSIONER_URL}/${id}`, body)
      .then((data) => data)
      .catch((error) => ErrorToast(error)),
};
