import { iDataCreate } from '../../model/shared/ICrud';
import { useTranslation } from 'react-i18next';
import { CrudTitle } from '../enums/Enums';

export const CrudCreate = ({
  showForm,
  setShowForm,
  createData,
  changeHandler,
  title,
  mainField,
}: iDataCreate) => {
  const { t } = useTranslation();

  const createTitle = (title: any) => {
    if (
      title.toLocaleLowerCase() === 'functions' ||
      (title.toLocaleLowerCase() === 'location' &&
        localStorage.getItem('language') === 'en')
    ) {
      return t(
        CrudTitle[
          title?.toLocaleLowerCase() as keyof typeof CrudTitle
        ] as string
      ).replace(/.$/, '');
    } else {
      return t(
        CrudTitle[
          title?.toLocaleLowerCase() as keyof typeof CrudTitle
        ] as string
      );
    }
  };

  return (
    <div className="card">
      <div
        className={`card-header pb-0 d-flex justify-content-between align-items-center  flex-column flex-md-row  ${
          !showForm && 'pb-4'
        }`}
      >
        <div>
          <div className="d-flex justify-content-between">
            <h4 className="card-title mg-b-0">
              {t('modals.create')} {createTitle(title)}
            </h4>
          </div>
        </div>
        {!showForm && (
          <button
            className="btn btn-primary"
            onClick={() => setShowForm(!showForm)}
          >
            {t('Tenders.companies.fields.add')} {createTitle(title)}
          </button>
        )}
      </div>
      {showForm && (
        <div className="card-body">
          <div className="form-group mb-0">
            <label htmlFor="exampleInputEmail1">{createTitle(title)}</label>
            <input
              type="text"
              className="form-control"
              name={mainField}
              onChange={changeHandler}
              placeholder={`${t('cruds.enter')} ${createTitle(title)}`}
            />
          </div>
          <div className="d-flex justify-content-end w-100">
            <button onClick={createData} className="btn btn-primary mt-3 mb-0">
              {t('modals.create')} {createTitle(title)}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
