import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { BaseRoutes } from "./app/routes";
import "./assets/scss/themes.scss";
import { EnumService } from "./store/enums/enumService";
import enumsSlice from "./store/enums/enumsRedux";
import { useDispatch } from "react-redux";
import i18next from "i18next";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    EnumService.getEnums().then((data) => {
      dispatch(enumsSlice.actions.setEnums(data));
    });
  }, []);

  const currentLanguage = localStorage.getItem("language");
  useEffect(() => {
    i18next.changeLanguage(currentLanguage ?? "de");
  }, [currentLanguage]);

  return (
    <BrowserRouter>
      <BaseRoutes />
    </BrowserRouter>
  );
}

export default App;
