import React, { useEffect, useState } from 'react';
import { iUserData, User } from '../../../model/user/User';
import { ProfileService } from '../../profile/service';
import { ErrorToast } from '../../../shared/toasters/toasters';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function DashboardHeader() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [profile, setProfile] = useState<iUserData>();
  const pageURL = localStorage.getItem('pageURL');
  useEffect(() => {
    ProfileService.getProfile()
      .then((res) => {
        res && setProfile(res);
      })
      .catch((err) => {
        if (err.message === 'Request failed with status code 401') {
          if (pageURL) {
            navigate('/change-password');
          } else {
            navigate('/');
            localStorage.clear();
          }
        }

        ErrorToast(err);
      });
  }, []);

  const passData = () => {
    navigate('/tender/create', { state: { isEdit: false } });
  };

  return (
    <div className="col">
      <div className="h-100">
        <div className="row mb-3 pb-1">
          <div className="col-12">
            <div className="d-flex align-items-lg-center flex-lg-row flex-column">
              <div className="flex-grow-1">
                <h4 className="fs-16 mb-1">
                  {t('dashboard.hello')}, {profile?.firstName}
                </h4>
                <p className="text-muted mb-0">
                  {t('dashboard.dashboard_inform_message')}.
                </p>
              </div>
              <div className="mt-3 mt-lg-0">
                <div className="row g-3 mb-0 align-items-center">
                  <div className="col-auto">
                    <button
                      onClick={passData}
                      type="button"
                      className="btn btn-success"
                    >
                      <i className="ri-add-circle-line align-middle me-1" />
                      {t('dashboard.add_tender')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
