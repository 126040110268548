import React, { useEffect, useState } from 'react';
import { TenderCheckListModal } from './TenderCheckListModal';
import { TenderTabs } from '../TenderTabs';
import { TenderCheckListItem } from './TenderCheckListItem';
import { IChecklist } from '../../../../model/tender/IChecklist';
import { useLocation, useNavigate } from 'react-router-dom';
import { TenderService } from '../../service';
import { ErrorToast, SuccessToast } from '../../../../shared/toasters/toasters';
import { ConfirmDeleteModal } from '../../../../shared/components/ConfirmDeleteModal';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../../../../shared/paginations/Paginations';

export function TenderCheckList() {
  const { t } = useTranslation();
  const [filterParams, setFilter] = useState({
    page: 1,
    perPage: 10,
  });
  const [allTasks, setAllTasks] = useState<IChecklist[]>();
  const [updateList, setUpdateList] = useState(false);
  const [selectedTaskID, setSelectedTaskID] = useState(0);
  const [selectedTaskDocumentID, setSelectedTaskDocumentID] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isModalActive, setIsModalActive] = useState(false);
  const [isEditModalActive, setIsEditModalActive] = useState(false);

  const showModalHandler = () => {
    setIsModalActive(true);
  };
  const showEditModalHandler = () => {
    setIsEditModalActive(true);
  };
  const hideModalHandler = () => {
    setIsModalActive(false);
    setIsEditModalActive(false);
  };
  const navigate = useNavigate();
  const location = useLocation();

  const id = location.pathname.split('/')[3];
  useEffect(() => {
    TenderService.getTenderTaskList(+id, filterParams)
      .then((response) => {
        setAllTasks(response.data);
        setTotalPages(response.totalPages);
      })
      .catch((err) => ErrorToast(err));
  }, [updateList, filterParams]);

  const [tenderStatus, setTenderStatus] = useState('');
  useEffect(() => {
    id &&
      TenderService.getTender(+id!).then((response) => {
        response && setTenderStatus(response?.status.toLowerCase());
      });
  }, [JSON.stringify(id)]);

  function navigateToNextStep() {
    navigate(`/tender/comments/${id}`);
  }

  const deleteTask = () => {
    TenderService.deleteTenderTask(selectedTaskID).then((res) => {
      if (res != undefined) {
        SuccessToast(
          `${t('Tenders.Comments.toasts.successfully_deleted_task')}!`
        );
        setSelectedTaskID(0);
        setUpdateList(!updateList);
      }
    });
  };

  const deleteTaskDocument = () => {
    TenderService.deleteTaskDocument(selectedTaskDocumentID)
      .then((response) => {
        setUpdateList(!updateList);
        SuccessToast(
          `${t('Tenders.Comments.toasts.successfully_deleted_task_document')}!`
        );
      })
      .catch((error) => ErrorToast(error));
  };

  const deleteCompanyDocument = () => {
    TenderService.deleteCompanyTaskDocument(
      selectedTaskID!,
      selectedTaskDocumentID
    ).then((response) => {
      setUpdateList(!updateList);
      SuccessToast(
        `${t('Tenders.Comments.toasts.successfully_deleted_company_document')}!`
      );
    });
  };

  const handlePages = (updatePage: number) => {
    setFilter({ ...filterParams, page: updatePage });
  };

  const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
  const isSuperAdmin = currentUser?.roles?.some(
    (role: string) => role === 'ROLE_SUPER_ADMIN'
  );

  const isDisabled = !isSuperAdmin && tenderStatus === 'done';

  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          <div className="card-body checkout-tab">
            <TenderTabs />
            <div className="col-lg-12">
              <div className="card" id="tasksList">
                <div className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      {t('Tenders.Checklist.title')}
                    </h5>
                    {!isDisabled && (
                      <div className="flex-shrink-0">
                        <button
                          className="btn btn-success add-btn"
                          onClick={showModalHandler}
                        >
                          <i className="ri-add-line align-bottom me-1" />
                          {t('Tenders.Checklist.create_task')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive table-card mb-4">
                    <table
                      className="table align-middle table-nowrap mb-0"
                      id="tasksTable"
                    >
                      <thead className="table-light text-muted">
                        <tr>
                          <th className="sort" data-sort="tasks_name">
                            {t('Tenders.Checklist.task')}
                          </th>
                          <th className="sort" data-sort="client_name">
                            {t('Tenders.Checklist.user')}
                          </th>
                          <th className="sort" data-sort="due_date">
                            {t('Tenders.Checklist.date')}
                          </th>
                          <th className="sort" data-sort="status">
                            {t('Tenders.Checklist.status')}
                          </th>
                          <th className="sort" data-sort="priority">
                            {t('Tenders.Checklist.priority')}
                          </th>
                          <th className="sort" data-sort="actions">
                            {t('Tenders.Checklist.actions')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allTasks?.map((list, i) => {
                          return (
                            <TenderCheckListItem
                              key={i}
                              setSelectedTaskID={setSelectedTaskID}
                              list={list}
                              showEditModalHandler={showEditModalHandler}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <TenderCheckListModal
              updateList={updateList}
              modalId="createModal"
              setUpdateList={setUpdateList}
              setIsModalActive={setIsModalActive}
              isModalActive={isModalActive}
              showModalHandler={showModalHandler}
              hideModalHandler={hideModalHandler}
            />
            <TenderCheckListModal
              updateList={updateList}
              modalId="updateModal"
              setUpdateList={setUpdateList}
              setSelectedTaskDocumentID={setSelectedTaskDocumentID}
              selectedTaskID={selectedTaskID!}
              isEditModalActive={isEditModalActive}
              showModalHandler={showModalHandler}
              hideModalHandler={hideModalHandler}
            />
            <ConfirmDeleteModal
              modalId="deleteTask"
              title="Task"
              deleteItem={deleteTask}
            />
            <ConfirmDeleteModal
              modalId="deleteTaskDocument"
              title="Task document"
              deleteItem={deleteTaskDocument}
            />
            <ConfirmDeleteModal
              modalId="deleteCompanyDocument"
              title="Company document"
              deleteItem={deleteCompanyDocument}
            />
            <div className="row">
              <div className="col-12">
                <div className="text-end my-3">
                  <button
                    disabled={isDisabled}
                    onClick={navigateToNextStep}
                    type="submit"
                    className="btn btn-primary w-sm"
                  >
                    {t('Tenders.Checklist.save')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        {totalPages <= 1 ? (
          ''
        ) : (
          <Pagination
            page={filterParams.page}
            totalPages={totalPages}
            handlePagination={handlePages}
          />
        )}
      </div>
    </>
  );
}
