export interface iUseTenders<T = any> {
    getData: (id: number) => Promise<T>;
    putData: (id: number, data: any) => Promise<T>;
    deleteData?: (id: number) => Promise<T>;
    getMany: (pagination: iGetData) => Promise<T>;
    addData: (data: any) => Promise<T>;
}

export interface iGetData {
    page?: number,
    perPage: number,
}

export interface iPagination {
    page: number,
    perPage: number,
    name?: string,
    reason?: string,
    role?: string,
    title?: string,
    email?: string,
    client?: string,
    supervisor?: string,
    company?: string,
    status?: string,
    isRelevant?: boolean,
    isWon?: boolean,
    isSubmitted?: boolean,
}

export const searchDto = (state: any) => {
    const output: any = {}
    for (const s in state) {
        if (state[s] || state[s]?.length) output[s] = state[s]
    }
    return output
}