import {createSlice} from '@reduxjs/toolkit';

const enumsSlice = createSlice({

    name: 'enums',
    initialState: {
        userRoles: [],
        userCompanyRoles: [],
        filter:{
            app:{
                appDashboardFilterStatus:[],
                appTenderFilterStatus:[],
            }
        }
    },

    reducers: {
        setEnums: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export default enumsSlice;
